.remindButton {
    background: transparent url('../../../assets/iconRemainder.svg') no-repeat center left;
    border: none;
    outline: none;
    padding: 0px;
}

.remindButton:hover {
    background: url('../../../assets/iconRemainder.svg') no-repeat center left;
}
