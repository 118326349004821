.pageHeading {
    color: rgba(72, 109, 144, 1);
    font: 800 30px/36px yorkten-slab-normal, benton-sans, sans-serif;
    padding-bottom: 10px;
    border-bottom: 2px solid rgb(140, 191, 38);
    display: inline-block;
    margin-bottom: 40px;
}
.beneficiaryWrapper .linkButton {
    height: 50px;
}
.beneFiciaryHeaderWrapper {
    justify-content: space-between;
}

.beneficiaryWrapper .dividendHint {
    margin-bottom: 40px;
    padding: 25px 30px 19px;
}
.beneficiaryWrapper .accordion {
    margin-bottom: 59px;
}
.beneficiaryHeader {
    display: flex;
    padding: 0 40px;
    background-color: #ffffff;
    border-bottom: 1px solid rgba(210, 210, 210, 1);
}
.beneficiaryHeader .beneficiaryHeaderCol > div {
    display: inline;
}
.beneficiaryHeaderCol:not(:empty) {
    padding: 18px 15px 17px;
    border-right: 1px solid rgba(210, 210, 210, 1);
}
.beneficiaryHeaderCol:first-child {
    padding-left: 0;
    padding-right: 0;
}
.beneficiaryHeaderCol:first-child span {
    padding: 0 15px;
}
.beneficiaryHeaderCol:first-child span[data-key='accountName'] {
    border-right: 1px solid rgba(210, 210, 210, 1);
}
.beneficiaryHeaderCol:last-child {
    padding-right: 0;
    border-right: none;
}
.beneficiaryHeaderCol .headerItem {
    font: 800 16px yorkten-slab-normal, benton-sans, sans-serif;
    color: rgba(72, 109, 144, 1);
}
.beneficiaryWrapper .card {
    border-radius: 0;
    border: 1px solid rgba(210, 210, 210, 1);
}
.beneficiaryCardBody {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 30px;
}
.beneSectionHeading {
    font: 800 22px/45px yorkten-slab-normal, sans-serif;
    color: rgba(72, 109, 144, 1);
}
.beneSectionHeading .lastModified {
    font: 500 12px/20px benton-sans, yorkten-slab-normal, sans-serif;
    padding-left: 10px;
    color: rgba(86, 86, 90, 1);
}
.benSectionHeaderWrapper {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(210, 210, 210, 1);
    margin-bottom: 30px;
}
.beneDetailsTable {
    margin: 20px 0 30px;
}
.beneDetailsTable thead {
    border-top: none;
}
.beneDetailsTable thead th {
    border: none;
    font: 700 16px/24px benton-sans, yorkten-slab-normal, sans-serif;
}
.beneDetailsTable tbody td {
    border: none;
    font: 500 16px/20px benton-sans, yorkten-slab-normal, sans-serif;
    padding: 20px 20px 16px;
}
.beneDetailsTable tbody td.name {
    width: 220px; 
}
.beneDetailsTable tbody tr:nth-child(even) {
    background-color: rgba(250, 250, 250, 1);
}
.benFooterText {
    font: 700 22px/25px benton-sans, yorkten-slab-normal, sans-serif;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(72, 109, 144, 1);
    padding: 17px 40px;
}
.right{
    float:right;
}
.addBeneficiary {
    margin-bottom: 20px;
    height: auto;
}
.primaryBeneWrapper:last-child .addBeneficiary {
    margin-bottom: 0;
}
.beneficiaryWrapper .beneficiaryBoxModel.dividendHint {
    padding-bottom: 26px;
}
.beneficiaryBoxModel h2 {
    font: 800 30px/31px yorkten-slab-normal, benton-sans, sans-serif;
    color: #49494a;
}
.beneficiaryBoxModel p:not(:empty) {
    font: 600 16px/22px benton-sans, yorkten-slab-normal, sans-serif;
    color: #49494a;
    margin-bottom: 0;
    margin-top: 15px;
}
.beneficiaryBoxModel button.apply > p {
    margin-top: 0;
    color: rgba(0, 74, 152, 1);
    font: 800 16px/20px yorkten-slab-normal, benton-sans, sans-serif;
}
.beneficiaryBoxModel .col-sm-2 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.beneficiaryBoxModel a.inlineLink {
    color: #0060c5;
}
.beneficiaryBoxModel .download-link > div {
    padding-right: 5px;
}
.beneficiaryWrapper hr {
    margin: 10px 0;
}
.beneficiaryWrapper .dividendHint:last-child {
    margin-bottom: 50px;
}
.manageButton {
    margin-right: 10px;
}
.inlineDiv {
    display:inline-block;
    margin-right:5px;
    float: right;
 }
 .inlineDiv button {
     margin-left: 20px;
 }
.smallerInput {
    height: 30px !important;
    width: 85px;
    padding: 0px 10px;
    display: inline-block;
}
.addBeneficiaryForm {
    margin-top: 50px;
    margin-bottom: 150px;
}
.addBeneficiaryCancelBtn {
    color: rgb(0, 74, 152);
    font: 700 16px/22px benton-sans, sans-serif;
    background: transparent;
    border: none;
    padding: 0px 0px 0px 30px;
    position: relative;
    margin-top: 5px;
    margin-bottom: 70px;
}
.addBeneDropDownDiv {
    margin-left: -15px;
    margin-right: -15px;
}
.noteFooterText {
    font: 400 benton-sans, yorkten-slab-normal, sans-serif;
    color: black;
    background-color: rgba(243, 244, 218, 1);
    padding: 17px 30px;
    margin-bottom: 20px;
    margin-left: -15px;
    margin-right: -15px;
}

.no-beneficiary-data {
    margin-bottom: 2.5rem;
}

.ben-header-container {
    display: flex;
    justify-content: space-between;
}

.distribute-equally {
    border-color: rgba(0,74,152,1);
    width: 25%;
}

.new-beneficiary-flag {
    color: #ffffff;
    padding: 0.2rem 0.5rem;
    background-color: #3463AF;
    margin-left: 5px;
}

.delete-beneficiary-flag {
    color: #ffffff;
    padding: 0.2rem 0.5rem;
    background-color: #E60000;
    margin-left: 5px;
}

.distribution-percentage_cell .distribution-percentage_input[readonly], 
.distribution-percentage_cell .distribution-percentage_input[readonly]:hover {
    border: none;
    background-color: #fff;
    font-weight: normal;
}

.beneDetailsTable .distribution-percentage_cell {
    padding-top: 0;
}

.distribution-percentage_cell .form-group {
    margin-bottom: 0;
}

.verify-changes-container {
    display: flex;
    padding-right: 2.5rem;
    justify-content: flex-end;
}

.beneficiaryAccordionHeader {
    margin-top: 15px;
}

.selectExistingDropdown{
    background: #fff !important;
    color: #486D90 !important;
    border: 1px solid #486D90 !important;
    font-weight: bold !important;
    border-radius: 2px !important;
    padding: 12px !important;
    width: 100% !important;
}
.dropdown_relation{
    border: 1px solid #486D90;
    box-shadow: 0 14px 28px rgb(0 0 0 / 11%), 0 10px 10px rgb(0 0 0 / 8%);
    width: 100% !important;
    max-height: 250px;
    overflow-y: auto;
    position: absolute;
    /* top: 108px; */
    z-index: 999;
    background-color: #fff;
    padding: 0px 15px;
}
.dropdown_relation p{
    padding: 20px;
    margin: 0;
    cursor: pointer;
    font: 791 15px/19px yorkten-slab-normal, benton-sans, sans-serif;
    color: #486D90;
    width: 100% !important;
}