.white-text{
    color: white;
}

.center{
    text-align: 'center';
}

.step-banner{
    padding: 10px 0px 2px 10px;
    background-color: #486D90;
}
.RegulatoryInfo .card {
    border: none;
}
.RegulatoryInfo .card-body {
    padding: 0;
}
.RegulatoryInfo .card-body .row {
    margin-left: 0;
    margin-right: 0;
    position: relative;
}
.RegulatoryInfo .label, .RegulatoryInfo label {
    font-size: 16px;
    font-weight: 600;
    /* width: 285px;
    flex-basis: 25.7%; */
}
.childboxsection .alignRadioBtnTrustedContact{
    padding-left: 20px !important
}
.childboxsection .subchild .alignRadioBtn{
    padding-left: 200px !important;
}
.childboxsection .alignRadioBtn{
    padding-left: 110px !important;     
}

@media (max-width:767px) {
    .childboxsection .alignRadioBtn{
        padding-left: 20px !important;     
    }
    .childboxsection .subchild .alignRadioBtn{
        padding-left: 20px !important;
    }
}

 .label1 {
    font-size: 16px;
    font-weight: 600;
    width: 285px;
    flex-basis: 70.7%;
}
.RegulatoryInfo h2 {
    padding-left: 0;
}
.RegulatoryInfo .Slabel {
    margin-bottom: 25px;
}
.RegulatoryInfo button span{
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal; 
}

.childboxsection .formLabel{
    padding-left: 20px !important; 
}
/* .beneficiaryForm .childboxsection .datePickerRow .formLabel{padding-left: 7px !important;} */
.childboxsection p{
    padding-left: 20px;    
}
/* .childboxsection .marketbeneficiary p{ padding-left: 5px !important;} */

.TrustedContactInfo .card {
    border: none;
}
.TrustedContactInfo .card-body {
    padding: 0;
}
.TrustedContactInfo .card-body .row {
    margin-left: 0;
    margin-right: 0;
    position: relative;
}
.TrustedContactInfo .label, .TrustedContactInfo label {
    font-size: 16px;
    font-weight: 600;
    width: 285px;
    flex-basis: 25.7%;
}
.TrustedContactInfo button span{
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal; 
}
.TrustedContactInfo h2 {
    padding-left: 0;
}
.TrustedContactInfo .Slabel {
    margin-bottom: 25px;
}
.verify .sum-card-wrap>* {
    padding-bottom: 10px;
}
.verify .contactwrap {
    display: flex;
    flex-direction: column;
}
.verify .mailAddress {
    margin-bottom: 42px;
}
.verify .phy-heading {
    font: 700 16px/24px benton-sans,yorkten-slab-normal,sans-serif;
    margin-bottom: 12px;
}
.verify .contactAddress, .verify .phy-heading {
    text-align: left;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
}
.sum-prelink {
    padding-top: 15px;
    text-align: right;
    color: #5d83ae;
}
.sum-linktext {
    padding: 0;
    background: none;
    border: none;
    text-align: right;
    font: 800 15px/20px yorkten-slab-normal, benton-sans, sans-serif;
    letter-spacing: 0;
    color: #004a98;
}

 .sum-prelink img {
    margin-right: 5px;
    width: 15px;
    height: 15px;
    cursor: pointer;
}
.summary-radio-desc {
    margin-left: 55px;
    margin-top: 15px;
}
.radio-dec-item {
    margin-bottom: 10px;
}
.summary-para {
    margin-top: 10px;
}


.summary-heading {
    font: 700 18px/25px benton-sans, yorkten-slab-normal, sans-serif;
    letter-spacing: 0;
    color: rgba(73, 73, 74, 1);
    opacity: 1;
    margin-bottom: 2px;
}
.verify .accordianStyle{
    color:#4f799f !important;
}
.verify .accordianStyle::before{
    background-image: url(../../../assets/Icon_Accod_Open.svg) !important;

}
.verify .accordianStyle.expand::before{
    background-image: url(../../../assets/Icon_Accod_Close.svg) !important;

}
.sum-card-wrap div.card-list-wrapper{
    padding-bottom: 10px;
}
.edelivery-heading {
    font: 18px/25px benton-sans, yorkten-slab-normal, sans-serif;
    letter-spacing: 0;
    color: rgba(73, 73, 74, 1);
    opacity: 1;
    margin-top: 20px;
    margin-left: 10px;
}
.econsentHint {
    padding: 26px 25px 21px 31px;
    border: 1px solid rgba(210, 210, 210, 1);
    position: relative;
    font: 600 16px/22px benton-sans, sans-serif;
    color: #49494a;
    opacity: 1;
    margin-top: 25px;
    margin-bottom: 50px;
}
.econsentHint::before {
    content: '';
    width: 0px;
    height: 0px;
    border-bottom: 25px solid transparent;
    border-left: 25px solid rgba(139, 193, 5, 1);
    position: absolute;
    top: 0;
    left: 0;
}
.econsentError{  
    color: red;
    font: 700 12px/22px benton-sans, yorkten-slab-normal, sans-serif;
    position: static !important;
}
.marketbeneficiary button span{
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal; 
}

.label-value-broker-dealer{
    margin-left:150px;
}
@media(max-width: 540px){
    .label-value-broker-dealer{
             margin-left:0px;
    }
    }
    
    .addressMargin{
        margin-top: 20px;
    }

    .madatorytextstyle {
     margin-bottom: 50px;
    }
@media(max-width: 767px){
    .card-list-wrapper.market>div:first-child {
        flex-direction: inherit !important;
    }
    .mobileWicon{
        margin-left: 0px !important;
    }
    .mobileWicon svg{
        margin-right: 0px !important;
        margin-top:22px !important;
    }
    .sum-prelink{
        display: block !important;
        padding-top: 0px !important;
    }
}


/* Regulatory css */
.TooltipForInvestorProfileandRiskR{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 26%;
    top: -75px
}
.TooltipForInvestorProfileandRiskForJoint{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 40%;
    top: -75px
}
.TooltipForInvestorProfileRisk{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 98%;
    top: -170px;
}
.TooltipRegulatory{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left:98%;
    top: -60px
}
.TooltipForInvestmentObjective{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 98%;
    top: -70px
}

.TooltipRegulatoryForCompany{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 98%;
    top: -55px
}
.TooltipRelatedBrokerDealer{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 98%;
    top: -55px
}
.TooltipRegulatoryForPolitical{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 98%;
    top: -55px
}
.borderforverify{
position: absolute;
margin-top:55px;
width:100%;
}

@media screen and (max-width: 1024px) {
   .TooltipForInvestorProfileJoint{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 80%;
    top: -45px
}

}


/* Joint Regulatory */
.TooltipForInvestorProfileJoint{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 98%;
    top: -75px
}
.TooltipForInvestorProfileRiskJoint{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 98%;
    top: -198px
}

@media only screen and (max-width: 766px) {

.TooltipForInvestorProfileandRiskR{
    position: relative;
    left: 80%;
    top: -45px
}
.TooltipRegulatory{
    position: relative;
    left:80%;
    top: -110px
}


.TooltipRegulatoryForCompany{
    position: relative;
    left: 80%;
    top: -220px
}
.TooltipRelatedBrokerDealer{
    position: relative;
    left: 80%;
    top: -110px
}
.TooltipRegulatoryForPolitical{
    position: relative;
    left: 80%;
    top: -110px
}

}

@media only screen and (min-width: 410px) and (max-width: 420px) {

.TooltipRegulatory{
    position: relative;
    left:70%;
    top: -75px
}

.TooltipRegulatoryForCompany{
    position: relative;
    left: 70%;
    top: -165px
}
.TooltipRelatedBrokerDealer{
    position: relative;
    left: 70%;
    top: -80px
}
.TooltipRegulatoryForPolitical{
    position: relative;
    left: 70%;
    top: -80px
}

}









/* Trusted Conytact */
.TooltipforTrustedContact{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 20%;
    top: -75px
}


.TooltipforEdelivery{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 15%;
    top: -75px
}


.verifyscreenlinks{
    cursor: pointer;
    color: rgba(0, 74, 152, 1);
    text-decoration: underline;
    margin-top: 15px;
}

@media screen and (max-width: 767px) {
   .TooltipforEdelivery{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 40%;
    top: -45px
}

.TooltipforTrustedContact{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 80%;
    top: -45px
}
}

@media only screen and (min-width: 912px) {
    .label-DefaultValue{
        margin-left: 30px;
}
.DefaultValue{
      margin-left: -50px;
}
}