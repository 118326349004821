.hideIfMobile {
    display:block;
}
@media screen and (max-width : 767px) {
    .hideIfMobile {
        display: none !important;
        visibility:hidden !important;
    }
    .accListAlign h2 {
        position: relatove;
        margin-top:0px;
        margin-bottom:-20px;
    }
}
