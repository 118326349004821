.personalIfoFormWrapper .form-control {
    height: 50px;
    max-width: 445px;
    border-radius: 0px;
    border: 1px solid rgb(210, 210, 210);
}

.personalIfoFormWrapper .form-control.is-invalid {
    border-color: red;
    background-image: none;
}

.personalIfoFormWrapper .form-control.is-invalid + .invalid-feedback {
    color: red;
}

.personalIfoFormWrapper .card {
    border: none;
}
.uspsSuggession .card {
    border: 2px solid #ffffff;
}
.uspsSuggession .card .leftCornerCard {
    border-left: 35px solid #8cbf26;
}
.UploadPh > div,
.nonUsForm > div {
    flex-basis: 60%;
    max-width: 445px;
}
.UploadPh div label,
.nonUsForm div label {
    width: 160px;
    flex-basis: unset;
}
.UploadPh div .dzu-previewContainer + label,
.nonUsForm div .dzu-previewContainer + label {
    width: 0;
}
/* .nonUsForm > label{
    margin-bottom: 40px;
} */
.form8Address {
    display: flex;
    flex-direction: column;
    font-size: 16px;
}
.form8Address b {
    font-size: 18px;
    margin-bottom: 10px;
}
.w8FormExplain {
    position: absolute;
    top: 20px;
    font-size: 12px;
    color: #5d83ae !important;
    text-decoration: underline !important;
}
.personalIfoFormWrapper label,
.personalIfoFormWrapper .label {
    font-size: 16px;
    font-weight: 600;
    width: 285px;
    flex-basis: 25.7%;
    max-width: 285px;
}
.personalIfoFormWrapper .datePickerRow > .col-sm-5 {
    max-width: 255px;
}
.personalIfoFormWrapper .card-body .row .form-group label + .col-sm-5 {
    max-width: 445px;
}

.personalIfoFormWrapper .form-group label + div::after,
.PhNumberWrapper .inputDiv::after {
    position: absolute;
    left: 0;
    bottom: -20px;
    font-size: 12px;
    color: #56565a;
}
.hintText {
    font-size: 12px;
    color: #56565a;
}
.personalIfoFormWrapper label.dobdate + div::after {
    content: 'MM/DD/YYYY';
}
.personalIfoFormWrapper label[labelfor='SSN'] + div::after {
    content: 'XXX-XX-XXXX';
}

/*.PhNumberWrapper .inputDiv::after {*/
/*    content: 'XXX-XXX-XXXX';*/
/*}*/
.personalIfoFormWrapper label.optional::after {
    content: '(Optional)';
    font-weight: normal;
    font-size: 14px;
    color: rgba(86, 86, 90, 0.65);
    margin-left: 10px;
}
.personalIfoFormWrapper > .row {
    margin: 0;
}

.personalIfoFormWrapper h2,
.accordionHeader {
    position: relative;
    margin-bottom: 30px;
    font-size: 22px;
    line-height: 45px;
    color: #486d90;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    border-bottom: 1px solid rgba(210, 210, 210, 1);
    padding-bottom: 15px;
    padding-left: 35px; /* fixed based on design library accordion header */
}

.personalIfoFormWrapper .card-header h2 {
    padding-left: 32px;
    display: flex;
    justify-content: space-between;
    padding-right: 32px;
}

.card-header h2::before,
.accordionHeader::before {
    content: '';
    background-image: url('../../../assets/Icon_Accod_Close.svg');
    height: 20px;
    width: 20px;
    display: block;
    position: absolute;
    left: 0;
    top: 11px;
    background-repeat: no-repeat;
}
.card-header h2.expand::before,
.accordionHeader.expand::before {
    content: '';
    background-image: url('../../../assets/Icon_Accod_Open.svg');
}

.personalIfoFormWrapper .personalizeHeader {
    padding-left: 0;
}

/* .card-header h4.expand{
    border-bottom: 1px solid #CECECE;
} */

.personalIfoFormWrapper .dzu-dropzone {
    min-height: unset;
}
.personalIfoFormWrapper .accordion > .card {
    border: none;
    overflow: visible;
}
.personalIfoFormWrapper .card-header {
    background-color: transparent;
    border: none;
    padding: 45px 0 0;
}
.personalIfoFormWrapper .card-body {
    padding: 0;
}
.personalIfoFormWrapper .card-body .row,
.personalIfoFormWrapper .beneficiaryForm .row {
    margin-left: 0;
    margin-right: 0;
    position: relative;
}

.card-body .Slabel .container-fluid {
    flex-basis: 10%;
    margin-left: 0;
    padding-left: 0;
    margin-right: 1%;
}
.card-body .Slabel .container-fluid label {
    flex-basis: unset;
    width: unset;
    margin-bottom: 16px;
    font-weight: normal;
}
.phoneNumberGroup .PhNumberWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 445px;
}
.PhNumberWrapper .inputDiv {
    flex-basis: 58%;
    position: relative;
}

.PhNumberWrapper .selectDiv {
    flex-basis: 36%;
    position: relative;
}

.phoneNumberGroup #emailType {
    max-width: 185px;
    position: absolute;
    top: 0;
    right: 170px;
}

.addAnotherFieldBtn {
    color: rgb(0, 74, 152);
    font: 700 16px/22px benton-sans, sans-serif;
    background: transparent;
    border: none;
    padding: 0px 0px 0px 42px;
    position: relative;
    margin-top: 5px;
    margin-bottom: 70px;
}

.deleteFieldBtn {
    color: rgb(0, 74, 152);
    font: 700 16px/22px benton-sans, sans-serif;
    background: transparent;
    border: none;
    padding: 0px 0px 0px 42px;
    position: relative;
    margin-top: 15px;
    margin-bottom: 60px;
}

.phoneWrapper:last-child .addAnotherFieldBtn,
#emailAccordion .addAnotherFieldBtn {
    margin-bottom: 25px;
}

.serviseDates {
    display: flex;
    justify-content: flex-start;
}

.serviseDates .datePickerRow.row {
    flex-grow: 0;
    max-width: 255px;
    margin-right: 30px;
    padding-right: 0;
    /* position: relative; */
}
.personalIfoFormWrapper .serviseDates .datePickerRow.row {
    margin-bottom: 0;
}
.personalIfoFormWrapper .serviseDates.serviceDateError {
    margin-bottom: 30px;
}
.serviseDates input {
    width: 100%;
}
.fromDate::before,
.toDate::before {
    content: 'From';
    position: absolute;
    top: -30px;
    font: 600 16px/19px benton-sans, yorkten-slab-normal, sans-serif;
}
.toDate::before {
    content: 'To';
}
.personalIfoFormWrapper .fromDate::before,
.personalIfoFormWrapper .toDate::before {
    content: '';
}
.personalIfoFormWrapper .fromDate > label,
.personalIfoFormWrapper .toDate > label {
    margin-bottom: 10px;
}
.personalIfoFormWrapper .row > label:not(.phone-type):not(.contact-me-during):not(#PhoneNumber) {
    padding-right: 10px;
}

.srPolFig {
    border-bottom: 1px dashed #0d68b8;
    margin-left: 5px;
}

.personalIfoFormWrapper .Slabel {
    margin-bottom: 25px;
}

.emailSection.row #emailType {
    max-width: 185px;
    position: absolute;
    top: 0;
}

.primaryBenSwitchRow > div,
.primarySwitchRow > div {
    margin-left: 4px;
}

.emailAddressRow .emailDotButton::after,
.phoneNumberGroup .emailDotButton::after {
    content: '\2807';
    color: #004a98;
    font-size: 27px;
    height: 30px;
    width: 10px;
    display: inline-block;
}
.emailAddressRow .emailDotButton,
.phoneNumberGroup .emailDotButton {
    position: absolute;
    right: 30px;
    top: 30px;
    background-color: transparent;
}

.emailAddressRow .emailDotMenu,
.phoneNumberGroup .emailDotMenu {
    list-style: none;
    position: absolute;
    top: 70px;
    right: 15px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
    padding: 0;
    z-index: 2;
}

.emailAddressRow .emailDotMenu li .primaryDlt.emailDelete,
.phoneNumberGroup .emailDotMenu li .primaryDlt.emailDelete {
    cursor: default;
    opacity: 0.5;
}
.fieldWrapperDiv {
    background-color: rgba(250, 250, 250, 1);
    border: 1px solid rgba(210, 210, 210, 1);
    margin-bottom: 30px;
}
.fieldWrapperDiv > .row {
    padding: 30px;
}
.fieldWrapperDiv .row.primarySwitchRow {
    border-top: 1px solid rgba(210, 210, 210, 1);
}
.fieldWrapperDiv .emailAddressRow {
    padding-bottom: 5px;
}
.fieldWrapperDiv .phoneNumberGroup {
    padding-bottom: 0;
}
.fieldWrapperDiv .contactTimeRow {
    padding-bottom: 5px;
}
.fieldWrapperDiv > .row label,
.fieldWrapperDiv > .row .label {
    flex-basis: 24.3%;
}
.fieldWrapperDiv > .row .form-group label + .col-sm-6 {
    max-width: 445px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .emailSection.row #emailType {
        right: 21px;
        top: 1.5px;
    }
    .emailSection.row #select-arr {
        right: 45px;
    }
    .phoneWrapper #emailType {
        right: 21px;
        top: 1.5px;
    }
    .phoneNumberGroup.row #select-arr {
        right: 45px;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .emailSection.row #emailType {
        right: 11%;
    }
    .emailSection.row #select-arr {
        right: 120px;
    }
    .phoneWrapper #emailType {
        right: 11%;
    }
    .phoneNumberGroup.row #select-arr {
        right: 120px;
    }
}
@media only screen and (min-width: 1200px) {
    .emailSection.row #emailType {
        right: 16%;
    }
    .emailSection.row .select-arr {
        right: 45px;
    }
    .phoneWrapper #emailType {
        right: 16%;
    }
    .phoneNumberGroup.row .select-arr {
        right: 45px;
    }
}

.beneficiaryForm {
    margin-top: -10px;
}
.primaryPercAlloc + .beneficiaryForm {
    margin-top: 25px;
}
.benBtnWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.benBtnWrapper .rmvBtn {
    margin-bottom: 30px;
    margin-top: 30px;
}

.benBtnWrapper .addBtn {
    margin: 0;
}

.emailSection {
    flex-direction: row;
    justify-content: space-between;
}
.emailDotMenu::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 10px solid rgba(255, 255, 255, 1);
    position: absolute;
    top: -10px;
    right: 10px;
}
.emailDotMenu li {
    padding: 8px 10px;
    background-color: #fff;
}

.emailDotMenu .emailDelete {
    padding: 0;
    padding-left: 20px;
    background: #fff url('../../../assets/delete.png') no-repeat center left;
}

.distributionPercentageHint {
    background-color: rgba(72, 109, 144, 1);
    height: 60px;
    padding: 17px 30px;
    margin: 23px 0 40px;
}
.distributionPercentageHint p {
    color: #ffffff;
    font: 800 22px/25px benton-sans, yorkten-slab-normal, sans-serif;
}
.benBtnWrapper .addAnotherFieldBtn {
    margin-bottom: 30px;
}

.personalIfoFormWrapper .addressFields .container-fluid .col-sm-5,
.personalIfoFormWrapper .addressFields .container-fluid .col-sm-7 {
    padding: 0;
}
.personalIfoFormWrapper .addressFields .container-fluid .col-sm-7,
.personalIfoFormWrapper .dropdownRow .col-sm-5 {
    max-width: 445px;
}
.personalIfoFormWrapper .addressFields .container-fluid .col-sm-7 {
    flex-basis: 61%;
}
.personalIfoFormWrapper .addressFields .container-fluid label.col-sm-5 {
    flex-basis: 38.55%;
}

.personalIfoFormWrapper .form-group.row,
.personalIfoFormWrapper .dropdownRow.row,
.personalIfoFormWrapper .datePickerRow.row {
    margin-bottom: 30px;
}
.personalIfoFormWrapper .datePickerRow input[name='dateofBirth'] ~ small.errorMsgInputStyle {
    width: 150%;
}

.beneficiaryForm .datePickerRow input[name='beneficiaryDOB'] ~ small.errorMsgInputStyle {
    width: 150%;
}
.personalIfoFormWrapper #upload {
    background-color: transparent;
    border: none;
    padding: 0;
}
.centarizeLabel {
    display: flex;
    align-items: center;
}
.sameAddressDiv {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
}

.sameAddressSpan {
    width: 20%;
    display: flex;
    flex-direction: column;
}

/* .errorSameAddressDiv {
    width: 200%;
} */

.genderRenderStyle {
    display: flex;
    width: 200px;
    justify-content: space-between;
    transform: translate(0px, -10px);
}

.citizenshipRenderStyle {
    display: flex;
    width: 200px;
    justify-content: space-between;
    transform: translate(0px, 1px);
}



.TooltipForPersonalInfo{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 80%;
    top: -70px
}

@media screen and (max-width: 767px) {
   .TooltipForPersonalInfo{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 80%;
    top: -75px
}
}

.TooltipForPersonalPhysical{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 21%;
    top: -70px
}

@media screen and (max-width: 820px) {
   .TooltipForPersonalPhysical{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 80%;
    top: -70px
}
}

.TooltipForJointPersonalPhysical{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 35%;
    top: -70px
}

@media only screen and (max-width: 540px) and (min-width: 375px){
   .TooltipForJointPersonalPhysical{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 80%;
    top: 20px
}
}

@media only screen and (max-width: 912px) and (min-width: 768px){
    .TooltipForJointPersonalPhysical{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 80%;
    top: -70px
}


}
.networthmargin{
    margin-top: -25px;
}



