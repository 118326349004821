body {
    margin: 0;
    font-family: benton-sans, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #56565a !important;
    text-rendering: geometricPrecision;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

[inert] {
    pointer-events: none;
    cursor: default;
}

[inert],
[inert] * {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

/*
  app CSS reset
  */

.container-fluid {
    /* max-width: 1366px; */
    padding: 0px;
}

@font-face {
    font-family: 'benton-sans';
    src: url('fonts/BentonSans-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'benton-sans-bold';
    src: url('fonts/BentonSans-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'benton-sans-bold-italic';
    src: url('fonts/BentonSans-BoldItalic.otf') format('opentype');
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: 'benton-sans-italic';
    src: url('fonts/BentonSans-Italic.otf') format('opentype');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'benton-sans-medium';
    src: url('fonts/BentonSans-Medium.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'benton-sans';
    src: url('fonts/BentonSans-MediumItalic.otf') format('opentype');
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: 'yorkten-slab-normal';
    src: url('fonts/YorktenSlab-NorBol.otf') format('opentype'),
        url('fonts/yorktenslabnormbold-webfont.woff') format('woff'),
        url('fonts/yorktenslabnormbold-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'yorkten-slab-extra-bold';
    src: url('fonts/YorktenSlab-NorExB.otf') format('opentype'),
        url('fonts/yorktenslabnormexbold-webfont.woff') format('woff'),
        url('fonts/yorktenslabnormexbold-webfont.woff2') format('woff2');
    font-weight: bolder;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto Slab';
    src: url('fonts/RobotoSlab-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto Slab Light';
    src: url('fonts/RobotoSlab-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto Slab Bold';
    src: url('fonts/RobotoSlab-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
