.terms-wrapper {
    position: relative;
}

.terms-heading {
    margin-bottom: 40px;
    padding-bottom: 10px;
    padding-top: 40px;
    float: left;
    border-bottom: 2px solid #8CBF26;
    text-align: left;
    font: 800 30px/45px yorkten-slab-normal, benton-sans, sans-serif;
    letter-spacing: 0;
    color: #4F799F;
    opacity: 1;
}

.terms-content {
    padding-left: 0;
    padding-bottom: 45px
}

.terms-desc {
    text-align: left;
    font: 400 16px/25px benton-sans, yorkten-slab-normal, sans-serif;
    letter-spacing: 0;
    color: #49494A;
}

.list-terms {
    padding-bottom: 25px
}

.terms-subheading {
    text-align: left;
    font: 800 18px/35px yorkten-slab-normal, benton-sans, sans-serif;
    letter-spacing: 0;
    color: #49494A;
    opacity: 1;
}

.terms-points {
    text-align: left;
    font: 400 15px/25px benton-sans, yorkten-slab-normal, sans-serif;
    letter-spacing: 0;
    color: #56565A;
    padding-left: 40px;
}

.check-terms {
    background-color: #ECEEEF;
    border: 1px solid #CFD1D2;
    opacity: 1;
    font-family: benton-sans, yorkten-slab-normal, sans-serif;
    color: rgba(86, 86, 90, 1);
    font-size: 18;
    margin-top: 30px;
}

.terms-wrapper .check-content {
    padding: 20px 34px 16px 20px;
    background: #F0F4F7 0% 0% no-repeat padding-box;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: nowrap;
    display: inline-flex;
    letter-spacing: 0;
    opacity: 1
}

.terms-wrapper .check-content .checkBoxWrapper {
    margin: 0;
}

.terms-wrapper .date-time {
    text-align: left;
    font: 600 14px/25px benton-sans, yorkten-slab-normal, sans-serif;
    letter-spacing: 0;
    color: #56565A;
    opacity: 1;
}

.check-term-wrap input[type="checkbox"] {
    flex-shrink: 0;
}

.term-list {
    padding-bottom: 20px;
    padding-top: 20px;
    border-bottom: 1px solid #CECECE;
    position: relative;
}

.term-list div[icon] {
    position: absolute;
    top: 28px;
    left: -40px;
}

.terms-points div:last-child {
    border-bottom: none;
}

.boldText {
    text-align: left;
    font: 700 16px/25px benton-sans, yorkten-slab-normal, sans-serif;
    letter-spacing: 0;
    color: #49494A;
}

.boldText2 {
    text-align: left;
    font: 700 15px/25px benton-sans, yorkten-slab-normal, sans-serif;
    letter-spacing: 0;
    color: #56565A;
}

.check-term-wrap .checkBoxWrapper input[type="checkbox"]:checked:after {
    width: 8px;
    height: 16px;
    top: 4px;
    left: 10px;
}

.error-msg {
    color: red;
}
