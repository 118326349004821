#account-registration .milestone:not(.userManagemntTerms) .wizardWrapper {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: benton-sans;
    margin-bottom: 4em;
}

#account-registration .milestone:not(.userManagemntTerms) .stepperComp {
    width: 100%;
}

#account-registration .milestone:not(.userManagemntTerms) .stepperContainer{
    padding-left: 0;
    display: flex;
    justify-content: space-between;
    padding-right: 0;
    margin-bottom:0;
}

#account-registration .milestone:not(.userManagemntTerms) li.stepperCol{
    list-style: none;
}
#account-registration .milestone:not(.userManagemntTerms) .stepperIcon {
    display: flex;
}
#account-registration .milestone:not(.userManagemntTerms) .stepperText {
    display: block;
    max-width: 115px;
    text-align: center;
    font-size: 14px;
    margin-top: 9px;
    color:#004a98;
    font-weight: 600;
    line-height: 17px;
    -webkit-font-smoothing: antialiased;
}

#account-registration .stepperText + hr {
    top: 9px;
    position: absolute;
    left: 29%;
    border-top: 1px solid rgba(210, 210, 210, 1);
    width: calc( (700%-(5*55)) / (4) );
}


#account-registration .milestone:not(.userManagemntTerms) .visitedpage hr {
    border-top: 1px solid rgba(72, 109, 144, 1);
}


#account-registration .milestone:not(.userManagemntTerms) .activepage .circleSpan:before,
#account-registration .milestone:not(.userManagemntTerms) .visitedpage .circleSpan:before {
    content: "";
    width: 60px;
    height: 60px;
    border-radius: 50% 50%;
    position: absolute;
    border: 1.5px dotted;
    border-color: rgba(72, 109, 144, 1);
    z-index: 1;
    top: -5px;
    left: -5px;
}
#account-registration .milestone:not(.userManagemntTerms) .stepperDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#account-registration .milestone:not(.userManagemntTerms) .stepperCol:last-child .stepperDiv {
    float: right;
    margin-right: 0;
}


#account-registration .circleSpan {
    display: flex;
    border-radius: 50% 50%;
    width: 52px;
    height: 52px;
    color: #fff;
    justify-content: center;
    align-items: center;
    font: 800 14px/22px yorkten-slab-normal;
    z-index: 1;
    position: relative;
}

#account-registration .activepage .circleSpan {
    background-color: #486D90; 
    border: 1px solid  #486D90;
    color: #486D90
}

#account-registration .visitedpage .circleSpan {
    background-color: #75757A;
    border: 1px solid #75757A;
}

#account-registration .visitedpage .stepperText{
    color: #49494A;
}
#account-registration .tobevistedpage .stepperText {
    color: #56565A;
}

#account-registration .milestone:not(.userManagemntTerms) .tobevistedpage .circleSpan {
    background-color: rgba(247, 247, 247, 1);
    color: rgba(86, 86, 90, 1);
    border: 1px solid rgba(210, 210, 210, 1);
}

#account-registration .milestone:not(.userManagemntTerms) .activepage .circleSpan:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 10px solid rgba(255, 255, 255, 1);
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
}

@media only screen and (min-width: 1520px) {
    #account-registration .milestone:not(.userManagemntTerms) .stepperDiv {
        margin-right: 100px;
    }
}
@media only screen and (min-width: 1366px) and (max-width: 1519px) {
    #account-registration .milestone:not(.userManagemntTerms) .stepperDiv {
        margin-right: 90px;
    }
}

@media only screen and (max-width: 1365px) and (min-width: 1200px) {
    #account-registration .milestone:not(.userManagemntTerms) .stepperDiv {
        margin-right: 70px;
    }
    #account-registration .milestone:not(.userManagemntTerms) .stepperText + hr {
        left: 35%;
    }
}

@media only screen and (max-width: 1199px) and (min-width: 992px) {
    #account-registration .milestone:not(.userManagemntTerms) .stepperText + hr {
        left: 50%;
    }
    #account-registration .milestone:not(.userManagemntTerms) .stepperDiv {
        margin-right: 40px;
    }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
    #account-registration .stepperText + hr {
        top: 11%;
        position: absolute;
        left: 54%;
        right: unset;
    }
    #account-registration .milestone:not(.userManagemntTerms) .stepperDiv {
        margin-right: 15px;
        width: 80px;
    }
}

@media only screen and (max-width: 767px) {
    #account-registration .milestone:not(.userManagemntTerms) .wizardWrapper {
        margin: 15px 0 25px;
    }
    #account-registration .milestone:not(.userManagemntTerms) .stepperComp {
        width: 99%;
    }
    #account-registration .activepage .circleSpan:before,
    #account-registration .visitedpage .circleSpan:before {
        border: none;
    }
    #account-registration .milestone:not(.userManagemntTerms) .stepperCol:last-child .stepperDiv {
        float: none;
        margin-right: 0;
    }
    #account-registration .milestone:not(.userManagemntTerms) .circleSpan {
        width: 28px;
        height: 28px;
    }
    #account-registration .milestone:not(.userManagemntTerms) .activepage .circleSpan:before,
    #account-registration .milestone:not(.userManagemntTerms) .visitedpage .circleSpan:before {
        width: 34px;
        height: 34px;
    }
    #account-registration .milestone:not(.userManagemntTerms) .stepperText {
        display: none;
    }
    #account-registration .stepperText + hr,
    #account-registration .stepperCol:nth-last-child(2) .stepperDiv .stepperText + hr {
        top: -2px;
        left: 70%;
    }
    #account-registration .milestone:not(.userManagemntTerms) .stepperDiv {
        width: 40px;
    }
    #account-registration .milestone:not(.userManagemntTerms) .circleSpan svg {
        height: 15px !important;
        width: 10px !important;
    }
    #account-registration .milestone:not(.userManagemntTerms) .activepage .circleSpan:after {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 7px solid rgba(255, 255, 255, 1);
    }
}


@media only screen and (max-width: 540px) and (min-width: 400px) {
    #account-registration .milestone:not(.userManagemntTerms) .stepperDiv {
        margin-right: 20px;
    }
    #account-registration .milestone:not(.userManagemntTerms) .stepperText + hr,
    #account-registration .milestone:not(.userManagemntTerms) .stepperCol:nth-last-child(2) .stepperDiv .stepperText + hr {
        left: 50%;
    }
}
@media only screen and (max-width: 767px) and (min-width: 540px) {
    #account-registration .milestone:not(.userManagemntTerms) .stepperDiv {
        margin-right: 30px;
    }
    #account-registration .milestone:not(.userManagemntTerms) .stepperText + hr,
    #account-registration .milestone:not(.userManagemntTerms) .stepperCol:nth-last-child(2) .stepperDiv .stepperText + hr {
        left: 50%;
    }
}
@media only screen and (min-width: 1551px) {
    #account-registration .milestone:not(.userManagemntTerms) .stepperText + hr {
        left: 33%;
    }
}
#account-registration .milestone:not(.userManagemntTerms) .tabOnly {
    display: none;
}

@media only screen and (max-width: 1199px) and (min-width: 768px) {
    #account-registration .milestone:not(.userManagemntTerms) .tabOnly {
        display: block;
    }
}
