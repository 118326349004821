.performanceTable tbody tr:nth-child(even) {
    background-color: rgba(250, 250, 250, 1);
}
.dataLabel {
    padding: 18px 20px !important;
}
.dataValue {
    padding: 18px 20px !important;
}
@media (max-width: 767px) {
    .performanceTable tbody tr:nth-child(even) {
        background-color: transparent;
    }
    .dataLabel {
        display: block;
        padding: 30px 0px 0px 0px !important;
        min-height: 19px !important;
        margin-bottom: 8px;
    }
    .dataValue {
        padding: 0px 20px 0px 0px !important;
    }
    .inceptionDataValue {
        padding: 0px 20px 40px 0px !important;
    }
}