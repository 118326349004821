@media only screen and (min-width: 992px) {
    .create-plan-stepper {
        padding: 0 4rem;
    }
}

@media only screen and (max-width: 767px) {
   
    .breadcrumb li.breadcrumb-item
    {
        display: block;
    }
    .breadcrumb .breadcrumb-item+.breadcrumb-item
    {
        padding-left: 0px;
    }
    #breadcrumb .breadcrumb-item + .breadcrumb-item::before
    {
        top: -1px;
        left: 5px;
    }
    .overRightParentPadding
    {
        margin-left: -15px;
        margin-right: -15px;
    }
    .cancelHide
    {
        display: none;
    }
    .nextBt
    {
        order: 0;
    }
    .cancelBtn
    {
        order: 1;
        padding-top: 20px;
    }
    .cancelBtn button
    {
        float: none  !important;
    }

}