.esign-wrapper {
    min-height: 100vh;
    position: relative;
    /* padding:33px 10px 60px; */
    background: #ffffff 0% 0% no-repeat padding-box;
}
.esign-page-heading {
    margin-bottom: 40px;
    padding-bottom: 10px;
    padding-top: 40px;
    display: inline-block;
    border-bottom: 2px solid #8cbf26;
    text-align: left;
    font: 700 30px/45px yorkten-slab-normal, sans-serif;
    letter-spacing: 0;
    color: #4f799f;
    opacity: 1;
}
.esign-cards {
    padding-bottom: 10px;
    padding-top: 10px;
    font: Regular 16px/19px benton-sans, sans-serif;
    letter-spacing: 0;
    color: #333333de;
}
.esign-wrapper .checkwrapper {
    background: #f0f4f7 0% 0% no-repeat padding-box;
    opacity: 1;
    text-align: left;
    font: 500 16px/25px benton-sans, sans-serif;
    letter-spacing: 0;
    color: #3e607e;
    /* opacity: 1; */
}
.esign-wrapper .check-content {
    width: 100%;
    justify-content: space-between;
    padding-top: 35px;
    padding-bottom: 35px;
    flex-wrap: nowrap;
    display: inline-flex;
    padding-left: 20px;
    letter-spacing: 0;
    opacity: 1;
}
.esign-wrapper .check-content .checkBoxWrapper {
    margin-left: 30px;
    margin-right: 100px;
    width: 760px;
}
.esign-wrapper .check-content label {
    text-align: left;
    font: Medium 16px/25px benton-sans, sans-serif;
    letter-spacing: 0;
    color: #3e607e;
    opacity: 1;
}
.esign-wrapper .error-check {
    color: red;
    padding-top: 10px;
    padding-left: 0px;
}
.esign-wrapper .revenue {
    margin-top: 20px;
    text-align: left;
    font: 400 14px/24px benton-sans, sans-serif;
    letter-spacing: 0;
    color: #616366;
    opacity: 1;
    /* padding-bottom:70px; */
    padding-top: 15px;
}
/* .esign-wrapper .esign-buttons {
    padding-bottom: 20px
} */
.esign-wrapper .date-time {
    text-align: left;
    font: 600 14px/25px benton-sans, sans-serif;
    letter-spacing: 0;
    color: #56565a;
    opacity: 1;
    width: 300px;
    padding-left: 20px;
}
.esign-cards .card {
    border: none;
}
.esign-wrapper .card-body .Slabel .container-fluid {
    flex-basis: 10%;
    margin-left: 0;
    padding-left: 0;
    margin-right: 1%;
}
.esign-wrapper .card-body .Slabel .container-fluid label {
    flex-basis: unset;
    width: unset;
    margin-bottom: 16px;
    font-weight: normal;
}

.esign-wrapper .card-body .Slabel {
    justify-content: space-between;
}

.esign-wrapper .card-body .row {
    text-align: left;
    font: 600 16px/22px benton-sans, sans-serif;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    padding-bottom: 25px;
    padding-top: 25px;
}

.check-content .checkBoxWrapper input[type='checkbox']:checked:after {
    width: 8px;
    height: 16px;
    top: 4px;
    left: 10px;
}

.listWrapperDiv .card-list-wrapper {
    border-bottom: 1px solid rgba(210, 210, 210, 1);
    list-style: none;
}

.listWrapperDiv .card-list-wrapper:last-child {
    border-bottom: none;
}
.listWrapperDiv .sum-card-wrap {
    padding-left: 25px;
    margin-bottom: 0;
}

.listWrapperDiv .document-heading div[icon]:not(.pdfIcon) {
    position: absolute;
    left: -25px;
}

.listWrapperDiv .document-heading .row {
    position: relative;
}

.esignHint {
    padding: 26px 25px 21px 31px;
    border: 1px solid rgba(210, 210, 210, 1);
    position: relative;
    font: 600 16px/22px benton-sans, sans-serif;
    color: #49494a;
    opacity: 1;
    margin-bottom: 50px;
}
.esignHint::before {
    content: '';
    width: 0px;
    height: 0px;
    border-bottom: 25px solid transparent;
    border-left: 25px solid rgba(139, 193, 5, 1);
    position: absolute;
    top: 0;
    left: 0;
}

.fund-doc-desc {
    text-decoration: none;
    color: #004a98;
    opacity: 1;
    font-size: 18px;
    cursor: pointer;
    font-weight: 600;
}

.fund-doc-desc:hover {
    text-decoration: none;
    color: white;
    opacity: 1;
    font-size: 18px;
    background-color: #004a98;
}

.documents-to-review {
    margin-bottom: 20px;
}

.document-row {
    margin: 15px 0px;
}

.display-time-format {
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}
