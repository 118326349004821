/*Alert Dialog box alignment*/
@media screen and (max-width: 767px) {
    .vcm-alert-dialogMobile {
        padding: 60px 25px 30px !important;
    }
    .vcm-model-titlePara {
        color: #303030 !important;
    }
    .cancel-BtnAlign {
        margin-top: 30px;
    }
}
.popupfooter {
    padding: 30px 0px 0px 0px;
}
