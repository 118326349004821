.Input {
    width: 100%;
    padding: 0 0 30px 0;
    box-sizing: border-box;
    position: relative;
    line-height: 0;
}

.SelectInput {
    width: 100%;
    padding: 0 0 30px 0;
    box-sizing: border-box;
    position: relative;
    line-height: 0;
    margin-bottom: 0;
}

.Label {
    vertical-align: top;
    display: inline-block;
    margin-bottom: 5px;
    color: #56565A;
    font: 600 14px/20px 'benton-sans' , sans-serif;
}

input.InputElement,
select.InputElement {
    outline: none;
    background-color: white;
    font: 500 16px/20px 'benton-sans' , sans-serif;
    color: #49494A;
    padding: 15px 20px 15px;
    display: block;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #D2D2D2;
    height: 50px;
    border-radius: 0;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

input[readOnly].InputElement,
select[readOnly].InputElement {
     background-color: #FAFAFA;
}

.SelectElement {
    outline: none;
    background-color: white;
    font: 500 16px/20px 'benton-sans' , sans-serif;
    color: #49494A;
    padding: 15px 15px;
    display: block;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #D2D2D2;
    margin-bottom: 13px;
}

input.InputElement:focus {
    outline: 2px dotted #000;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
}

input.Invalid {
    border: 2px solid #E60000;
}

.errMsg {
    vertical-align: top;
    margin: 0;
    padding-top: 1px;
    color: #E60000;
    display: inline-block;
    font: 700 12px/22px 'benton-sans' , sans-serif;
}

.optional {
    font: 500 12px/20px 'benton-sans' , sans-serif;
    letter-spacing: 0;
    color: #56565A;
    opacity: 1;
    margin-left: 10px;
    position: relative;
    top: -1px;
}

.forgot {
    vertical-align: top;
    display: inline-block;
    position: absolute;
    right: 0;
    font: 600 12px/14px benton-sans , sans-serif;
    color: #004A98;
    margin: 0;
    padding-top: 5px;
}