.pdfDownloadLink .pdfIcon {
    margin-right: 2px;
    height: 25px;
}

.pdfDownloadLink:not(:last-child) {
    border-bottom: 1px solid rgb(206, 206, 206);
}

.summaryCardWrapper .dollar::before {
    top: 0;
    left: -16px;
}

.dowlodLinkListWrapper {
    padding-left: 25px;
}
.riskLabel {
    margin-right: 10px;
}

.riskSpan {
    justify-content: space-between;
    margin-bottom: 11px;
}

.summaryPara {
    text-align: justify;
    margin-top: 27px;
}

.monthlyInvestmentStyle {
    margin-bottom: 47px;
}
