.pdfViewer {
  max-width: 1200px !important;
}

.pdfViewer .modal-body {
  padding: 0px;
}

@media screen {
  .react-pdf__Document {
    max-height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.PDFPage>canvas {
  max-width: 100%;
  height: auto !important;
}
.modal.pdfView {
  z-index: 9999;
}
.pdfView:focus {
  outline: none;
}

@media print {
  .react-pdf__Document {
    max-height: auto;
  }
}

.pdfViewer .modalHeader {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  width: 100%
}

.printPdf {
  background: url('../../../assets/icon-print.svg') no-repeat left top;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 60px;
  border: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.downloadPdf {
  margin-right: 20px;
  background: url('../../../assets/icon-save.svg') no-repeat left top;
  display: inline-block;
  width: 25px;
  height: 25px;
  text-decoration: none;
  border: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.linkPdf {
  background-color: #fff;
  border: none;
  outline: none;
}

.pdfView .closeButton {
  background: url('../../../assets/icon-btn-close.svg') no-repeat left top;
  width: 26px;
  height: 39px;
  border: 0;
  padding: 0;
}
.react-pdf__Page.PDFPage{
  border-bottom: 1px solid;
}
.react-pdf__Page.PDFPage:last-child{
  border-bottom: none;
}