.main {
    padding-top: 40px;
}

.main.confirm-otp {
    margin-top: 13px;
}

/* .container-fluid.sign-in-page > .container {
    max-width: 1140px !important;
} */

.container-fluid.sign-in-page {
    background: transparent url('../../../assets/signInBackground_new.png') 0% 0% no-repeat padding-box;
    min-height: 650px;
    background-size: cover;
}

.vcm-register {
    text-align: center;
    min-height: 300px;
    width: 100%;
    max-width: 500px;
    background-color: white;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
    padding: 54px 94px;
    box-sizing: border-box;
    position: relative;
    border-collapse: separate;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Right {
    position: relative;
    right: -80%;
}

.NavigationButtons {
    width: 95%;
}

.ButtonLeft {
    position: relative;
    left: 5%;
}

.ButtonRight {
    float: right;
}

.Message {
    width: 100%;
    height: 44px;
    background-color: #f2f2f2;
    border: 1px solid #dadbdb;
    margin-bottom: 20px;
    padding-top: 5px;
    text-align: center;
}

.rulesWrapper {
    color: #49494a;
}

.rulesWrapper .rules {
    padding: 0;
}

.rulesHeading {
    font: 600 16px/22px benton-sans , sans-serif;
    margin-bottom: 18px;
    display: block;
}

.ruleListIcon {
    line-height: 0;
    margin-bottom: 18px;
}

.ruleListText {
    font: 500 14px/22px benton-sans , sans-serif;
    margin-left: 10px;
    margin-bottom: 16px;
}

.circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #75757a;
    position: relative;
    display: inline-block;
}

.filledCircle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #8cbf26;
    position: relative;
    display: inline-block;
}

.tick {
    position: absolute;
    top: 6px;
    left: 5px;
    width: 10px;
    height: 5px;
    transform: rotate(-45deg);
    border-left: 2px solid white;
    border-bottom: 2px solid white;
}

.displayFlex {
    display: flex;
}

.table tbody.securityAnswers+tbody {
    border-top: 2px solid #D2D2D2;
}

@media screen and (max-width: 768px) {
    .container.sign-in-page .main.row {
        margin: 0;
    }
}
/* Added below lines to override -> .container: max-width:100% !important;  for ipad and other screens*/
@media (min-width: 320px) and (max-width: 1199px) {
    .container-fluid .container.custom-container {
        padding: 0 15px !important;
    }
    .custom-button-container .container .row {
        margin: 0;
    }
}
@media (min-width: 768px) and (max-width: 991px) {

    .header.aem-GridColumn.aem-GridColumn--default--12 {
        min-height: 72px;
    }
    .container-fluid.sign-in-page > .container {
        /* max-width: 720px !important; */
        padding: 0 15px !important;
    }
}
@media screen and (max-width: 1199px) and (min-width: 992px) {
    .container-fluid.sign-in-page > .container,
    header.container ,
    .footer .container , #vcm-foot .content-wrapper  {
        max-width: 960px !important;
    }
}
/* Added below lines to override -> .container: max-width:100% !important;  for ipad and other screens*/

@media (min-width: 1200px) {
    .container-fluid.sign-in-page > .container {
        max-width: 1140px !important;
    }
}
@media (min-width: 1367) {
    .NavigationButtons {
        width: 90%;
    }
}

.signinPageLogo {
    display: block;
    margin-right: 30px;
    border-right: 1px solid #D2D2D2;
    padding-right: 30px;
}

.signinFooter {
    margin-top: 30px;
    display: flex;
    align-items: center;
}
.signinPageLink {
    max-width: 185px;
    font: 500 16px/20px benton-sans , sans-serif;
    color: #56565A;
}
.signinPageLink .inlineLink {
    color: #004A98;
    font-weight: 600;
}

.personalDetailsMainDiv .sideHeaderH1tag {
    max-width: 425px;
}