#upperRow .nav-item a#user-dropdown:visited, #upperRow .nav-item a#user-dropdown:focus {
    color: #fff;
    font-size: 14px;
}

#lowerRow ul li a:visited, #lowerRow ul li a:focus {
    color: #fff;
    font-size: 18px;
}

.col-sm-4 {
    box-shadow: none !important;
}

.see-all-faq {
    display: none;
}

.faqdetails h2.blueHdng-greenBorder:before {
    display: none !important;
}

.container-fluid {
    padding-right: 0 !important;
    padding-left: 0 !important;
}