@media only screen and (max-width: 992px) {
   .dzu-dropzone {
    flex-direction: column !important;   
  }
  .dropZoneContainer .dzu-previewContainer {
    flex-direction: column;
    padding: 5px;
  }
  .dropZoneContainer .dzu-previewImage{
      margin-bottom:10px;
  }
}
.dropZoneContainer .dzu-dropzone{
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  vertical-align: middle;
}
.dropZoneContainer .dzu-previewContainer {
  min-height: 60px;
  padding: 20px;
  background-color: rgba(250, 250, 250, 1);
  margin-bottom: 0;
  order: 2;
}
.dropZoneContainer .dzu-dropzone div.dzu-previewContainer:not(:first-child) {
  margin-top: 5px;
}
.dropZoneContainer .dzu-previewContainer .dzu-previewFileName {
  font: 500 16px/19px benton-sans, sans-serif;
  color: rgba(0, 74, 152, 1);
}
.dropZoneContainer .dzu-previewButton{
  background: none !important;
  width: auto;
  min-height: 19px;
  margin-bottom: 0;
}
.dropZoneContainer .dzu-previewButton::after {
  content: 'Delete';
  color: rgba(0, 74, 152, 1);
  font: 700 15px/19px yorkten-slab-normal, sans-serif;
  display: inline-block;
  padding-left: 26px;
  background: url('../../assets/close-icon.svg') no-repeat left center;
}

.dropZoneContainer .browseLabelCorner {
  border-right: 18px solid rgba(210, 210, 210, 1);
  border-top: 18px solid transparent;
  transform: rotate(-90deg);
  position: absolute;
  top: 0;
  right: 0;
}

.dropZoneContainer .browseLabel{
  cursor: pointer;
  border: 0;
  background: none;
}

.dropZoneContainer .browseLabel[disabled]{
  cursor: default;
}
.dropZoneContainer label {
  width: 160px;
  flex-basis: unset;
}
.dzu-previewContainer + label {
  width: 0;
  order: 1;
}
.dropZoneContainer .browseLabel.focused {
  border: 2px solid #004A98;
  outline: 0;
  border-radius: 0;
}