.verifyAuthPref {
        font: 700 18px/24px benton-sans;
        margin-bottom: 23px;
        display: inline-block;
}

.confirm-otpivr .container-fluid {
        width: auto;
        margin-left: 0;
        margin-right: 25px;
}

.confirm-otpivr .form-group {
        padding-bottom: 0;
        margin-bottom: 0;
}

.confirm-otpivr .container-fluid label > div {
        display: none;
}

.memberStepper {
        /* padding-bottom: 70px; */
        padding-top: 4em;
        color: rgba(73, 73, 74, 1);
        max-width: 1140px;
        margin-left: auto;
        margin-right: auto;
}