#account-registration #vcm_name, #account-registration  #vcm_date{
 box-shadow: none;
 border: 0px;
 border-bottom: 1px solid;
 padding: 10px;
 color: rgba(0, 0, 0, 1);
}

#account-registration #vcm_name{
    width: 50%;
}

#account-registration #vcm_date{
    width: 8.125em;	
    border-bottom: none;	
    background: none;
}

#account-registration .announceLabel{
    margin-top: 0.5em;	
    width:65%;	
    font: 1em/1.6em benton-sans;
}
#account-registration .signContanier  #errorMessage {
    width: 70%;
}

@media only screen and (max-width: 767px) {
 #account-registration .announceLabel{
  display: block;
  width: auto;
 }
 #account-registration #vcm_name{
    width: 60%;
 }
}

#account-registration .pdfImage{
    margin-left: 10px;
}

#account-registration .dateLblContainer{
    margin-left: 2.5em;
}

#account-registration .dateTxtContainer{
    margin-left: 2em;
}


@media only screen and (max-width: 1023px) {
    #account-registration .dateLblContainer,
    #account-registration .dateTxtContainer{
        margin-left: 0;
    }

    #account-registration .dateTxtContainer #vcm_date{
        padding-left: 0px;
    }
}

@media only screen and (max-width: 767px) {
    #account-registration .dateComp{
        margin-top: 20px;
    }
}

#account-registration .userManagemntTerms .contentWrapper.pad {
 margin: 0 auto;
 padding-right: 0;
 padding-left: 0;
}

@media (min-width: 576px) {
 #account-registration .userManagemntTerms .contentWrapper.pad {
  max-width: 540px;
 }
}
@media (min-width: 768px) {
 #account-registration .userManagemntTerms .contentWrapper.pad {
  max-width: 720px;
 }
}
@media (min-width: 992px) {
 #account-registration .userManagemntTerms .contentWrapper.pad {
  max-width: 960px;
 }
}
@media (min-width: 1200px) {
 #account-registration .userManagemntTerms .contentWrapper.pad {
  max-width: 1140px;
 }
}

#account-registration #termsMileStone + #main-disclosure .defaultFooter2 {
 display: block;
}

#root ~ .modal-backdrop.show {
    z-index:99;
}

#account-registration .userManagemntTerms .headingRow {
    margin-top: 40px;
}

#account-registration .userManagemntTerms .tobevistedpage .circleSpan {
    color: rgba(86, 86, 90, 1)
}