.footer .footer {
    padding: 0 15px;
    margin-top: 30px;
}


/*social media css ended*/

.footer__textlink {
    float: left;
    list-style-type: none;
    font-size: 18px;
    padding-bottom: 21px;
}

.footer__logoContainer {
    padding-top: 48px;
}

.footer__copyright-Container--info {
    color: #FFFFFF;
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 13px;
    padding-bottom: 13px;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    line-height: 23px;
}

.footer__links--text ul li a {
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    text-align: left;
    letter-spacing: 0;
    color: #004A98;
    opacity: 100%;
    line-height: 20px;
    margin-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
}

.footer__links--text {
    display: inline-block;
    list-style-type: none;
    border-right: none;
    padding-left: 0;
}

.footer__links--text ul{
    display: flex;
    flex-wrap: wrap;
}
.footer__links {
    margin-bottom: 16px;
    margin-top: 30px;
    padding: 16px 16px 16px 0px;
}

.footer__copyright-Container {
    background-color: #486D90;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
}
@media screen and (max-width:768px) {
    .footer__logoContainer {
        padding: 15px;
    }
    .footer__links.row {
        margin: 30px 0 0 0;
        padding: 0px;
    }
}
@media screen and (min-width:768px) {
    .footer .footer {
        padding: 0;
    }
    .footer__copyright-Container {
        padding-left: 0;
    }
    .footer__links--text {
        display: inline-block;
        list-style-type: none;
        padding-left: 0;
    }
    .footer__heading p,
    .footer__text p {
        margin-bottom: 0;
    }
    .footer__text p {
        line-height: 24px;
    }
}

@media screen and (min-width:1200px) {
    .footer__text p {
        padding-top: 14px;
        padding-bottom: 0;
    }
    .footer__logoContainer {
        padding-top: 33px;
    }
    .socialmedia__icons p {
        margin-left: 30px;
        padding-left: 0;
    }
}

@media screen and (min-width:1025px) {
    .footer .footer {
        margin-top: 36px;
    }
}

.container {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

@media screen and (min-width: 1200px){
    .appContainer .container {
        max-width: 1110px !important;
    }

    #vcm-foot .container {
        max-width:100% !important;
    }
    .appContainer .responsivegrid .header .container {
        padding: 0;
        max-width: 100% !important;
    }
   

}
.showinline {
    display: none;
}
.desktopShow {
    display:none
}
.socialmedia__icons ul li{
    list-style: none;
}

@media screen and (min-width:768px){
    .desktopShow{display:block}
    .mobileShow{display:none}
    .showinline {
        display: inline-block;
    }

    #vcm-foot .content-wrapper {
        max-width: 1140px;
        margin: 0 auto;
        width: 100%;
    }
    
    .footer__links--text{
        padding-left:0px !important;
    }
    
    .footer__links--text ul{
        padding-left:0;
    }

    .footer__links--text ul li a {
        display: contents;
    }
}

