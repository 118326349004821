.uploadDocPara {
    margin: 30px 0 20px;
}
.uploadComponent .dzu-dropzone {
    overflow: auto;
    border: 2px dashed rgba(210, 210, 210, 1);
    min-height: 246px;
    margin-bottom: 40px;
}
.dzu-inputLabel {
    flex-direction: column;
    font: 800 15px/19px yorkten-slab-normal, benton-sans, sans-serif;
    color: rgba(0, 74, 152, 1);
}
.strongSpan {
    font: 600 16px/22px benton-sans, yorkten-slab-normal, sans-serif;
    color: rgba(73, 73, 74, 1);
}
.dzu-inputLabel::before {
    content: '';
    background: url(../../../assets/Icon_Drop.svg) no-repeat center;
    height: 50px;
    width: 50px;
    display: block;
    margin-bottom: 15px;
}
.orText {
    font: 500 16px/22px benton-sans, yorkten-slab-normal, sans-serif;
    color: rgba(73, 73, 74, 1);
    margin: 20px 0 25px;
}
/* .dzu-inputLabel {
    font: 800 15px/19px yorkten-slab-normal, benton-sans, sans-serif;
    color: rgba(0, 74, 152, 1);
} */
.dzu-inputLabel input {
    display: none;
}
.uploadComponent .limitsItemWrapper {
    margin-bottom: 30px;
}
.uploadComponent .limitsItemWrapper:last-child {
    margin-bottom: 0;
}
.uploadComponent .labelSpan.orText {
    width: 158px;
    display: inline-block;
    margin: 0;
    margin-right: 28px;
}
.uploadComponent .labelSpan.orText::after {
    content: ':';
    float: right;
}
.uploadComponent .orOption {
    margin: 40px 0;
    display: block;
}
.mailingAddress.col {
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    background: url(../../../assets/IKcon_Mail.svg) no-repeat left top;
    background-size: 3%;
}
.mailingAddress .addressLine {
    font: 500 14px/22px benton-sans, yorkten-slab-normal, sans-serif;
    color: rgba(73, 73, 74, 1);
}
.mailingAddress .line1 {
    margin-bottom: 15px;
    font: 800 18px/22px yorkten-slab-normal, benton-sans, sans-serif;
}
.uploadComponent {
    margin-bottom: 40px;
}
