.ssnLabel + div > .row > div:nth-child(2) {
    padding: 0 10px;
}

.radioError.error,
span.error-msg,
p.error-check,
.errorMsgInputStyle,
.errorMsgA {
    color: rgba(230, 0, 0, 1);
    font: 700 12px/22px benton-sans, yorkten-slab-normal, sans-serif;
    position: static !important;
}

.errorInputField {
    border: 2px solid rgba(230, 0, 0, 1) !important;
}

.ellipsisButton {
    background: none;
    border: none;
    padding: 0;
    height: 20px;
}

.ellipsisButton::after {
    content: '\2807';
    color: #004a98;
    font-size: 27px;
    height: 30px;
    width: 10px;
    display: inline-block;
}

.positionRelative {
    position: relative;
}

.floatLeft {
    float: left;
}

.ellipsisMenu {
    list-style: none;
    position: absolute;
    top: 100%;
    right: 0;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
    padding: 0;
    z-index: 1;
    display: none;
}

.ellipsisMenu.show {
    display: block;
}

.ellipsisMenu::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 10px solid rgba(255, 255, 255, 1);
    position: absolute;
    top: -10px;
    right: 10px;
}

.ellipsisMenu li {
    padding: 15px;
    background-color: #fff;
}

.ellipsisMenuButton {
    background: none;
    border: none;
    text-align: center;
    font: 600 16px/22px benton-sans, yorkten-slab-normal, sans-serif;
    color: rgb(0, 74, 152);
    padding: 0;
}

.linkButton {
    border: none;
    width: auto;
    height: auto;
}

.displayFlex {
    display: flex;
}

a.inlineLink {
    color: #004a98;
    text-decoration: underline;
    margin: 0 3px;
    display: inline-block;
}

hr {
    border-color: rgba(210, 210, 210, 1);
}

.linkButton.edit {
    padding-left: 21px;
    background: url('../assets/pencil-small.png') no-repeat center left;
}

.linkButton.add {
    padding-left: 30px;
    background: url('../assets/plusIcon.png') no-repeat center left;
}

.linkButton.apply {
    padding-left: 21px;
    background: url('../assets/hand-icon.png') no-repeat center left;
}

.linkButton.manage {
    position: absolute;
    right: 10px;
    top: 5px;
    background: none;
}

.transparentColor {
    color: transparent;
}

.linkButton.print {
    padding-left: 25px;
    background: url('../assets/print.png') no-repeat center left;
}

.linkButton.save {
    padding-left: 25px;
    background: url('../assets/saveIcon.png') no-repeat center left;
}

.textAlignRight {
    text-align: right;
}

.telephoneLink {
    color: rgba(0, 74, 152, 1);
    text-decoration: underline;
    font-weight: 600;
    margin: 0 3px;
}

.download-link {
    text-align: center;
    font: 800 16px/20px benton-sans, sans-serif;
    letter-spacing: 0;
    color: rgba(0, 74, 152, 1);
    opacity: 1;
    border: none;
    padding: 0;
    background: none;
    display: flex;
}

.download-link:hover {
    text-decoration: none;
}

.download-link > .pdfIcon {
    margin-right: 5px;
}

.alertWrapper div[class^='col'] {
    padding: 0;
}

.alertWrapper .styledAlert {
    padding: 1.875rem 1.0625rem 1.75rem 1.0625rem;
    background: #fcf1f2 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px #000000;
    border: 2px solid #eea7ad;
    opacity: 1;
    border-radius: inherit;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.alertWrapper .styledAlert p {
    margin-bottom: 0;
    text-align: left;
    font-family: 'benton-sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
}

.linkButtonHeader {
    background: transparent;
    color: #fff;
    border: none;
    margin: 0;
}

.wModalCommon .close {
    color: transparent;
    background: url('../assets/Icon__close.svg') no-repeat center left;
    opacity: 1;
}

.wModalCommon .close span:first-child {
    visibility: hidden;
}

.headerPopover .popover {
    width: 100%;
    margin: 0 auto;
    right: 0;
    max-width: 100%;
    margin-top: 8px;
    border-radius: 0;
    border: none;
    left: 5px;
}

.headerPopover .arrow {
    display: none;
}

.headerPopover .popover .WCardOverlay .cornerPosition {
    width: 0rem;
    height: 0rem;
    border-right: 60px solid transparent !important;
    border-top: 60px solid rgb(139, 193, 5);
    position: absolute;
    top: 0rem;
    left: 0rem;
    border-bottom: none !important;
    right: 0px !important;
}

.headerPopover .popover .popover-body {
    padding: 0 !important;
}

.subHeader .WCardOverlay {
    box-shadow: none;
}

.subLinks .WCardOverlay {
    background: #fafafa 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 0px 25px rgba(0, 0, 0, 0.17);
}

.clickedTitle {
    font-size: 30px;
    line-height: 32px;
    color: #486d89;
    font-family: yorkten-slab-normal, sans-serif;
    margin: 0 auto;
    width: 6.125em;
}

.borderBtm:after {
    content: '';
    display: inline-block;
    vertical-align: bottom;
    height: 1px;
    border-bottom: 2px solid #8bc105;
    width: 4.125em;
    position: relative;
    top: -20px;
}

.subLinks li {
    list-style-type: none;
    margin-bottom: 30px;
}

/* #advicePopOver .subLinks li:nth-child(3) {
    margin-top: 2px;
    display: inline-block;     
} */

.headerPopover .menuBackBtn:focus {
    outline: 2px solid #000000 !important;
    z-index: 1;
    outline-offset: 0;
    margin: 2px !important;
    text-decoration: none !important;
}

@media only screen and (max-width: 1024px) {
    #advicePopOver .headerul {
        white-space: nowrap;
    }
}

#dashboardDisclaimerList li {
    padding-right: 3px;
}

.footerSocialMediaList li {
    margin-right: 30px;
}

.headerPopover.adviceTools li,
.headerPopover.adviceTools li a {
    font: 700 18px/18px yorkten-slab-normal, sans-serif;
    color: #004a98;
    opacity: 1;
}

.subLinks .WCardOverlay:before {
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: #fff;
    border-width: 16px;
    top: 30px;
    right: 97%;
    transform: rotateZ(90deg);
    bottom: 0px;
}

.splitul {
    column-count: 2;
}

.imagePointer {
    cursor: pointer;
    margin-left: 2px;
}

.user-dropdown .dropdownItems,
.user-dropdown .dropdownItems:hover {
    text-align: left;
    font: 600 16px/20px yorkten-slab-normal, sans-serif;
    letter-spacing: 0px;
    color: #004a98;
    text-transform: capitalize;
    opacity: 1;
    padding: 20px 0px 20px 30px;
    min-width: 300px;
}

#headerUserNameDropdown
    a.dropdownItems.dropdown-item:hover
    #headerUserNameDropdown
    a.dropdownItems.dropdown-item:focus {
    background-color: #e6e6e6;
}

#productsPopOver .splitul li:nth-child(5) {
    margin-top: 110px;
    display: inline-block;
}

/* .dropdownItems:nth-child(1) {
    background: #E6E6E6 0% 0% no-repeat padding-box;
    opacity: 1;
}

.dropdownItems:nth-last-child(1) {
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
} */

#postLoginFooter {
    margin-top: 30px;
    background-color: #fff;
}

#postLoginFooter .container#postLoginFooterImage {
    margin-bottom: 55px !important;
    margin-top: 40px !important;
}

#postLoginFooter .container a:focus {
    outline-width: 2px;
    outline-style:  solid;
    outline-color: #000000;
}

#postLoginFooterLinks {
    margin-bottom: 29px !important;
}

.user-dropdown .dropdown-menu {
    width: 300px !important;
    left: -142px !important;
    padding-top: 0 !important;
    border-radius: 0 !important;
    top: 5px !important;
}

.user-dropdown .dropdown-menu .dropdownItems:active {
    background: rgba(230, 230, 230, 1) 0% 0% no-repeat padding-box;
    opacity: 1;
    color: #004a98;
}

.menu-items-popover {
    display: none;
}

.products .splitul li:nth-child(2) {
    padding-bottom: 0px;
    margin-bottom: 36px;
}

.adviceTools .splitul li:nth-child(2) {
    padding-bottom: 0px;
    margin-bottom: 40px;
}

/* .products .splitul li:nth-child(3),.adviceTools .splitul li:nth-child(3), */
.products .splitul li:nth-child(2n + 1),
.adviceTools .splitul li:nth-child(2n + 1) {
    padding-top: 4px;
}

/* .user-dropdown .dropdown-menu-items:before {
    content: " ";
	position: absolute;
	border-bottom: 15px solid #fff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
	/* top: -14px;
    right: 11px; 
    top: -10px;
    right: 40%;
    margin-left: -40%;
}*/

.user-dropdown .carret-up {
    content: ' ';
    position: absolute;
    border-bottom: 15px solid #fff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    /* top: -14px;
    right: 11px; */
    top: -10px;
    /* right: 15%; */
    /* margin-left: -15%; */
}

/* Search Button CSS */

#myNavBar {
    display: block;
    height: 100%;
    z-index: 9999;
}

.header-overlay {
    height: auto;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #486d90;
    overflow-x: hidden;
    transition: 0.5s;
}

.label-content {
    float: left;
    color: #fff;
}

.header-overlay a,
.header-overlay a:hover {
    text-decoration: none;
    font-size: 18px;
    color: black;
    display: block;
    transition: 0.3s;
    padding-left: 45px;
}

.header-overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
}

.hrTag {
    border-bottom: 1px solid #fffffe;
    opacity: 0.09;
}

.global-search-btn,
.global-search-btn:hover,
.global-search-btn:focus {
    position: absolute;
    right: 15px;
    top: 25px;
    color: #004a98;
    text-decoration: none;
    list-style-type: none;
    font-weight: bold;
}

.upperRow:hover,
.upperRow:focus,
.lowerRow:hover,
.lowerRow:focus,
.header-navlink-main:hover,
.header-navlink-main:focus {
    outline: none;
}

.global-search-text {
    font-size: 16px;
    font-weight: 400;
    top: 32%;
    position: absolute;
    padding-left: 45px;
}

.search-head-text {
    color: #fff;
    font-weight: bold;
    font-size: 1rem;
}

.type-ahead input[type='text'] {
    height: 75px !important;
    padding-left: 50px !important;
    font-size: 1rem;
}

.type-ahead [readonly] {
    display: none;
}

.type-ahead .rbt-menu.dropdown-menu {
    border-radius: 0 !important;
    border: none !important;
}

.global-search-icon {
    top: 22px;
    left: 15px;
    position: absolute;
    z-index: 9;
}

.recent-search-header {
    color: #fff;
    font-size: 22px !important;
    line-height: 26px;
    font-family: yorkten-slab-normal, sans-serif;
    margin-bottom: 20px;
}

.recent-search-items {
    margin-top: 40px;
}

.recent-search-items ul,
.recent-search-items ul:hover {
    padding: 0;
    margin: 0;
    column-count: 2;
}

.recent-search-items ul li,
.recent-search-items ul li:hover {
    list-style-type: none;
    text-decoration: none;
    padding: 10px 0;
    font: 100 18px/42px benton-sans, yorkten-slab-normal, sans-serif;
}

.recent-search-items ul li a,
.recent-search-items ul li a:hover {
    list-style: none;
    font-size: 16px;
    color: #d2d2d2;
    font-family: benton-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
    padding-left: 0;
}

.recent-search-items .search-history {
    width: 65%;
}
.wid25px{
    width:25px;
}
/* Dashboard Pixel Perfect Fixes */

/* .dropdown-toggle::after{
    display: none;
} */
/* .menu-header .carret-down{
    border-top: 0.375em solid;
    margin-left: 0.625em; 
    background: transparent url('../assets/Icon_MenuDown_header.svg') 0% 0% no-repeat padding-box;
} */

.menuDropIcon > span::after {
    content: url('../assets/Icon_MenuDown_header.svg');
    line-height: 1;
    position: relative;
    left: 10px;
    top: -2px;
}

.menuDropIcon:focus,
#lowerRow ul li button:focus {
    box-shadow: none;
    /* padding-right: 14px; */
}

#searchOverlayCloseDiv {
    float: right;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    margin: 0 20px 0 0;
}

#searchOverlayCloseDiv a:focus {
    text-decoration: none;
}

.headerCloseBtn span {
    color: white;
    font-size: 53px;
    cursor: pointer;
    width: 100%;
    display: block;
    line-height: 24px;
    text-align: center;
    opacity: 1;
    font-family: 'Times New Roman', sans-serif;
}

.headerCloseBtn span:last-child {
    display: block;
    font-size: 12px;
}

.menu-header .dropdown-toggle::after {
    border-top: 0.375em solid;
    margin-left: 0.625em;
    background: transparent url('../assets/Icon_MenuDown_header.svg') 0% 0% no-repeat padding-box;
}
.pageNotFoundContainer,
.redirectionContainer {
    max-width: 480px;
    margin: 70px auto 20px auto;
    text-align: center;
}
.redirectionContainer img {
    width: 300px;
}
.pageNotFoundContainer img {
    width: 200px;
}
.redirectionContainer p {
    font-size: 20px;
    margin-top: 20px;
    font-family: benton-sans, sans-serif;
    text-align: center;
    line-height: 22px;
    font-weight: 500;
    color: #004a98;
}
.pageNotFoundContainer h1 {
    text-align: center;
    margin-top: 40px;
    font-size: 28px;
    color: #486d90;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    font-style: normal;
}
.pageNotFoundContainer p {
    font-size: 16px;
    margin-top: 15px;
    font-family: benton-sans, sans-serif;
    text-align: center;
    line-height: 22px;
    font-weight: 500;
}
.pageNotFoundContainer a {
    font-family: benton-sans, sans-serif;
    font-weight: 600;
}
.pageNotFoundContainer h2 {
    text-align: center;
    font-size: 120px;
    margin-top: 5px;
    color: #486d90;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
}

/* Ipad Media Queries Portrait */
@media screen and (max-width: 768px) {
    .headerPopover .popover {
        width: 100%;
        margin: 0 auto;
        right: 0;
        max-width: 100%;
        margin-top: 8px;
        border-radius: 0;
        border: none;
        left: 5px;
    }
    ul.navbar-nav .nav-item .overlay .overlay-content ul.splitul {
        column-count: auto;
    }
    .feeGatesWarningDiv {
        padding-right: 0px !important;
    }
    .ssn2BoxSize {
        max-width: 20.9% !important;
    }
    .radioBtnrow {
        display: block !important;
    }
    .radioBtncol {
        padding-left: 0 !important;
    }
    .investmentDetails {
        display: block !important;
        padding-top: 20px !important;
    }
    .investmentDetailsBorder {
        padding: 0 !important;
        border-left: none !important;
        margin-top: 25px;
    }
    .investmentDetailsBorder:first-child {
        margin-top: 0 !important;
    }
    .investmentValue {
        font-size: 16px !important;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .lowerRow {
        height: auto !important;
    }
}

@-moz-document url-prefix() {
    .menu-header a {
        color: #fff !important;
    }

    .menu-header .dropdown-menu a {
        color: #004a98 !important;
    }
}
