.paperFlowPage {
    padding-top: 40px;
    margin-bottom: 20px;
}
.paperFlowPage .headerRow {
    margin-top: 40px;
    border-bottom: 1px solid #d2d2d2;
    margin-bottom: 40px;
}
.paperFlowPage .headerRow .pageSubHeading {
    margin-bottom: 0;
}
.paperFlowPage .contentRow {
    margin-bottom: 10px;
}
.paperFlowPage .contentRow p {
    font: 500 16px/24px 'benton-sans';
    color: rgb(73, 73, 74);
    margin-bottom: 20px;
}
.uploadDocPara {
    line-height: 20px;
}
.paperFlowPage .cardColWrapper .card {
    margin-bottom: 0;
}
.paperFlowPage .cardColWrapper .card .card-body {
    padding: 45px 30px 42px;
    display: flex;
    flex-direction: column;
}
.paperFlowPage .cardColWrapper > div {
    max-width: 350px;
    margin-right: 30px;
    width: 350px;
    margin-bottom: 30px;
}
.paperFlowPage .cardColWrapper .card .leftCornerCard {
    border-left-color: #486d90;
}
.paperFlowPage .cardColWrapper .card:hover {
    outline: 1px solid #d2d2d2;
}
.paperFlowPage .contentRow .strongPara {
    font: 700 18px/22px 'benton-sans';
}
