.quickFactsRow:nth-child(odd) {
    background-color: rgba(250, 250, 250, 1);
}

.quickFactsRow {
    align-items: center;
    vertical-align: middle;
    display: flex;
    font: 600 16px/22px benton-sans, yorkten-slab-normal, sans-serif;
    letter-spacing: 0;
    color: rgba(73, 73, 74, 1);
    opacity: 1;
}

.quickFactsRow .quickFactsLabel {
    max-width: 380px;
    font-weight: 600;
}

.quickFactsRow .quickFactsLabel span {
    font: 500 12px/20px benton-sans, yorkten-slab-normal, sans-serif;
    letter-spacing: 0;
    color: rgba(86, 86, 90);
    opacity: 1;
    display: block;
}

.quickFactsValue {
    text-align: left;
    font: 500 16px/20px benton-sans, yorkten-slab-normal, sans-serif;
    letter-spacing: 0;
    color: rgba(73, 73, 74);
    opacity: 1;
}
