.detailedCradWrapper {
    width: 100%;
    background-color: rgba(250, 250, 250, 1);
    border: 2px solid rgba(250, 250, 250, 1);
    outline: 1px solid rgba(210, 210, 210, 1);
    margin-bottom: 40px;
    position: relative;
    text-align: left;
    padding: 0;
}
/* .detailedCradWrapper:focus, */
.detailedCradWrapper:hover {
    outline: 1px solid rgba(60, 90, 119, 1);
}
.detailedCradWrapper.selected {
    border: 2px solid rgba(140, 191, 38, 1);
    outline: 1px solid rgba(140, 191, 38, 1);
}
.detailedCradWrapper::before {
    content: '';
    width: 0;
    height: 0;
    border-bottom: 35px solid transparent;
    border-left: 35px solid rgba(210, 210, 210, 1);
    position: absolute;
    top: -2px;
    left: -2px;
}
.detailedCradWrapper.selected::before {
    border-left: 35px solid rgba(140, 191, 38, 1);
}
.detailedCradWrapper .row {
    margin-left: 0;
    margin-right: 0;
}
/* .detailedCradWrapper .descriptionRow{
    padding: 30px;
} */
.detailedCradWrapper .headerRow{
    padding: 23px 32px 21px 40px;
    justify-content: space-between;
    border-bottom: 1px solid rgba(210, 210, 210, 1);
}
.detailedCradWrapper .headerRow h3 {
    font: 800 18px/22px yorkten-slab-normal, sans-serif;
    color: rgba(72, 109, 144, 1);
    padding-left: 10px;
}
.detailedCradWrapper .headerRow span{
    font: 600 14px/20px benton-sans, sans-serif;
    color: rgba(86, 86, 90, 1);
}
.detailedCradWrapper .descriptionRow{
    font: 500 16px/20px benton-sans, sans-serif;
    color: rgba(73, 73, 74, 1);
    padding: 30px;
}
.detailedCradWrapper .descriptionRow .descriptionCol {
    min-width: 60%;
    padding-left: 20px;
    padding-right: 0;
}
.detailedCradWrapper .descriptionRow .descriptionCol:first-child {
    min-width: 40%;
    border-right: 1px solid rgba(210, 210, 210, 1);
    padding-left: 0;
    padding-right: 20px;
}
.detailedCradWrapper .descriptionCol span {
    font-weight: 600;
    margin-bottom: 26px;
    display: inline-block;
}
.detailsList  {
    list-style: none;
    padding-left: 0;
    margin-bottom: 31px;
}
.detailsList li {
    margin-bottom: 24px;
}
.detailsList li::before {
    content: '';
    border-left: 3px solid rgba(139, 193, 5, 1);
    border-bottom: 3px solid rgba(139, 193, 5, 1);
    height: 8px;
    width: 13px;
    display: inline-block;
    transform: rotate(-45deg);
    margin-right: 11px;
    margin-bottom: 2px;
}
.detailedCradWrapper .headerRow span > div {
    margin-left: 30px;
    display: inline-block;
}
.detailedCradWrapper.selected .headerRow {
    padding-right: 20px;
}
.accountTypePage .alertWrapper {
    margin-top: 2.5rem;
}
