/*Alert Dialog box alignment*/
@media screen and (max-width: 767px) {
    .vcm-alert-dialogMobile {
        padding: 30px 25px !important;
    }
    .vcm-model-titlePara {
        color: #303030 !important;
    }
    .cancel-BtnAlign {
        margin-top: 30px;
    }
}