.leftCornerCard + .rightArrow img {
    position: absolute;
    right: 11px;
    bottom: 11px;
    height: 32px;
    width: 32px;
}

#tooltipM
{
  position: absolute;
  font-size: 12px;
  display: inline-block;
  opacity: 1;
  width: 100px;
  height: 100px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 25px rgba(0,0,0,0.16);
  right: 3px;
  z-index: 9;
  /* margin-left: 225px; */
}



#tooltipM.bottom
{
  margin-top: 5px;
}

#tooltipM.bottom .tooltip-arrow
{
  top: 0;
  left: 50%;
  margin-left: 25px;
  border-width: 0 7px 7px;
  border-bottom-color: #ffff;
  margin-top: -11px;
}

#tooltipM .tooltip-arrow
{
  position: absolute;
  width: 10px;
  height: 10px;
  border-color: transparent;
  border-right-color: transparent;
  border-style: solid;
}

#tooltipM .tooltip-label
{   
    height: 19px;
    text-align: center;
    letter-spacing: 0;
    color: #0000FF;
    opacity: 1;
    margin-top: 10px;
    font-size: medium;
    font-weight: 600;
}

