.stepperComp {
    padding: 0;
    display: flex;
}
.stepperComp li {
    list-style: none;
}


.stepperText {
    display: block;
    max-width: 115px;
    text-align: center;
    /* margin-left: -10px; */
    font-size: 14px;
    margin-top: 9px;
    color: #004A98;
    font-weight: 600;
    line-height: 17px;
}

.stepperText + hr {
    width: calc(100% - 5%);
    top: 9px;
    position: absolute;
    left: 29%;
    border-top: 1px solid rgba(210, 210, 210, 1);
}


.visitedpage hr {
    border-top: 1px solid #75757A;
}


.activepage .circleSpan:before, .visitedpage .circleSpan:before {
    content: "";
    width: 60px;
    height: 60px;
    border-radius: 50% 50%;
    position: absolute;
    border: 1.5px dotted;
    border-color: #75757A;
    z-index: 1;
    top: -5px;
    left: -5px;
}
.stepperDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.stepperCol:last-child .stepperDiv {
    float: right;
    margin-right: 0;
}

.stepperCol:nth-last-child(2) .stepperDiv .stepperText + hr {
    width: calc(100% - 8px);
}

.circleSpan {
    display: flex;
    border-radius: 50% 50%;
    width: 52px;
    height: 52px;
    color: #fff;
    justify-content: center;
    align-items: center;
    font: 800 14px/22px yorkten-slab-normal benton-sans , sans-serif;
    z-index: 1;
    position: relative;
}

.activepage .circleSpan {
    background-color: #486D90; 
    border: 1px solid  #486D90;
    color: #486D90;
}

.visitedpage .circleSpan {
    background-color: #75757A;
    border: 1px solid #75757A;
}

.tobevistedpage .stepperText {
    color: #56565A;
}

.tobevistedpage .circleSpan {
    background-color: rgba(247, 247, 247, 1);
    color: rgba(86, 86, 90, 1);
    border: 1px solid rgba(210, 210, 210, 1);
}

.activepage .circleSpan:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 10px solid rgba(255, 255, 255, 1);
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
}

.stepperComp.stepperComp-4 .stepperText + hr {
    width: calc(200% - 5%);
}
.stepperComp.stepperComp-4 .stepperCol:nth-last-child(2) .stepperDiv .stepperText + hr {
    width: calc(200% - 8px);
}

.stepperComp.stepperComp-5 .stepperText {
    max-width: 130px;
}

@media only screen and (min-width: 1200px) {
    .stepperDiv {
        margin-right: 85px;
    }
    .stepperComp.stepperComp-4 .stepperDiv{
        margin-right: 60px;
    }
    .stepperComp.stepperComp-5 .stepperDiv {
        margin-right: 56px;
    }
}

@media only screen and (max-width: 1199px) and (min-width: 992px) {
    .stepperText + hr {
        left: 36%;
        width: calc(100% - 10px);
    }
    .stepperCol:nth-last-child(2) .stepperDiv .stepperText + hr {
        width: calc(100% - 12px);
    }
    .stepperDiv {
        margin-right: 48px;
    }
    .stepperComp.stepperComp-5 .stepperDiv {
        margin-right: 30px;
    }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
    .stepperText + hr {
        width: calc(100% - 5%);
        top: 11%;
        position: absolute;
        left: 54%;
        right: unset;
    }
    .stepperDiv {
        margin-right: 15px;
        width: 80px;
    }
    .stepperCol:nth-last-child(2) .stepperDiv .stepperText + hr {
        width: calc(100% - 5%);
    }
}

@media only screen and (max-width: 767px) {
    .stepperCol:last-child .stepperDiv {
        float: none;
        margin-right: 0;
    }
    .circleSpan {
        width: 28px;
        height: 28px;
    }
    .activepage .circleSpan:before, .visitedpage .circleSpan:before {
        width: 34px;
        height: 34px;
    }
    .stepperText {
        display: none;
    }
    .stepperText + hr, .stepperCol:nth-last-child(2) .stepperDiv .stepperText + hr {
        width: 30%;
        top: -2px;
        left: 70%;
    }
    .stepperDiv {
        width: 40px;
    }
    .circleSpan svg {
        height: 15px !important;
        width: 10px !important;
    }
    .activepage .circleSpan:after {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 7px solid rgba(255, 255, 255, 1);
    }
}

/* IE specific styles */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .stepperText + hr {
        left: 38%;
    }
    .stepperComp.stepperComp-5 .stepperDiv {
        margin-right: 103px;
    }
 }