#account-registration .preRegistrationStepsCard.card {
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    position: fixed;
    right: 4%;
    /* top: 136px; */
    /* width: 387px; */
    top: 9.6em;
    width: 24.1875em;
    text-align: center;
    padding: 32px 0 20px 30px;
    border-radius: 0;
    z-index: 1;
    height: auto;
    margin-top: 0;
}
#account-registration .preRegistrationStepsCard.card::after {
    content: '';
    width: 0px;
    height: 0px;
    border-bottom: 42px solid transparent;
    border-right: 42px solid rgba(139, 193, 5, 1);
    position: absolute;
    top: 0;
    right: 0;
}
#account-registration .preRegistrationStepsCard.card .card-body {
    display: flex;
    flex-direction: column;
}
#account-registration .bannerWrapper {
    background-color: #405e78;
}
#account-registration .headerWrapper {
    width: 60%;
    padding-top: 10%;
}

@media (min-width:1024px) {
    #account-registration .headerWrapper {
        /* padding-top: 4%; */
        padding-top: 4%;
        padding-bottom: 4%;
    }
}
#account-registration .welcomeHeader {
    display: flex;
    font: 800 42px/44px yorkten-slab-normal;
    flex-direction: column;
    margin-bottom: 0;
}
#account-registration .welcomeHeader small {
    font: 800 22px/44px yorkten-slab-normal;
}
#account-registration .userNameSpan {
    text-transform: capitalize;
    display: inline-block;
    margin-bottom: 10px;
}
#account-registration #account-registration .userNameSpan:empty{
    margin-bottom: 0;
}
#account-registration .headerDesc {
    font: 600 26px/33px yorkten-slab-normal;
    color: #FFFFFF;
}
#account-registration .headerDesc span {
    display: block;
}
#account-registration .headerDesc .tmspan {
    display: inline-block;
    font-size: small;
    vertical-align: super;
}
#account-registration .padRow.row {
    padding: 4em 10% 3em;
}

#account-registration .listPadRow.row {
    padding-top: 3.875em;
    /* padding-bottom: 10em;
    margin-bottom: 11em; */
    padding-bottom: 4em;
    margin-bottom: 4em;
    background-color: #F7F7F7;
}
#account-registration .preRegCardHeader {
    color: rgba(73, 73, 74, 1);
    font: 800 26px/30px  yorkten-slab-normal;
    margin-bottom: 15px;
    text-align: left;
    border-bottom: 2px solid rgba(139, 193, 5, 1);
    padding-bottom: 10px;
    float: left;
}
#account-registration .preRegistrationStepsCard .card-title + div{
    text-align: left;
}
#account-registration .preRegistrationStepsCard ol li {
    margin-bottom: 18px;
    font: 500 15px/26px benton-sans;
}
#account-registration .preRegBtnWrapper {
    text-align: center;
}
#account-registration .preRegBtnWrapper h3,
#account-registration .preRegBtnWrapper .preRegTxt {
    font:  700 14px/17px benton-sans;
    margin-bottom: 20px;
    margin-top: 31px;
    color: rgba(95, 95, 95, 1);
    padding-bottom: 0;
    border-bottom: none;
    display: block;
    text-align: center;
}
#account-registration .benefitsTiles {
    font: 1em/1.6em benton-sans;
}
#account-registration .benefitsTilesContent p {
    font: 1em/1.6em benton-sans;
    margin-bottom: 24px;
    line-height: 1.5em;
    margin-top: 16px;
}
#account-registration .pageHeader2 {
    font: 800 1.875em/0.78em yorkten-slab-normal, sans-serif;
    color: #486d90;
    padding-bottom: 10px;
    margin-bottom: 32px;
    line-height: 32px;
    display: inline-block;
    border-bottom: 2px solid #8bc105;
}
#account-registration .benefitsTiles > .container-fluid {
    padding: 0;
}

@media only screen and (min-width:992px) and (max-width:1199px) {

    #account-registration .preRegistrationStepsCard.card {
        right: 3%;
    }
    #account-registration .headerDesc {
        font: 600 20px/25px yorkten-slab-normal;
    }
    #account-registration .benefitsTiles.col-lg-8 {
        flex-basis: 100%;
        max-width: 100%;
    }
    #account-registration .headerWrapper {
        padding-top: 75px;
    }
}
@media only screen and (min-width:768px) and (max-width:991px) {
    #account-registration .headerWrapper {
        /*padding-top: 25px;*/
        width: 100%;
    }
    #account-registration .headerDesc {
        font: 600 20px/25px yorkten-slab-normal;
    }
    #account-registration .bannerWrapper  {
        margin-bottom: 20px;
    }
    #account-registration .preRegButton { /* vq-733 */
        margin-bottom: 1px;
    }

}
@media only screen and (max-width: 991px) {
    #account-registration .preRegistrationStepsCard.card {
        position: relative;
        width: calc(100% - 30px);
        padding: 18px 2px;
        margin: 0 auto;
        right: unset;
        top: unset;
    }
    #account-registration .preRegistrationStepsCard.card .card-title {
        text-align: left;
    }
    #account-registration .preRegistrationStepsCard.card ol li {
        line-height: 26px;
        font-size: 14px;
    }
    #account-registration .preRegCardHeader {
        font: 800 22px/30px yorkten-slab-normal;
    }
    #account-registration .preRegBtnWrapper h3,
    #account-registration .preRegBtnWrapper .preRegTxt {
        margin: 0;
    }
    #account-registration .preRegButton {
        position: fixed;
        bottom: 50px; /* vq-733 bot0*/
        left: 0;
        width: 100%;
        z-index: 2;
    }
    #account-registration .pre-reg-captcha {
        position: fixed;
        bottom: 50px; /* vq-733 bot0*/
        width: 100%;
        left: 0;
        z-index: 2;
        background-color: #405e78;
    }

    #account-registration .pre-reg-captcha div div {
        width: auto !important;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    #account-registration .preRegButton .vcmbtn {
        width: 100%;
        margin: 0;
    }
    #account-registration .preRegButton .vcmbtn::after {
        border-right-color: transparent;
    }
}
#account-registration .footerContainerFixed{
    position: fixed;
    margin-bottom: 0px;
}

@media only screen and (max-width:767px) {
    #account-registration .headerWrapper {
        padding: 30px 0 30px;
        width: 70%;
    }
    #account-registration .welcomeHeader {
        font: 800 26px/32px yorkten-slab-normal;
    }
    #account-registration .headerDesc {
        font: 600 18px/24px yorkten-slab-normal;
        margin: 0;
        padding: 15px;
        color: #49494A;
    }
    #account-registration .padRow.row {
        padding: 48px 0 20px;
    }
    #account-registration .benefitsTiles .benefitsTilesContent {
        display: flex;
    }
    #account-registration .benefitsTiles .benefitsTilesContent > svg{
        flex-basis: 12%;
        margin-right: 30px;
        flex-shrink: 0;
    }
    #account-registration .listPadRow.row {
        padding:50px 15px 60px;
    }
    #account-registration .benefitsTiles {
        font-size: 16px;
    }
    #account-registration .benefitsTiles > .container-fluid .row {
        margin: 0;
    }
    #account-registration .benefitsTilesContent span {
        margin: auto;
        padding-right: 30px;
        width: 83px;
        flex-shrink: 0;
        text-align: center;
    }
    #account-registration .preRegistrationStepsCard.d-none.d-sm-block,
    #account-registration .headerDesc.d-none.d-sm-block {
        display: none !important;
    }
    #account-registration .preRegistrationStepsCard.d-block.d-sm-none,
    #account-registration .headerDesc.d-block.d-sm-none {
        display: block !important;
    }
    #account-registration .pre-reg-captcha {
        margin-left: 0;
        left: 0;
        background-color: #405e78;
    }

    #account-registration .pre-reg-captcha div div {
        width: auto !important;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@media only screen and (min-width: 1600px){
    #account-registration .headerWrapper {
        width: 48%;
    }
}

#account-registration .preRegCount{
    width: 30px;
    height: 30px;
    margin: 0 14px 0 0;
    border: 1px solid #54565A;
    border-radius: 100%;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    font-family: yorkten-slab-normal;
    color: #54565A;
    display: flex;
    align-items: center;
    justify-content: center;
}

#account-registration .howToPreReg li:before{
    content: '';
    border: 0;
    margin: 0;
    width: 0;
}
