.nav-tabs .tabLinkStyle.nav-link {
    background-color: transparent;
    border: none;
    width: 160px;
    text-align: center;
    font: 600 16px/22px benton-sans, yorkten-slab-normal, sans-serif;
    color: rgba(0, 74, 152, 1);
    padding: 15px;
}

.nav-tabs .tabLinkStyle.nav-link.active {
    font: 600 16px/22px benton-sans, yorkten-slab-normal, sans-serif;
    position: relative;
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    border: 1px solid rgba(73, 73, 74, 0.87);
    opacity: 1;
    border-radius: 0px;
}

.nav-tabs .tabLinkStyle.nav-link.active::before {
    content: '';
    border-bottom: 1px solid rgba(73, 73, 74, 0.87);
    border-right: 1px solid rgba(73, 73, 74, 0.87);
    position: absolute;
    bottom: -8px;
    background: #fff;
    left: calc(50% - 7.5px);
    height: 15px;
    width: 15px;
    display: block;
    transform: rotate(45deg);
}

#aem-page-frame div, #aem-page-frame span, #aem-page-frame a, #aem-page-frame img{
    vertical-align: middle !important;
}

#aem-page-frame p{
    margin-bottom: 1rem !important;
}

