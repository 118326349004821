html {
    overflow-y: auto;
}
html,
body {
    height: auto !important;
    width: 100% !important;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #09d3ac;
}

.appContainer {
    align-items: center;
    display: flex;
    justify-content: center;
    overflow-y: visible;
}

.headerLogo {
    margin-top: 14.12px;
    width: 190px;
    height: 75px;
}

:focus,
button:focus,
.btn:focus,
a:focus > .headerLogo,
#postLoginFooter a:focus,
.portfolioLinks:focus {
    /* outline: 2px dotted #000 \*/
    outline-offset: 0px;
}
#maincontent a:focus {
}

button:focus,
.btn:focus {
    box-shadow: 0 0 0 0.2rem #fff !important;
}

.footerSocialMediaList a:focus,
.footerlinks a:focus {
    /* outline: 1px dotted;
    outline: 2px auto -webkit-focus-ring-color;
    outline-offset: 2px; \*/ 

    outline-offset: 0;
}

a:focus,
a:visited,
a:hover,
a:active {
    /* padding: 0; \*/ 
    color: rgba(0, 74, 152, 1);
    text-decoration: underline;
}

.icon-newpage {
    display: inline-block;
    width: 15px;
    height: 15px;
}

.icon-newpage:before {
    /* content: "\2197"; \*/
    content: url('assets/icon-external-link.svg');
    line-height: 1;
    speak: none;
    position: relative;
    left: 3px;
    top: 0;
}

select:focus,
.form-control:focus,
input:focus {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
}

input[type='checkbox'] {
    -webkit-appearance: initial;
    /* appearance: initial; \*/ 
    background: white;
    width: 18px;
    height: 18px;
    border: 1px solid rgba(86, 86, 90, 1);
    position: relative;
    border-radius: 0;
    margin-right: 10px;
    flex-shrink: 0;
}

input[type='checkbox']:checked {
    border-color: #4f799f;
}

input[type='checkbox']:hover {
    border-color: rgba(72, 109, 144, 1);
}

input[type='checkbox']:focus {
    border-color: rgba(0, 74, 152, 1) !important;
    box-shadow: 0 0 0 0.1rem rgba(0, 74, 152, 1) !important;
}

input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 5px;
    height: 10px;
    border: solid #4f799f;
    border-width: 0 2px 2px 0;
    transform: rotate(36deg);
    top: 2px;
    left: 6px;
    position: absolute;
    background-color: #fff;
}

.flex-container {
    display: flex;
    flex-flow: row wrap;
    margin-left: 15px;
}

/* The switch - the box around the slider \*/
.switch {
    position: relative;
    display: inline-block;
    width: 300px;
    height: 40px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 21px;
    opacity: 1;
}

/* Hide default HTML checkbox \*/
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider \*/
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    padding: 7px 58px;
    content: 'Yes';
    height: 39px;
    width: 150px;
    left: 0px;
    bottom: 0px;
    /* background-color: #818181 0% 0% no-repeat padding-box; \*/
    border: 1px solid #707070;
    transition: 0.4s;
    -webkit-transition: 0.4s;
    background: #818181dd 0% 0% no-repeat padding-box;
}

.slider:after {
    content: attr(data-on);
}

input:checked + .slider {
    background-color: #ffffff;
}

input:focus + .slider {
    box-shadow: 0 0 1px #ffffff;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(150px);
}

/* Rounded sliders \*/
.slider.round {
    border-radius: 50px;
}

.slider.round:before {
    border-radius: 21px;
}

.react-switch {
    float: right;
}

.backButton {
    background: url('../src/assets/leftArrow_green.png') no-repeat bottom left;
    background-size: 30px;
    background-position: 2px;
}

.cancelButton {
    background: url('../src/assets/icon-delete-red.png') no-repeat bottom left;
    background-size: 15px;
    background-position: 2px;
}

.loadMore {
    background: url('../src/assets/Icon_Down.svg') no-repeat bottom left;
    background-size: 15px;
    background-position: 2px;
}

.backButton:hover {
    background: url('../src/assets/leftArrow_blue.png') no-repeat bottom left;
    background-position: 2px;
}

.saveButton {
    background: url('../src/assets/saveIcon.png') no-repeat center left;
}

.saveButton:hover {
    background: url('../src/assets/saveIcon_Blue.png') no-repeat center left;
}

.mainScreen {
    background: url('../src/assets/independent_03.png') no-repeat bottom left,
        url('../src/assets/star_03.png') no-repeat top right;
    background-position-x: -20%, 107%;
    background-position-y: bottom, 0;
}

input[type='date'] {
    -webkit-appearance: initial;
    appearance: initial;
    /* background: white; \*/
    height: 48px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #dededf;
    border-radius: 4px;
    opacity: 1;
    position: relative;
    padding-right: 27px;
}

input[type='date']::-webkit-clear-button {
    font-size: 18px;
    height: 30px;
    position: fixed;
}

input[type='date']::-webkit-inner-spin-button {
    display: none;
}

/* input[type='date'] {
    position: relative;
    padding-right: 27px;
} Duplicate so commenting and putting in 222 \*/

input[type='date']::-webkit-calendar-picker-indicator {
    background-image: url('../src/assets/calendarIcon.png');
    background-repeat: no-repeat;
    color: transparent;
    opacity: 1;
    height: 20px;
    width: 20px;
    float: right;
    position: absolute;
    right: 0;
}

input[type='date']::-webkit-calendar-picker-indicator:hover {
    background-color: transparent;
}

.errorMsg {
    margin-left: 278px;
    margin-top: 5px;
    color: red;
    font: 600 12px/14px benton-sans, sans-serif;
}

.errorMsgAa {
    margin-left: 380px;
    margin-top: 5px;
    color: red;
    font: 600 12px/14px benton-sans, sans-serif;
}

.errorMsgA {
    margin-left: 140px;
    margin-top: -20px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

select.form-control,
input[type='text'],
input[type='number'],
input[type='date'],
input[type='password'] {
    height: 50px;
    border: 1px solid #d2d2d2;
    width: 100%;
    padding: 6px 10px;
    color: rgba(73, 73, 74, 1);
    -webkit-appearance: none;
    position: relative;
    border-radius: 0;
}

input[type='number'] {
    -webkit-appearance: none;
    -moz-appearance: textfield !important;
}

select.form-control::-ms-clear,
input[type='text']::-ms-clear,
input[type='number']::-ms-clear,
input[type='date']::-ms-clear,
input[type='password']::-ms-clear,
input[type='checkbox']::-ms-clear {
    display: none;
}

select.form-control.prefilled:disabled,
input[type='text'][readonly].prefilled,
input[type='number'][readonly].prefilled,
input[type='date'][readonly].prefilled {
    background-color: transparent;
    opacity: 1;
    border: none;
    padding-left: 0;
}

select.form-control:disabled,
input[type='text'][readonly],
input[type='number'][readonly],
input[type='date'][readonly] {
    background-color: rgba(250, 250, 250, 1);
    border-color: #d2d2d2;
}

select.form-control:hover,
input[type='text']:hover,
input[type='number']:hover,
input[type='date']:hover,
input[type='password']:hover {
    border-color: rgba(72, 109, 144, 1);
}

select.form-control:focus,
input[type='text']:focus,
input[type='number']:focus,
input[type='date']:focus,
input[type='password']:focus {
    outline: none;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
    border-color: rgba(0, 74, 152, 1);
}
input.form-control{font-weight: 700;}
select.form-control:disabled:focus,
input[type='text'][readonly]:focus,
input[type='number'][readonly]:focus,
input[type='date'][readonly]:focus,
select.form-control:disabled:hover,
input.form-control:disabled:hover,
input[type='text'][readonly]:hover,
input[type='number'][readonly]:hover,
select:hover,
select:focus input[type='date'][readonly]:hover {
    border-color: #d2d2d2;
    background-color: rgba(250, 250, 250, 1);
    box-shadow: unset;
}
input.form-control:disabled:hover {
    background-color: #f7f7f7;
}

input.form-control:disabled {
    background-color: #f7f7f7;
}

.mobile-quick-sign-in button:focus {
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
}

.userWizard {
    padding-top: 44px;
}

.strongMessage {
    font: 700 18px/22px benton-sans, sans-serif;
}

.card-deck a:hover {
    text-decoration: none;
}

.card-deck a:focus .card {
    outline: 1px solid #3c5a77;
}

/* .enterOtp #resendOtp {
  background-image: url('../src/assets/icon_resend.svg');
} \*/

.messageBarDiv strong {
    font: 700 18px/22px benton-sans, sans-serif;
}

abbr[title] {
    text-decoration: none;
}

.skiptocontent.skiptocontent:focus,
.skiptonavigation.skiptonavigation:focus {
    text-decoration: underline;
}

/* Accessbility issues \*/
.skiptocontent.skiptocontent:focus,
.skiptonavigation.skiptonavigation:focus,
.headerLogo:focus,
#upperRow ul li :focus,
#lowerRow ul li :focus,
.headerUpperRowIcons:focus {
    position: static;
    color: #ffffff;
  
}

ul#headerUserNameDropdown li :focus {
    color: #004a98;

    outline-offset: 0px;
}

.skiptocontent {
    color: #ffffff;
    text-decoration: underline;
}
.manageContactInfo button,
.managePhoneInfo button,
.manageEmailInfo button {
    text-align: right;
}
.captchaCont iframe {
    overflow: hidden;
}
.confirm-otpivr .wradio label {
    display: inline-block !important;
}

/* This will remove IE Issue for Select dropdown double arrow image: Abhinav \*/
select::-ms-expand {
    display: none;
}

body.using-mouse.modal-open .indivitualAccTypePage {
    filter: blur(2px);
}

/* .appContainer {
    max-width: 960px !important;
} \*/

.card {
    border-radius: unset !important;
}
.rightArrowIcon{
    width:25px;
    height:25px;
}
