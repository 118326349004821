.buttonImage {
    height:30px;
    width:30px;
}

.ButtonBoth {
    margin-top: 15%;
}
.tableDiv{
    margin-left: 5%;
    margin-right: 5%;
    text-align: left;
    width: 100%;  
}

.tableHeaderWidth2{
    text-align: left;
    width: 25%;
}

.secondColumn{
    width: 50%;
}
.thirdColumn{
    width: 25%;
}

.iraContributionFontSize{

    font-size: 14px;
    line-height : 22px;
}


@media only screen and (max-width:1020px){

    .tableDiv{
        text-align: left;
        width: 100%;  
        padding: 0%;
        margin: 0%;
    }

    .iraContributionFontSize{

        font-size: 12px;
        line-height : 22px;
    }
    
}

@media only screen and (max-width:765px){

    .iraContributionFontSize{

        font-size: 14px;
        line-height : 22px;
    }


}

