.oops-wrap {
    padding-top: 5%;
}
.oops-content {
    position: relative;
}
.oops-layout {
    position: absolute;
    left: 35%;
    top: 30%;
}
.oops-heading {
    font: 138px/35px Roboto Slab, yorkten-slab-normal, benton-sans, sans-serif;
    color: #544a54;
}
.oops-page {
    position: relative;
}
.oops-desc {
    font: Bold 18px/35px Roboto Slab, yorkten-slab-normal, benton-sans, sans-serif;
    color: #544a54;
    position: absolute;
    top: 60px;
    width: 400px;
}
.oops-img {
    padding-top: 5%;
}
