.errormessage{
    color: #e60000 !important;
    font: 700 12px/22px benton-sans,yorkten-slab-normal,sans-serif;
    text-align: right;
}
.productnamelist{
    text-decoration: underline;
}
.listdata{
    word-break: break-all;
}
.linkview{
    color: #007bff;
}
.linkview:hover{
    cursor: pointer;
    text-decoration: underline;
}
.disbaleCheckbox{
    background: #efefef !important;
    border-color: #cccccc !important;
}
.cardContainer {
    padding: 15px !important;
}

.cardContainer .WCardOverlay {
    padding: 25px;
}
/* Added custom css as classname 'box-heading' for Box as Styledcss/macro was failing in testcases and were forced to remove /macro to make it work in test cases but UI was failing.  */
.box-heading{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-size: 30px !important;
    float: left;
    color: rgb(72, 109, 144);
    border-bottom: 2px solid rgb(139, 193, 5);
    font-weight: 800;
    font-family: yorkten-slab-normal, sans-serif;
    padding-bottom: 10px;
}
.balance-amount {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.offerZone .rtHeading .card-link:focus {
    margin-left: 5px;
    outline: 2px solid #000;
    outline-offset: 0px;
}

.offerZone .rtHeading {
    font-size: 15px;
    margin-bottom:5px;
}

.cardHeight::-webkit-scrollbar,
.offerZone::-webkit-scrollbar,
.dataScroll::-webkit-scrollbar  {
    display: none;
}

.roman.cardHeight.card-body::-webkit-scrollbar,
.graph-main .chartDetails::-webkit-scrollbar,
.actualdocuments::-webkit-scrollbar{
    width: 5px;
    display: block;
}

.roman.cardHeight.card-body::-webkit-scrollbar-thumb,
.graph-main .chartDetails::-webkit-scrollbar-thumb,
.actualdocuments::-webkit-scrollbar-thumb{
    background: #486d90 0% 0% no-repeat padding-box;
    /* border-radius: 0.1875rem;  Removing Border radius to make it uniform across all browsers*/
}

/* Only IE */
.roman.cardHeight.card-body,
.graph-main .chartDetails,
.actualdocuments{
    scrollbar-face-color: #486d90;
    scrollbar-width: thin;
    /* scrollbar-shadow-color: #8ea5b5; 
    scrollbar-highlight-color: #8ea5b5; */
}
/* Only FireFox */
.roman.cardHeight.card-body,
.graph-main .chartDetails,
.actualdocuments {
    scrollbar-color: #486d90 #ffffff;
    scrollbar-width: thin;
}

.guestCardTitle:focus{
    outline: 2px solid #000 !important;
    outline-offset: 0px;
}

.roman.cardHeight {
    flex-basis: 40%;
    min-height: 23.125rem;
    max-height: 23.125rem;
    overflow-y: auto;
    overflow-x: hidden;
}

.WCardOverlay {
    text-align: left;
    
    width: 100%;
    /* max-width: 540px; */
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0rem 0.1875rem 0.375rem;
    padding: 30px 30px;
    box-sizing: border-box;
    position: relative;
    border-collapse: separate;
}

.custom-pt-4 {
    padding-top: 1.08em;
}

.graph-main {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    max-height: 400px;
    min-height: 300px;
    /* align-items: center; */
}

.graph-main .chartMain {
    flex-basis: 60%;
    min-height: 300px;
    max-height: 400px;
    margin-left: -15px;
    max-width: 400px;
}

.graph-main .chartMain .showBlankScreen {
    position: absolute;
    display: none;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #ffffff;
}

.graph-main .chartDetails {
    flex-basis: 56%;
    min-height: 390px;
    max-height: 390px;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
    padding-right: 5px;
    margin-left: -90px;
    z-index: 1;
}

/* .ach-message-center-main .actualmessages {
    min-height: 400px;
    max-height: 400px;
    overflow: hidden;
} */

.ach-document-center-main .actualdocuments {
    min-height: 370px;
    max-height: 370px;
    overflow-y: auto;
    overflow-x: hidden;
}

.guest-portfolio .WCardOverlay {
    padding-bottom: 11px !important;
}

.list-items .items-list:last-child,
.activity:last-child > div {
    border-bottom: none;
}

.ach-message-center-main .actualmessages ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.holding-group-main .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(210, 210, 210, 1);
}

.holding-group-main .head p {
    margin: 0;
    overflow: auto;
}

.dashboard .leftCornerCard {
    display: none;
}

.dashboard .card-body {
    padding: 10px !important;
}

.dashboard .card-text {
    max-width: none !important;
}

.dashboard .rightArrow.card::after {
    top: 7px;
    right: 10px;
    bottom: 0px;
}

.dashboard .roman.card-body {
    padding: 0px !important;
}

.placeOrderBtn {
    border-radius: 0rem;
    border: none;
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 1rem;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    height: 3.125rem;
    width: 100%;
    position: relative;
    background-color: #486d90;
    white-space:nowrap;
}

.placeOrderBtn:hover {
    background-color: #3c5a77;
}

.FileSubmitBtn {
    border-radius: 0rem;
    border: none;
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: .8rem;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    height: 30px;
    width: 40%;
    float: right;
    position: relative;
    background-color: #486d90;
    white-space:nowrap;
}

.FileSubmitBtn:hover {
    background-color: #3c5a77;
}

.btncorner {
    border-bottom: 1.1875rem solid transparent;
    border-right: 1.1875rem solid #8cbf26;
    position: relative;
    top: 1.1875rem;
    z-index: 1;
}

.cardAjust {
    margin-top: -45px;
}

.trans-table-start:hover {
    /* padding-right: 5px !important; */
    /* overflow: auto !important; */
}

.trans-table-start {
    border-bottom: 1px solid #d2d2d2;
}

tr.transactTable td {
    padding: 10px 10px 8px 10px;
}

tr.transactTable:nth-child(1) td {
    padding: 14px 10px 8px 10px;
}
.transactTable .arrowUpAndDown div{
    display: inline-block;
}
.transactTable .arrowUpAndDown div img{
    position:static!important;
}
.transactTable .arrowUpAndDown{
height: 35px;
margin-left: -10px;
}
.acc-activity-main .acc-act-header {
    /* position: absolute; */
    top: 0;
    left: 0;
    right: 0;
}

.acc-activity-main .acc-act-header p {
    margin: 0;
}

.acc-activity-main .acc-act-body {
    /* position: absolute; */
    top: 80px;
    left: 0;
    right: 0;
}

.acc-act-foot .list-group-item {
    padding-right: 0px;
}

.transContainer {
    padding-bottom: 50px;
}

.containerBg {
    background: #f7f7f7 0% 0% no-repeat padding-box;
    padding-bottom: 60px;
    border: 1px solid #d2d2d2;
    border-top: none;
    opacity: 1;
}

.dataScroll {
    overflow: auto;
}


.acc-act-foot .card .card-link {
    font-weight: 500 !important;
    font-size: 15px;
}

.dashboard-cotainer .mainButtonCorner {
    display: none;
}

.dashboard .hideArrow .card-img {
    display: none;
}

.highCharts .highcharts-container {
    left: -40px !important;
}

.containerBg .accordion {
    padding-bottom: 10px;
    border-bottom: 1px solid #d2d2d2;
    margin-bottom: 10px;
}

.containerBg .accordion:last-child {
    border: none;
}

.borderDesign {
    border: 1px solid #d2d2d2;
    border-top: none;
}

.NavBack {
    border-top: 1px solid #d2d2d2;
}

.dashboard .cardContainer .card-body .card-text {
    overflow: auto;
    font-size: 14px !important;
    line-height: 20px !important;
}

.welcomeMsgClass{
    display: flex;
    justify-content: space-between;
}

.loginDts {
    text-align: right;
    font: 500 0.875rem/0.625rem benton-sans,sans-serif;
    letter-spacing: 0px;
    color: rgba(73, 73, 74, 1);
    opacity: 1;
    margin-bottom: 0px;
    margin-top:8px;    
}

/*.dashboard .acctypecards .card-title {*/
/*    font-size: 16px !important;*/
/*    line-height: 18px !important;*/
/*}*/

.acctypecards .card > button {
    min-height: 142px;
}

.media-trans-history-table .table th {
    padding: 0px 0px 10px 0px !important;
}

.media-trans-history-table .table th:nth-child(-n + 2) {
    text-align: center !important;
}

.media-trans-history-table .table th:nth-last-child(2) {
    text-align: center !important;
}

.transActivity .table th:nth-child(-n + 3),
.transActivity .table td:nth-child(-n + 3) {
    text-align: left !important;
}

.transActivity .table th:nth-child(n + 4) {
    width: 10.5%;
}

.transActivity .table th:nth-child(1),
.transActivity .table td:nth-child(1) {
    width: 6.5%;
    padding-left: 10px !important;
}

.transActivity .table th:nth-child(2),
.transActivity .table td:nth-child(2) {
    width: 23.5%;
    font-weight: 600;
}

.transActivity .table th:nth-child(3) {
    width: 13%;
}

.transActivity .table th:nth-last-child(1) {
    width: 15%;
}

.pendingStatus {
    color: #c3821a;
    font-style: italic;
    font-size: 14px;
    line-height: 17px;
}

.transActivity .table-striped thead,
.transactTable .table-striped thead {
    border-bottom: 3px solid rgb(139, 193, 5);
}

.transActivity .table-striped tbody tr:nth-child(even),
tbody tr.transactTable:nth-child(even) {
    background-color: #f7f7f7;
}

.transActivity .table-striped tbody tr:nth-child(odd),
tbody tr.transactTable:nth-child(odd) {
    background-color: #ffffff;
}

.cardset .card-text {
    max-width: 80% !important;
}

.contact-center-act .side-header,
.contactCenter .side-header {
    margin-bottom: 0 !important;
}

.contact-live-chat .modal-content {
    border: none !important;
}

.contact-live-chat .chat-modal-dialog {
    position: fixed !important;
    right: 0px;
    bottom: 0;
    top: 50px;
}

.contact-live-chat [data-testid='service-modal-header-2'],
.contact-live-chat [data-testid='service-modal-body'],
.contact-live-chat [data-testid='service-modal-footer'] {
    display: none;
}

.loader-spin {
    background: rgba(0, 0, 0, 0.58);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}

.loader-spin svg {
    height: 40% !important;
    position: relative;
    top: 50%;
}
/* .dateRangeClass {
    height: 40px;
} */

.transactTable:nth-child(even) {
    background-color: #f7f7f7;
}

.dateRangeClass,
#tranActivityEndDate,
#tranActivityStartDate {
    height: 40px;
}

.highChartCenter {
    position: absolute;
    bottom: 155px;
    left: 13.59%;
    text-align: center;
    transform: translate(-50%, 0px);
}
.holding-sub-group {
    padding-left: 25px;
}
.alertcontainercustom .alert.alert-danger {
    position: inherit !important;
}

.anchorSpan .linkTestClr{
    color: #004a98;
}
.anchorSpan .linkTestClr:focus{
    outline: 2px solid #000;
}

.no-gutters:last-child {
    border-bottom: none !important;
}

/* New Dashboard Styles */
.tabbox{
    background: #fff;
}
.launchbtn{margin-top:3rem !important;}
.launchbtn a{color: #486D90 !important;}
.tooltip{
    border:none !important;
    background: none !important;
}
.tablink.first{
    border-bottom: 4px solid #486D90 !important; 
    background-image: linear-gradient(to top, #486D90 10%, transparent 0%) !important;
    background-size: 100% 200% !important;
    background-position: top;
    box-shadow: inset -4px 4px 4px rgba(0, 0, 0, 0.05);
    transition: background-position 0.5s ease-in-out;}

.tablink.first:hover{ background-position: bottom;box-shadow: inset -4px -2px 4px rgba(0, 0, 0, 0.05) !important;}
.tablink.second{
    border-bottom: 4px solid #7A2C7B !important;
    background-image: linear-gradient(to top, #7A2C7B 10%, transparent 0%) !important;
    background-size: 100% 200% !important;
    background-position: top;
    box-shadow: inset -4px -2px 4px rgba(0, 0, 0, 0.05);
    transition: background-position 0.5s ease-in-out;
    padding: 17px 15px 27px !important;
}
.tablink.second:hover{background-position: bottom;}
.tablink.third{
    border-bottom: 4px solid #00ADEE !important;
    background-image: linear-gradient(to top, #00ADEE 10%, transparent 0%) !important;
    background-size: 100% 200% !important;
    background-position: top;
    box-shadow: inset -4px -2px 4px rgba(0, 0, 0, 0.05);
    transition: background-position 0.5s ease-in-out;
    }
.tablink.third:hover{background-position: bottom;}
.tablink.fourth{
    border-bottom: 4px solid #8BC13F !important;
    background-image: linear-gradient(to top, #8BC13F 10%, transparent 0%) !important;
    background-size: 100% 200% !important;
    background-position: top;
    box-shadow: inset -4px -2px 4px rgba(0, 0, 0, 0.05);
    transition: background-position 0.5s ease-in-out;
    margin-bottom:0px;
    }
    .tablink.fifth{
        border-bottom: 4px solid #CF481E !important;
        background-image: linear-gradient(to top, #CF481E 10%, transparent 0%) !important;
        background-size: 100% 200% !important;
        background-position: top;
        box-shadow: inset -4px -2px 4px rgba(0, 0, 0, 0.05);
        transition: background-position 0.5s ease-in-out;
        margin-bottom:0px;
        padding: 15px 3px 27px !important;
        }
.tablink.fourth:hover{background-position: bottom;}
.tablink.fifth:hover{background-position: bottom;}
.tablink{padding: 29px 3px 27px !important; border-radius: 0px !important;text-align: center; min-height: 84px; max-height: 84px;
    font: 800 20px / 1.5rem yorkten-slab-normal; color: #004A98;
    line-height: 24px; margin-bottom: 2px;}
/* .alerttablink{padding:20px 15px 12px !important;} */
.tablink.active{background: none !important; color: #fff;}
.tablink:hover img{display: none !important;}
.tablink.active img{display: none !important;}
.tablink.first.active{background: #486D90 !important;}
.tablink.second.active{background: #7A2C7B !important;}
.tablink.third.active{background: #00ADEE !important;}
.tablink.fourth.active{background: #8BC13F !important;}
.tablink.fifth.active{background: #CF481E !important;}
.dynamicadvisorbullet{background: #CF481E !important;}
.moblistbox a, .savedTitlelink a {
    color: #004A98;
}
.mcardLink{margin-right: 20px !important;}
.fundsTitle{font: 900 36px / 1.5rem yorkten-slab-normal; color: #486D90;}
.tabmenusection{ padding-right: 3px !important;}
.tabsection{padding-right: 0px !important;}
.tableftColumn{max-width:13% !important;}
/* .leftContentbox p,.leftContentbox li{color:#333;} */
.tabRightColumn{max-width:87% !important;background: #fff;}
.GuestRightBox{padding-right: 0px !important; }
.leftContentbox{padding-left: 30px !important; padding-top:20px; padding-bottom: 10px; padding-right: 50px !important; }
.leftTitle{font: 800 30px / 1.5rem yorkten-slab-normal; color: #486D90; line-height: 45px;}
.smallLeftboxText{display: inline-flex; padding-left: 0px; margin-bottom: 0px;}
.smallLeftboxText li{font: 600 0.8rem / 0.8rem benton-sans, sans-serif;font-size:10px;color: rgba(0,74,152,1); margin-left: 30px;}
.smallLeftboxText li:first-child{list-style-type: none; margin-left: 0px;}
.smallLeftboxText1{display: inline-flex; padding-left: 0px; margin-bottom: 0px;}
.smallLeftboxText1 li{font: 600 0.8rem / 0.8rem benton-sans, sans-serif;font-size:13px;color: rgba(0,74,152,1); text-decoration: underline; margin-left: 30px; line-height: 1.3;}
.smallLeftboxText1 li:first-child{list-style-type: none; margin-left: 0px;}
.rightBox{padding: 0px 47px !important;padding-top:130px !important;min-height: 453px;background: url(../../../assets/rightBox.png);background-size: cover;background-color: #486D90;}
.tabrightBox{display: none !important;}
.rightTitle{font: 800 22px / 1.5rem yorkten-slab-normal; color:#fff;}
.savedrightBox{padding-top: 72px !important;}
.savedtext .guestdismisstext{ font-size: 12px; font-weight:600;}
.savedtext a{color: #fff !important; text-decoration: underline;}
.fullBox{background:linear-gradient(90deg, #FFFFFF 42.06%, rgba(255, 255, 255, 0.31) 60.87%);}
.mainTitle{padding:20px; padding-bottom: 7px; padding-top: 30px;}
.guestbanner{min-height: 453px;}
.guestbanner1{background:linear-gradient(90deg, #FFFFFF 42.06%, rgba(255, 255, 255, 0.31) 60.87%), url(../../../assets/guestfund.png); background-size: 100% 100%;}
.guestbanner2{background:linear-gradient(90deg, #FFFFFF 42.06%, rgba(255, 255, 255, 0.31) 60.87%), url(../../../assets/guestmarket.png); background-size: 100% 100%;}
.guestbanner3{background:linear-gradient(90deg, #FFFFFF 42.06%, rgba(255, 255, 255, 0.31) 60.87%), url(../../../assets/guesteducation.png); background-size: 100% 100%;}
.mobguestbanner1{display: none !important;}
.mobguestbanner2{display: none !important;}
.mobguestbanner3{display: none !important;}
.tabguestbanner1{display: none !important;}
.tabguestbanner2{display: none !important;}
.tabguestbanner3{display: none !important;}
/* .highCharts{height:300px !important;} */
.imageComponent1 div .highcharts-container {
    /* width:100px !important; */
    height:100% !important;


}
.selectHeader{
    float: left;
    margin-right: 3px;
}
.HeaderCheckbox{
    display: inline-block;
}

.HeaderCheckbox input[type='checkbox']{
    margin-right:0px;
    width:14px;
    height:14px;
}
.HeaderCheckbox input[type='checkbox']:checked:after{
    width:4px;
    height:8px;
    left:5px;
    top:2px;
}
.checkboxCenter{
    text-align: center;
    margin-top: 50%;
}
.checkboxCenter input[type='checkbox']{
    margin-right:0px;
}
.documentCenterTable thead tr th{
    color:#000;
    vertical-align: middle;
}
.documentCenterTable thead tr th:first-child,.documentCenterTable thead tr td:first-child{
    width: 300px !important;
}
.documentCenterTable thead tr th,.documentCenterTable tbody tr td{
    padding:3px !important;
}
.documentCenterTable tbody tr td:first-child{
    word-break: break-all;
}
.documentCenterTable tbody tr td:first-child span{
    text-align: left !important;
}
.documentCenterTable thead tr th:last-child,.documentCenterTable tbody tr td:last-child{
    display: none;
}
.paginationbtn{
    background: #3c5a77;
    color: #fff;
}
.NewDashboardGraph{

}
/* .NewDashboardGraph div .highcharts-container svg{height: 330px !important;}
.NewDashboardGraph div .highcharts-container {
    width:100% !important;
    height:330px !important;} */

.imageComponent1 div .highcharts-container .highcharts-root{
    /* width:100% !important; */
    height: 100% !important;

}
.titletextnew{font-weight: 700 !important; color: #333 !important;}
.amountnew{color: #333;}
.tabFooterbox{background:#F2F2F2; padding:5px; position: absolute !important; bottom: 0px; right:0px; left:0px; border-bottom: 2px solid #dbdbdb;}
.memberTabsection{margin-right:-27px !important;}
.accountBox{padding: 20px 60px;}
.topHeader{background: #fff; width:100%; float: left;}
.lefttopHeader{width:19%; float: left;}
.righttopleftheader{width:11%; height: 79px; float:left;background-repeat: no-repeat; background-size: cover; }
.tabrighttopleftheader{display: none !important;}
.righttopheader{width:70%; height: 79px; float:left;background: #364e68; }
.rightmobtopheader{display: none !important;}
.righttopheadertext{font-size: 16px; padding: 15px; padding-right: 40px;}
.overviewRightbox{padding-left: 0px !important;}
.savedTitle{color: #333;font: 800 16px / 1.5rem yorkten-slab-normal; line-height: 20px; margin-left: 1.3rem;}
.savedTitlelink{ font-size:12px; font-weight: 700; text-decoration: underline; width: 100%; float: left; }
.savedTitlelink a{width:300px; float: left; text-decoration: underline; margin-left: 1.3rem;}
.unsavedTitlelink{ font-size:12px; font-weight: 700; }
.unsavedTitlelink a{ width:400px; float: left; font-size:12px; font-weight: 700; }
.dismisstext{font-size: 12px; font-weight: 700;  float: right; color:#004A98;}
.blackinfoIcon{float: left;margin-right:8px; margin-top: 3px;height: 13px;}
.unfinisheddismisstext{font-size: 12px; font-weight: 700;  margin-left:20px; color:#004A98; text-decoration: none;}
.unfinishedBox{background: #E9F4FF;border: 1px solid #004A98;padding: 10px 20px;}
.unsavedTitle{color:#333;font: 800 16px / 1.5rem yorkten-slab-normal; line-height: 16px;}
.guesttabwrapper{background-color: white;box-shadow: rgb(0 0 0 / 16%) 0rem 0.1875rem 0.375rem;}
.guesttabsection{margin-right: -27px !important;}
.leftButtonbar{margin-left:17px !important; margin-bottom: 1rem;}
.bullets{width:14px; height:14px;float: left; border-radius:50%; background: #efefef;margin-top: 8px;margin-right: 10px;}
.fundsbullet{background: #7A2C7B;}
.marketbullet{background: #8BC13F;}
.educationbullet{background: #00ADEE;}
.savedDate{color: #56565a; margin-left: 1.3rem; font-size: 14px;}
.savedDateguest{color: #f2f2f2;  font-size: 14px;}
.SelectDropdownBox{display: none;}
.tableftColumn .nav{display: block !important;}
.tableftColumn .mobnav{display: none !important;}
.mobnav {
    display: inline-block;
    text-align: left;
    width: 100%;
    position: relative;
    background: #fff;
    padding-top:20px;
   }
  .mobnav  a {
    font: 800 20px / 1.5rem yorkten-slab-normal;
    text-decoration: none;
    text-transform: capitalize;
    border: 1px solid #949494;
    width:170px;
    margin-left: 30px;
  }
  .mobnav.is-open a{
    border-bottom: none !important ;
  }
  .mobnav  a.overviewtext{color:#486D90 !important;}
  .mobnav  a.nametext{color:#486D90 !important;}
  .mobnav  a.fundstext{color:#7A2C7B !important;}
  .mobnav  a.marketstext{color:#8BC13F !important;}
  .mobnav  a.educationtext{color:#00ADEE !important;}
  .mobnav a:hover, .mobnav a:focus{
    text-decoration: none;
    color: inherit;
  }
  .mobnav__list li a{border:none !important; margin-left:0px !important;}
  .mobnav__list,
  .mobnav__option {
    margin:  0;
    padding: 0;
    display: inline;
  }
  .mobnav__chevron {
    display: none;
  }

    .mobnav__list,
    .mobnav__option {
      display: block;
    }
    .mobnav__chevron {
      display: inline-block;
      transform: rotate(90deg);
      float: right;
      margin-right: 3px;
      font-size: 28px;
      color: #434344;

    }
    .mobnav__option {
      padding: 0.3rem;

    }
    .mobnav__option  :hover
    {
      background: #fff;
    }
    .mobnav__list {
      display: none;
      list-style-type: none;
    }

    .is-open .mobnav__list {
        border:1px solid #949494;
        border-top: none;
        display: block;
        width: 170px;
        position: absolute;
        z-index: 99;
        background: #fff;
        left: 30px;

      }
      .is-open .mobnav__chevron {
        transform: rotate(270deg);
      }

#tabselectdropdown{font: 800 20px / 1.5rem yorkten-slab-normal;border: 1px solid #949494; padding: 4px 8px; margin-top:20px;}
.NewDashboardGraph div .highCharts .highcharts-container {left:0px !important; margin: auto;}
.overviewfundname{color: rgb(0, 74, 152);font-size: 20px;font-weight: 600;font-family: benton-sans,sans-serif}
.overviewAmount{font-size:20px;color:#333;font-weight: 400;font-family: benton-sans,sans-serif}
.trianglebullet{height: 25px; margin-left:5px;}
.errorbalance{font-size:16px; font-weight: bold;}
.refreshicon{height:25px !important;}
.refreshtext{color:#004A98; font-size:16px;font-weight: bold;}
.refreshtext:hover{ text-decoration:underline}
.refreshbtn{ color: #fff; background:#364E68; font-size:14px;  margin-left:5px;padding: 5px; padding-bottom: 3px; border-radius: 5px;}
.fundsName{padding-right: 2rem !important;}
.blogbannerBox{padding: 0px 15px 15px 10px; height:105px;}
.blogTitle{font: 600 16px / 1.5rem yorkten-slab-normal;color:#fff; position: absolute; left:30px;line-height: 20px; bottom: 15px;}
.blogTitle::after{position: absolute;left: 0px;bottom: -5px;width: 80%;height: 1px;border-bottom: 2px solid #8bc105;content: "";}
.blog1banner{background:linear-gradient(180deg, rgba(0, 0, 0, 0.08) 0%, #000000 81.25%),url(../../../assets/Blog1.png);}
.blog2banner{background:linear-gradient(180deg, rgba(0, 0, 0, 0.08) 0%, #000000 81.25%),url(../../../assets/Blog2.jpg);}
.blog3banner{background:linear-gradient(180deg, rgba(0, 0, 0, 0.08) 0%, #000000 81.25%),url(../../../assets/Blog3.jpg);}
.placeOrderBtnNew {
    border-radius: 0rem;
    text-decoration: none!important;
    border: 1px solid #486d90;
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 1rem;
    font-family: benton-sans, sans-serif;
    font-weight: 600;
    height: 3.125rem;
    width: 100%;
    position: relative;
    background-color: #fff;
    white-space: nowrap;
}

.placeOrderBtn:hover {
    background-color: #3c5a77;
}
.transferArrowIcon {
    width: 25px;
    margin-left: 15px;
}
.displayinnerBox{
    padding-top:20px;
}
.displayinnerBox p.Edit:hover{text-decoration: underline; color: #004a98;}
.dataTable thead tr th{
    background: url(../../../assets/sort.jpg) no-repeat right center;
    background-size:25px;
    min-width: 130px;
}
.dataTable thead tr th:last-child{
    background: none !important;
}

.dataTable tbody + thead{
    display: none;
  }
.dataTable tbody tr td{
    line-height: 2;
    word-break: break-all;
}
.dataTable tbody tr td a{
   display: inherit !important;
}
.bs-select select{
    margin-left: 0px !important;
}
.searchbar .form-group {
    margin-bottom: 0px !important;
}
.searchbar .searchinputbox{
    width: 24.3% !important;
    max-width: 24.3% !important;
    flex: 0 0 24.3% !important;

}
.bs-select select:focus,.bs-select .form-control:focus{
    background: url(../../../assets/sort.jpg) no-repeat right center;
    background-size:25px; 
}
.mdb-datatable-filter{
    margin-top:14px;
}
.dataTables_paginate .pagination{
    float: right;
}
div.mdb-datatable div.mdb-datatable-length select{
    margin-left: 10px !important;
    width:70px !important;
}
div.mdb-datatable div.mdb-datatable-entries{
    padding-left: 0px !important;
}
div.mdb-datatable div.mdb-dataTables_paginate,table.mdb-dataTable thead > tr > th.sorting,table.mdb-dataTable thead .sorting:before,table thead.mdb-dataTable-head .sorting:after,table.mdb-dataTable thead .sorting_asc:after, table.mdb-dataTable thead .sorting_desc:after,table thead.mdb-dataTable-head > tr > th.sorting_asc:before,table thead.mdb-dataTable-head > tr > th.sorting_asc:after{
    font-family: 'FontAwesome', sans-serif;
    
}
table.mdb-dataTable thead .sorting:before,table thead.mdb-dataTable-head .sorting:after,table thead.mdb-dataTable-head > tr > th.sorting_asc:before,table thead.mdb-dataTable-head .sorting:after, table.mdb-dataTable thead .sorting_desc:after, table.mdb-dataTable thead .sorting_desc:before{
    font-size: .7rem;
    top: 8px;
}
.page-link i{
    font-style: normal !important;
}
/* Desktop Media Queries for Alignment Break  */
.formtabbox{
    max-width:max-content !important;
    width:auto !important;
    flex: inherit !important;
}
.divformRadioCategory h4{
    font-family: yorkten-slab-normal,sans-serif;
    font-size: 16px;
    line-height: 20px;
}
#formRadioCategory .category-radio-btn {
    width: 20px;
}
.category-radio-btn {
    background: 0;
    border: 0;
    font-family: yorkten-slab-normal,sans-serif;
    padding: 25px 20px 25px 12px;
    text-transform: capitalize;
    font-size: 18px;
    font-weight: bold;
    position: relative;
    color: #212529;
    margin-right: 8px;
    margin-left: 8px;
}
.searchlabel{
    width:100% !important;
}
.internalsearchlabel{
    margin-top: -60px;
    width: 530px;
    padding-left: 50px;
}
#search {
    height: 48px;
    border-radius: 0;
    padding-left: 40px;
    border: 1px solid #d2d2d2;
}
 .dataTables_filter input {
    border-radius: 0;
    display: block;
    width: 100% !important;
    height: 48px;
    margin: 0 auto;
    margin-top: 8px;
    background: url(../../../assets/searchIcon.svg) no-repeat scroll 11px 11px;
    padding: 11px 11px 11px 45px;
    border: 1px solid #d2d2d2;
}
.dataTables_filter input:focus-visible{
    outline: none !important;
}
.subsectionbox p{
    padding-left: 65px;
}
.subsectionbox.newbankbox{
    padding-left: 40px;
}
.subsectionbox .checkBoxWrapper{
    margin-top: 7px;
}
.subfundaccountbanklistbox{
    padding-left: 32px;
}

.smallitalictext{
    font-style: italic;
    height: auto;
    padding-left: 50px;
}
.subfundaccountbox .checkBoxWrapper div{
    align-items: center !important;
}
.errorMessageStyle{
    margin-top: -20px !important;
    margin-left: 12px !important;
}

.subfundaccountbox .checkBoxWrapper{
    margin-top:15px !important;
}
.subfundaccountbox .checkBoxWrapper input[type='checkbox']:checked:after {
    left:7px !important;
    top:2px !important;
}
.BankInformation .checkBoxWrapper div{
    align-items: center !important;
}
.subsectionbox .checkBoxWrapper div{
    align-items: center !important;
}
.subsectionbox .checkBoxWrapper input[type='checkbox']:checked:after {
    left:7px !important;
    top:2px !important;
}
.BankInformation .checkBoxWrapper input[type='checkbox']:checked:after{
    left:7px !important;
    top:2px !important;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .graph-main .chartMain {
        flex-basis: 70%;
    }

    .graph-main .chartDetails {
        flex-basis: 60%;
        min-height: 390px;
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
        scroll-behavior: smooth;
    }

    .portfolioChart {
        max-width: 100%;
        flex-basis: 100%;
    }

    .highChartCenter {
        left: 16.29%;
    }
}

/* Ipad Pro Media Queries Portrait */

@media only screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
    .graph-main .chartMain {
        flex-basis: 70%;
    }

    ul.header-navlink-main {
        margin: 0;
    }

    .graph-main .chartDetails {
        flex-basis: 60%;
        min-height: 300px;
        max-height: 300px;
        margin-left: 0;
        /* max-width: 45%; */
    }

    .WCardOverlay {
        padding: 20px !important;
    }

    .highChartCenter {
        left: 15.9%;
    }
}

/* Ipad Media Queries Landscape */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .graph-main .chartMain {
        flex-basis: 70%;
    }

    ul.header-navlink-main {
        margin: 0;
    }

    .graph-main .chartDetails {
        flex-basis: 60%;
        min-height: 300px;
        max-height: 300px;
        margin-left: 0;
        /* max-width: 45%; */
    }

    #lowerRow ul li a:visited,
    #lowerRow ul li a:focus {
        font-size: 14px !important;
    }
}

/* Ipad Media Queries Portrait */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .transContainer .media-trans-history-table {
        padding: 0 !important;
        margin-bottom: 2rem;
    }
    .graph-main .chartMain {
        flex-basis: 70%;
        /* // margin-left: -150px; */
    }

    .graph-main .chartDetails {
        flex-basis: 56%;
        min-height: 300px;
        max-height: 300px;
        margin-left: -72px;
        /* max-width: 45%; */
    }
}
