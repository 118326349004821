.rightArrow .leftCornerCard + img {
    position: absolute;
    right: 30px;
    bottom: 30px;
    height: 32px;
    width: 32px;
    display: none;
}

.rightArrow.card::after {
    content: '';
    position: absolute;
    right: 30px;
    bottom: 30px;
    height: 30px;
    width: 30px;
    background: url('../../assets/leftArrow_green.png') no-repeat bottom left;
    background-size: 30px;
    transform: rotate(180deg);
}
.rightArrow:hover.card::after {
    background: url('../../assets/leftArrow_blue.png') no-repeat bottom left;
}

/*.card-text { overflow-y: scroll; }*/

.card > button {
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    background: transparent;
    border: none;
    padding: 0;
    margin: -2px 0 0 -2px;
    -webkit-appearance: none;
    display: flex;
    flex-direction: column;
    text-align: inherit;
}
.btn-outline-light.focus,
.btn-outline-light:focus {
    outline: 2px solid #000000 !important;
}
.card {
    height: auto !important;
}
@media screen and (max-width: 767px) {
    .w-card {
        width: 100%;
    }
}
