.confirmationPage {
    padding-top: 40px;
}
.confirmationPage .pageHeading {
    margin-bottom: 20px;
}
.confirmationMsg {
    font: 500 16px/20px benton-sans, sans-serif;
    color: rgba(73, 73, 74, 1);
}
.confirmationMsg .strongSpan {
    font: 600 16px/20px benton-sans, sans-serif;
    margin: 0 3px;
}
.confirmFundAccInfo {
    margin-top: 24px;
}
.pageSubHeading {
    font: 800 22px/45px yorkten-slab-normal, sans-serif;
    color: rgba(72, 109, 144, 1);
    margin-bottom: 15px;
}
.headerRowCfmPg,
.sectionHeader {
    border-bottom: 1px solid rgba(210, 210, 210, 1);
    align-items: center;
    margin-bottom: 40px;
}
.sectionHeader {
    margin-bottom: 30px;
}
.confirmationPage .dividendHint {
    padding: 22px 30px;
    margin-bottom: 54px;
    border: 1px solid rgba(210, 210, 210, 1);
    position: relative;
    font: 600 16px/22px 'benton-sans', sans-serif;
    color: #49494a;
    opacity: 1;
}
.confirmationPage .confirmFundingType .dividendHint:not(.onlineConfirmationcard) {
    border: 2px solid rgba(140, 191, 38, 1);
    outline: 0;
    background-color: rgba(250, 250, 250, 1);
}
.confirmationPage .confirmFundingType .dividendHint.onlineConfirmationcard {
    background-color: rgba(255, 255, 255, 1);
    padding: 25px 30px 14px;
}
.confirmationPage .confirmFundingType .dividendHint.onlineConfirmationcard p {
    font: 600 16px/22px benton-sans, sans-serif;
}
.confirmationPage .dividendHint::before {
    content: '';
    width: 0px;
    height: 0px;
    border-bottom: 35px solid transparent;
    border-left: 35px solid rgba(139, 193, 5, 1);
    position: absolute;
    top: 0;
    left: 0;
}
.confirmationPage .dividendHint.header::before {
    border-left-color: rgba(72, 109, 144, 1);
}
.confirmationPage .itemWrapper {
    display: flex;
    flex-direction: column;
    padding: 0 15px 0 30px;
    border-left: 1px solid rgba(206, 206, 206, 1);
}
.confirmationPage .itemWrapper:first-child {
    border-left: none;
    max-width: 259px;
}
.confirmationPage .itemWrapper:nth-child(2) {
    max-width: 182px;
}
.confirmationPage .itemWrapper .labelItem {
    font: 600 14px/20px benton-sans, sans-serif;
    color: #fff;
}
.confirmationPage .itemWrapper .valueItem {
    font: 600 16px/25px benton-sans, sans-serif;
    color: #fff;
}
.confirmationPage .beneDetailsTable {
    margin-bottom: 60px;
}
.confirmationPage .dividendHint.fundingTypecard {
    padding: 41px 38px 50px 50px;
}
.confirmationPage .dividendHint.onlineTransferTypecard {
    padding: 41px 54px 0px 50px;
}

.confirmationPage .dividendHint.onlineConfirmationcard {
    max-width: 1013px;
    padding: 25px 93px 24px 95px;
    bottom: 0;
    margin: 19px 15px 26px 15px;
    display: none;
}

.onlineTransferLabel {
    font: 600 16px/22px benton-sans, sans-serif;
    letter-spacing: 0;
    color: rgba(73, 73, 74, 1);
    opacity: 1;
    margin-bottom: 30px;
}

.onlineTransferValue {
    font: 500 16px/24px benton-sans, sans-serif;
    letter-spacing: 0;
    color: rgba(73, 73, 74, 1);
    opacity: 1;
    margin-bottom: 30px;
}

.onlineTransferHintTextStyle {
    text-align: left;
    font: 600 16px/22px benton-sans, sans-serif;
    letter-spacing: 0;
    color: rgba(73, 73, 74, 1);
    opacity: 1;
    margin-bottom: 0px;
}
.confirmationPage .specimenCardWrapper {
    padding-left: 20px;
}
.confirmationPage .wireTransferCard .specimenCardWrapper {
    padding-left: 10px;
}
.confirmationPage .specimenCardWrapper.checkSpecimen {
    padding-left: 0;
    max-width: 670px;
}
.confirmationPage .sectionHeading {
    font: 800 22px/45px yorkten-slab-normal, sans-serif;
    margin-bottom: 15px;
    color: rgba(72, 109, 144, 1);
}
.confirmationPage .specimenCard .leftCornerCard {
    border-left-color: rgba(139, 193, 5, 1);
}
.confirmationPage .specimenCard {
    padding: 30px 30px 38px;
    margin-bottom: 0;
}

.confirmationPage .wireTransferCard {
    padding: 41px 35px 41px 40px;
}

.confirmationPage .specimenCard .card-body {
    padding: 0;
    padding-bottom: 21px;
}
.confirmationPage .specimenCard .card-title {
    border-bottom: 2px solid rgba(139, 193, 5, 1);
    display: inline-block;
}
.confirmationPage .specimenCard .card-img {
    margin-bottom: 0;
    max-width: 100%;
}

.new-line {
    white-space: pre-line;
}
.confirmationPage .confirmFundingType .dividendHint p {
    font: 500 14px/20px benton-sans, sans-serif;
    margin-bottom: 5px;
}
.confirmationPage .wireTransferCard p {
    margin-bottom: 25px;
}
.confirmationPage .confirmFundingType .dividendHint .boldPara {
    font-weight: 600;
}
.confirmationPage .fundTypeSpecimenWrapper {
    margin: 30px 0;
    align-items: flex-end;
}

@media print {
    .confirmationPage > .row:first-child,
    header,
    footer,
    .confirmPageBtnWrapper,
    .print {
        display: none !important;
    }
}
