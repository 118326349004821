.socialmedia  .container{
	padding:0;
}
.socialmedia__links{
    margin-top:30px;
}
.socialmedia__icons{
   display:inline-block;
   list-style-type:none;
   overflow:hidden;
   text-align:center;
   margin-bottom: 15px;
   margin-top:15px;
   padding-right:0;
}
.socialmedia__icons ul {
    text-align: left;
    margin-top: 13px;
	padding-right:0;
}
.socialmedia__icons p{
    text-align:left;
    margin:0px;
    padding-left:0;
    margin-top: 0;
    font-size:16px;
    line-height:23px;
    font-weight: 500;
    font-style: normal;
}
.socialmedia__icons li{
    padding-left:0;
    margin-right: 10px;
    list-style: none; 
    margin-right: 30px;
}
.socialmedia__icons ul li a i{
    color:#49494A;
    font-size: 36px;
}
.socialmedia__icons ul li a:hover i{
   color:#004A98;
   font-size: 36px;
}
@media screen and (min-width:768px){
.socialmedia__icons{
    margin-top:27px;
}
}
@media screen and (min-width:1025px){
.socialmedia__icons{
    margin-top:0px;
}
.socialmedia__links {
        margin-top: 50px;
    }
}