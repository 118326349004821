.openAccBreadCrumbWrapper #breadcrumb {
    position:relative;
    top:-40px;

    @media  screen and (max-width: 767px) {
        position:relative;
        top: 100px;
        padding-bottom:40px;
    }
}


.AccountTypeSection{
      cursor: "pointer";
      height: 20px;
      width: 20px;
      position: relative;
      left: 35%;
      top:-50px;   
} 
@media screen and (max-width: 540px){
   .AccountTypeSection{
      position: relative;
      left: 85%;
      top:-5px;   
} 
}  
      
@media only screen and (max-width: 912px) and (min-width: 750px){

     .AccountTypeSection{
      position: relative;
      left: 48%;
      top:-50px;   
} 

}

.accountTypePdf{
    cursor: pointer;
     color:rgba(0, 74, 152, 1);
     text-decoration: underline;
     margin-top: 30px;
}

