#account-registration{
  font-family: benton-sans, serif;
  -webkit-font-smoothing: auto;
}
/* .container-fluid{  
  max-width: 1366px;
  padding:0px;
} */

#account-registration .pad {
  padding: 0px 10% 0px 10%;
}

#account-registration .padLess {
  padding: 0px 5% 0px 5% !important;
}
/*Added for ADA*/
#account-registration .sr-only{
  display: inline !important;
}

#account-registration .sr-only-focusable:active,
#account-registration .sr-only-focusable:focus {
  position: absolute;
  left: 20px;
}
#skipContentLink:focus, #skipContentLink:active {
  left: 20px;
  width: auto;
  height: auto;
  overflow:auto;
  z-index:999;
  outline: 2px solid #ffffff;
}

#skipContentLink {
  position: absolute;
  left: -999px;
  top: auto;
  overflow: hidden;
  width: 1px;
  height: 1px;
  text-decoration: underline;
  z-index:-999;
  color: #ffffff;
  outline: 2px solid #ffffff;
}

#account-registration .d-flex.justify-content-between {
  align-items: center;
}

#account-registration :focus,
#account-registration button:focus,
#account-registration .btn:focus,
#account-registration a:focus > .headerLogo {
    /* outline: 2px solid #004A98; */
    outline: 1px dotted;
    outline: 2px dotted #000000;
    outline-offset: 2px;
}

#account-registration #main-header :focus,
#account-registration #main-header button:focus,
#account-registration #main-header .btn:focus,
#account-registration #main-header a:focus > .headerLogo,
#account-registration #main-footer :focus,
#account-registration #main-footer button:focus,
#account-registration #main-footer .btn:focus,
#account-registration #main-footer a:focus > .headerLogo {
  /* outline: 2px solid #004A98; */
  outline: 1px dotted;
  outline: 2px dotted #FFF;
  outline-offset: 2px;
}


#account-registration span > .inlineLInkNew,
#account-registration .anchorSpan .inlineLInk  {
  display: block;
}

#account-registration .anchorSpan {
  display: inline-block;
}

#account-registration abbr[title] {
    text-decoration: none;
}

/* #main-disclosure abbr[title] {
  margin: 0 3px;
} */

#account-registration .hidden-accessible {
  border: 0;
  clip: rect(0,0,0,0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
#account-registration .icon-newpage {
  display: inline-block;
  padding: 0 !important;
}
#account-registration .icon-newpage:before {
  content: url('../../assets/icon-external-link.svg');
  line-height: 1;
  position: relative;
  right: -1px;
  top: -5px;
}
#account-registration .icon-newpage.small-size{
  width: 15px;
  height: 15px;
}
#account-registration .icon-newpage.large-size{
  width: 20px;
  height: 20px;
}
#account-registration .icon-newpage-footer {
  display: inline-block;
  padding: 0 !important;
}
#account-registration .icon-newpage-footer:before {
  content: url('../../assets/icon-external-link-footer.svg');
  line-height: 1;
  position: relative;
  right: -1px;
  top: -7px;
}

#account-registration .icon-newpage-footer.small-size{
  width: 15px;
  height: 15px;
}
#account-registration #main-disclosure{
  margin-bottom: 8em;
  left: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  font: 500 13px/23px benton-sans;
}

#account-registration .welcomePageDisclosure {
  display: none;
  padding: 0;
}
#account-registration .welcomePage + #main-disclosure .welcomePageDisclosure {
  display: block;
}
#account-registration .defaultFooter1 {
  display: none;
}
#account-registration .milestone + #main-disclosure .defaultFooter1 {
  display: block;
}
#account-registration .defaultFooter2 {
  display: none;
}
#account-registration .success-title + #main-disclosure .defaultFooter2 {
  display: block;
}
#account-registration #main-header {
  background-color: #486d90;
  height: 6.25em;
  position: relative;
}
#account-registration #main-header .container-fluid {
  padding: 13px 0;
}
#account-registration #main-header p,
#account-registration #main-header a {
  color: #ffffff;
  margin: 0px;
}
#account-registration #main-header a.logoLink {
  display: inline-block;
}
#account-registration #main-content {
  font-family: benton-sans, sans-serif;
  color: rgba(73, 73, 74, 1);
  height: 100%;
  position: relative;
  /* padding-bottom: 200px; */
  min-height: calc(100vh - 100px);
}

#account-registration #main-footer{
  background-color: #486D90;
  height: 3.07em;            /* Footer height */
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  font: 500 13px/23px benton-sans;
  z-index: 1;
  display: flex;
  align-items: center;
  color: #fff;
}

#account-registration #main-footer .footerContentBlock{
  display: block;
  float: left;
}
.axLinkBlockLeft{
  display: block;
  float: left;
}

#account-registration .copyright {
  color: white;
  font-size: 13px;
  margin-top: 17px;
}

#account-registration .copyright p {
  margin: 0px;
}
#account-registration .copyright span {
  padding-left: 10px;
  display: inline-block;
}
#account-registration .privacyNotice span{
  display: inline;
}
@media (min-width:768px) {
  #account-registration #main-disclosure{
    position: absolute;
  }
}
@media (max-width:767px) {
  #account-registration #main-content{
    padding-bottom: 50px; /* vq-733 padBot0*/
  }
  #account-registration #main-disclosure{
    /* margin-top: -180px; */
    padding: 0 15px;
  }

  #account-registration #main-disclosure.extraPadding{
    margin-top: -40px;
  }

  #account-registration .copyright span {
    padding-left: 3px;
  }
  #account-registration .copyright {
    font-size: 12px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  #account-registration .welcomePage + #main-content{
    padding-bottom: 0;
  }
  #account-registration .welcomePage + #main-disclosure{
    padding: 0 15px 35px; /* vq-733  padbot0*/
  }
}
@media only screen and (min-width: 1775px) {
  #account-registration #main-content{
    /* padding-bottom: 400px; */
    padding-bottom: 25em;
  }
}
@media only screen and (max-width: 1774px) and (min-width: 1601px) {
  #account-registration #main-content{
      /* padding-bottom: 425px; */
      padding-bottom: 26.5625em;
    }
}
@media only screen and  (max-width: 1600px) and (min-width: 1511px) {
  #account-registration #main-content {
    /* padding-bottom: 445px; */
    padding-bottom: 27.8125em; 
  }
}
@media  only screen and  (max-width: 1510px) and (min-width: 1474px) {
  #account-registration #main-content {
    /* padding-bottom: 465px; */
    padding-bottom: 29.0625em; 
  }
}
@media  only screen and  (max-width: 1473px) and (min-width: 1429px) {
  #account-registration #main-content {
    /* padding-bottom: 490px; */
    padding-bottom: 30.625em; 
  }
}
@media  only screen and  (max-width: 1428px) and (min-width: 1410px) {
  #account-registration #main-content {
    /* padding-bottom: 508px; */
    padding-bottom: 31.75em; 
  }
}
@media only screen and  (max-width: 1409px) and (min-width: 1286px) {
  #main-content {
    /* padding-bottom: 510px; */
    padding-bottom: 31.875em; 
  }
}
@media only screen and  (max-width: 1285px) and (min-width: 1274px) {
  #account-registration #main-content {
    /* padding-bottom: 526px; */
    padding-bottom: 32.875em; 
  }
}
@media only screen and  (max-width: 1273px) and (min-width: 1154px) {
  #account-registration #main-content {
    /* padding-bottom: 533px;  */
    padding-bottom: 33.3125em; 
  }
}
@media only screen and  (max-width: 1153px) and (min-width: 1149px) {
  #account-registration #main-content {
    /* padding-bottom: 556px; */
    padding-bottom: 34.75em; 
  }
}
@media only screen and  (max-width: 1148px) and (min-width: 1116px) {
  #account-registration #main-content {
    /* padding-bottom: 590px; */
    padding-bottom: 36.875em; 
  }
}
@media only screen and  (max-width: 1115px) and (min-width: 1112px) {
  #account-registration #main-content {
    /* padding-bottom: 615px; */
    padding-bottom: 38.4375em; 
  }
}
@media only screen and  (max-width: 1111px) and (min-width: 1004px) {
  #account-registration #main-content {
    /* padding-bottom: 618px; */
    padding-bottom: 38.625em; 
  }
}
@media only screen and  (max-width: 1003px) and (min-width: 992px) {
  #account-registration #main-content {
    /* padding-bottom: 640px; */
    padding-bottom: 40em; 
  }
}
@media only screen and  (max-width: 991px) and (min-width: 934px) {
  #account-registration #main-content {
    /* padding-bottom: 430px;  */
    padding-bottom: 26.875em; 
  }
}
@media only screen and  (max-width: 933px) and (min-width: 860px) {
  #account-registration #main-content {
    /* padding-bottom: 460px; */
    padding-bottom: 28.75em;
  }
}
@media only screen and  (max-width: 859px) and (min-width: 768px) {
  #account-registration #main-content {
    /* padding-bottom: 510px;  */
    padding-bottom: 31.875em;
  }
}

#account-registration h3 {
  color: #486d90;
  padding-bottom: 10px;
  display: inline-block;
  font-family: yorkten-slab-normal, sans-serif;
  font-weight: 800;
  font-style: normal;
  border-bottom: 2px solid #8bc105;
}

#account-registration .ssn {
  height: 1.5625em;
  letter-spacing: 0;
  color: #49494A;
  opacity: 1;
  font-size: 1.2rem;
  font-weight: bolder;
}

#account-registration .passcode{
  color: #486d90;
  padding-bottom: 10px;
  display: inline-block;
  font-family: yorkten-slab-normal, sans-serif;
  font-weight: 800;
  font-style: normal;
  border-bottom: 2px solid #8bc105;
  font-size: 1.5rem;
  
}

#account-registration .passcode-label {
text-align: left;
letter-spacing: 0;
color: #49494A;
font-weight: 800;
}

#account-registration .needhelp {
  float: right;
  margin-top: 26px;
  display: flex;
  align-items: flex-end;
}

#account-registration .help {
  color: #fff;
  font-family: yorkten-slab-normal, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  flex-direction: column;
  padding-right: 15px;
}
#account-registration .help a {
  font: 600 15px/23px benton-sans;
  text-decoration: underline;
}
#account-registration .help a:hover {
  text-decoration: none;
}
#account-registration .help span {
  vertical-align: text-bottom;
}
#account-registration h1 {
  color: #fff;
  font-family: yorkten-slab-normal, sans-serif;
  font-weight: 800;
  font-style: normal;
}
#account-registration h1 small {
  font-size: large; 
}
#account-registration h1 p {
  font-size: large; 
  line-height: 2;
}

#account-registration .noPad {
  padding: 0px !important;
}

#account-registration #welcome h4 {
  font-size: small;
  font-weight: 800;
  margin-top: 10px;
}

#account-registration #welcome li,
#account-registration #welcome p {
  font-size: small;
}

#account-registration ol {
  counter-reset: section;
  list-style: none;
  padding: 0px;
  font-size: 15px;
}

#account-registration ol li {
  margin: 0 0 8px 0;
  line-height: 32px;
  display: flex;
}

#account-registration ol li:before {
  content: counter(section);
  counter-increment: section;
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 0 14px 0 0;
  border: 1px solid #54565A;
  border-radius: 100%;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  font-family: yorkten-slab-normal;
  color: #54565A;
  display: flex;
  align-items: center;
  justify-content: center;
}

#account-registration #banner {
  background-image: url('../../assets/Member-background.png');
  background-position: left top, right bottom;
  min-height: 11.500em;
  background-color: #405e78;
  background-repeat: no-repeat;
}


#account-registration .vcmbtn {
  background-color: rgba(72, 109, 144, 1);
  border: none;
  color: #49494A;
  font:700 16px/20px yorkten-slab-normal, sans-serif;
  /* height: 50px;
  width: 293px; */
  height: 3.125em;
  width: 18.3125em;
  position: relative;
}
#account-registration .vcmbtn::after {
  content: '';
  width: 0px;
  height: 0px;
  border-bottom: 19px solid transparent;
  border-right: 19px solid rgba(139, 193, 5, 1);
  position: absolute;
  top: 0;
  right: 0;
}
#account-registration .vcmbtn:disabled::after{
  display: none;
}
#account-registration .vcmbtn:disabled {
  background-color: #D2D2D2;
}
#account-registration .vcmbtn:hover:not(:disabled) {
  background-color: rgba(0, 74, 152, 1);
}
#account-registration .vcmbtn .vcmBtnText {
  color: #fff;
}
#account-registration .vcmbtn:disabled .vcmBtnText {
  color: #49494A;
}


#account-registration ul.basicterms {
  list-style-type: disc;
  padding-left: 20px;
}

#account-registration ul.basicterms li {
  margin-bottom: 20px;
}

#account-registration .vcmBoxContainer {
  border: 1px solid #CFD1D2;
  padding: 30px 72px 30px 26px;
  display: block;
  min-height: 90px;
  margin-bottom: 40px;
  /*margin-top: 30px;*/
}
#account-registration .vcmBoxContainer .madatoryText {
  font: 500 1em benton-sans-medium;
  display: block;
  margin-bottom: 2em;
}

#account-registration .vcmBox {
  display: flex;
  align-items: flex-start;
  /*min-height: 123px;*/
  margin-bottom: 2em;
}
#account-registration .vcmBox:last-child {
  margin-bottom: 0;
}

#account-registration .vcmBoxText{
  font: 1em/1.6em benton-sans;
  color: #1A1A1A;
  margin-left: 18px;
  margin-bottom: 0;
  display: block;
  float: left;
}

#account-registration .vcmBox .checkboxcontainer {
  height: 30px;
  padding-left: 30px;
  flex-shrink: 0;
  top: 3px;
  display: block;
  float: left;
}
#account-registration .vcmBox .checkboxcontainer .checkmark {
  top: 0;
}
#account-registration .vcmBox .checkboxcontainer input:focus + span {
  outline: 2px dotted #000000;
  outline-offset: 2px
}
#account-registration .vcmBox .checkboxcontainer span:focus,
#account-registration .vcmBox .checkboxcontainer:focus-within{
  /* outline: 2px solid #004A98; */
  outline: 2px solid -webkit-focus-ring-color;
  outline: 2px dotted #000000;
  outline-offset: 2px;
}

#account-registration input.largerCheckbox { 
  transform : scale(2); 
}

#account-registration .mobileView {
  display:none
}
#account-registration h4 {
    font-size: 16px !important;
    font-weight: 700 !important;
}

#account-registration .para {
  margin-bottom: 41px;
}

#account-registration .success-title {
  text-align: center;
  margin: 110px 139px 160px;
}

#account-registration .success-p {
  padding: 0 20%;
}
#account-registration .mobile-img {
  max-width: 200px;
  height: auto;
  min-width: 70px;
}
#account-registration .mobile-call {
  padding-right: 10px;
  background: url('../../assets/icon-phone.png') no-repeat left top;
  display: block;
  width: 37px;
  height: 37px;
}
#account-registration .mobile-clock {
  background: url('../../assets/icon-clock.png') no-repeat right bottom;
  display: block;
  width: 32px;
  height: 32px;
}
#account-registration button.tooltip-button {
  background: none;
  border: none;
  padding: 0;
}
#account-registration .clockButtonDivWrapper {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
#account-registration button.clockButton {
  margin-left: 16px;
}
#account-registration #clockTooltip .closeButtonDiv {
    position: absolute;
    width: 30px;
    height: 50px;
    top: 16px;
    right: 16px;
    text-align: center;
}
#account-registration #clockTooltip {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
  border: none;
  border-radius: 0;
  max-width: 395px;
  padding: 14px 33px 22px;
  min-width: 325px;
}


#account-registration .success-title .pageHeading {
  text-align: center;
}
@media screen and (max-width: 767px){
  #account-registration #main-header {
    height: auto;
  }
  #account-registration .help,
  #account-registration .help a {
    font-size: 12px;
    line-height: 16px;
    padding-right: 5px;
  }
  #account-registration .desktopView {
    display:none
  }
  #account-registration .mobileView { 
    display: block
  }

  #account-registration .mobile-stepper svg{
    transform: scale(0.5)
  }

  #account-registration .mobile-stepper p {
    display:none
  }
  #account-registration .mobile-img {
    width: 120px;
  }
  #account-registration .mobile-call {
    display: none
  }
  #account-registration .needhelp {
    margin-top: 10px;
  }
  #account-registration .mobile-clock {
    padding-left: 10px;
  }
  #account-registration .mobile-clock svg{
    width:20px;
    margin-right: 10px;
  }
  #account-registration .vcmbtn {
    width: 100%;
    margin-left: 0;
  }
  #account-registration .pad {
    padding: 0px 4% 0px 7%;
  }
  #account-registration .mobile-btn {
    margin-left: 30px;
  }

  #account-registration .success-title {
    text-align: center;
    margin: 108px 25px 160px;
  }

  #account-registration .success-title .pageHeading {
    margin: 0 18px 30px;
    border: none;
  }

  #account-registration .success-p {
    padding: 0;
    text-align: left;
  }

  #account-registration .mobile-bt {
    margin-right: 20%;
  }
  
  #account-registration .vcmBox {
    padding-bottom: 20px;
  }
  #account-registration .vcmBox .checkboxcontainer {
    margin-top: 5px;
  }
  #account-registration #clockTooltip {
    width: calc(100% - 30px);
    padding: 14px 25px 22px;
  }

}
@media only screen and (max-width:767px) {
  #account-registration #banner {
    height: 184px;
    background-image: url('../../assets/flag-mob.png'),url('../../assets/Member-background.png');
    background-position: right bottom, left top;
  }
  #account-registration .vcmBoxContainer {
    padding: 10px;
  }
}

#account-registration input[type=text],
#account-registration input[type=number],
#account-registration input[type=date],
#account-registration input[type=password] {
  border: 1px solid rgba(210, 210, 210, 1);
  height: 3em;
  text-align: left;
  padding: 10px 20px;
}
#account-registration input[type=text]:focus,
#account-registration input[type=number]:focus,
#account-registration input[type=date]:focus,
#account-registration input[type=password]:focus {
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
}

#account-registration input[type=text].errorField,
#account-registration input[type=number].errorField,
#account-registration input[type=date].errorField,
#account-registration input[type=password].errorField,
#account-registration #vcm_name.errorField {
  border: solid 2px rgba(230, 0, 0, 1);
}


#account-registration .createPassword input[type=password],
#account-registration .createPassword input[type=text] {
  padding: 10px 30px 10px 20px;
}

#account-registration input::-ms-clear,
#account-registration input::-ms-reveal
{
    display: none;
}

#account-registration .rules {
  padding: 0px;
  color: #49494A;
  font-size: smaller;
  margin: 0px;
}

#account-registration .rules li {
  padding-bottom: 5px;
}

#account-registration .valid {
  list-style-image: url('../../assets/rule.png')
}

#account-registration .invalid {
  list-style-image: url('../../assets/rulenone.png')
}
#account-registration button.inlineLInkNew {
  color: rgba(0, 74, 152, 1);
  padding: 0px;
  word-break: break-all;
  text-decoration: underline;
  cursor: pointer;
  border: none;
  background: none;
  display: block;
  float: left;
}
#account-registration a.inlineLInkNew {
  font-size: 1.125em;
  text-decoration: underline;
  color: rgba(0, 74, 152, 1);
  font-weight: 600;
  font-family: yorkten-slab-normal;
  display: inline-block;
}
#account-registration .success-title a.inlineLInk{
  font-size: 28px;
  margin-top: 20px;

}
#account-registration a.inlineLInk {
  color: #004A98;
  margin: 0 3px;
  word-break: break-word;
  text-decoration: underline;
}
#account-registration a.inlineLInk:hover{
  text-decoration: none;
}
#account-registration a.inlineLInk.noMarginR {
  margin-right: 0;
}
#account-registration .vcmBackButton span {
  color: #004A98;
  font: 700 16px/18px yorkten-slab-normal, sans-serif;
  letter-spacing: 0;
}
#account-registration .backLink .vcmBackButton.btn {
  background: url('../../assets/leftArrow.svg') no-repeat 0px 1px;
  background-size: 30px;
  padding: 0;
  padding-left: 35px;
  background-color: transparent;
  border: none;
  height: 50px;
  padding-bottom: 16px;
  color: #49494A;
}

#account-registration .backLink.passwordBckBtn .vcmBackButton{
  background-size: 28px;
  height: 30px;
  padding-bottom: 0;
}

#account-registration .backLink.d-sm-block {
  display: inline-block !important;
  color: #49494A;
}

#account-registration .backLink.passwordBckBtn{
  width: 40%;
  max-width: 12.6875em;
}
#external-link{
  display: none;
}

@media only screen and (max-width:1024px) {
  #account-registration .backLink.passwordBckBtn{
    width: 60%;
    margin-top: 2em;
  }
}

#account-registration .backLink .btn-outline-light.vcmBackButton:hover,
#account-registration .backLink .btn-outline-light.vcmBackButton:active,
#account-registration .backLink .btn-outline-light.vcmBackButton:focus
#account-registration .backLink .btn-outline-light:not(:disabled):not(.disabled):active{
  background-color: transparent;
  border: none;
  box-shadow: none;
}

#account-registration .titleText {
  font: 600 18px/23px benton-sans,sans-serif;
  letter-spacing: 0;
  color: #56565a;
   margin-bottom: 1em;
}

#account-registration .ulList{
  list-style-type: disc;
  margin-bottom: 1rem;
  padding-inline-start: 40px;
  line-height: 24px;
}

#account-registration #clockTooltip.show:not(:focus-within) {
  background-color: rgb(255, 255, 254);
  transition: background-color 0.01s;
}

/* forIE browsers the specific css  */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #account-registration #clockTooltip.show:not(:focus) {
    background-color: rgb(255, 255, 254);
    transition: background-color 0.01s;
  }
  #account-registration .vcmBox .checkboxcontainer input:focus + span {
    outline: 1px dotted #000000;
    outline-offset: 2px
  }

}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)  and (min-width: 992px) {
  #account-registration .preRegButton {
    padding: 16px 2px;
  }
}

/* For IE edge the specific css */
@supports (-ms-ime-align: auto) {
  #account-registration #clockTooltip.show button:not(:focus) {
    background-color: rgb(255, 255, 254);
    transition: background-color 0.01s;
  }
}

#account-registration .investmentInvokCont{
  margin-top: 0;
  /* margin-bottom: 1rem; */
}

#account-registration ol.orderList{
  margin-top: 13px;
}

#account-registration ol.orderList li{
  font-size: 13px;
  margin-bottom: 0;
  line-height: 25px;
}

#account-registration ol.orderList:before{
  margin-top: 10px;
}
#account-registration ol.orderList li:before {
  content: "";
  width: auto;
  height: 27px;
  margin: 0;
  border: 0;
}

#account-registration .orderListCnt{
  margin: 0 10px 0 0;
  font-size: 12px;
  font-weight: 700;
  font-family: yorkten-slab-normal;
}

#account-registration ol.orderList p{
  margin-bottom: 0;
}


#account-registration ol.orderList p a{
  margin: 0;
}

#account-registration .contentWrapper.pad {
  padding-bottom: 70px;
  padding-top: 4em;
  color: rgba(73, 73, 74, 1);
}


#account-registration .descWrapper h3,
#account-registration .descWrapperHeading {
  font: 800 26px/44px yorkten-slab-normal, sans-serif;
  color: #486D90;
  margin-bottom: 20px;
  border-bottom: 2px solid #8bc105;
  display: inline-block;
}
#account-registration .descWrapper h3.termsSpanHeading {
  font: 700 18px/22px benton-sans;
  display: block;
  margin-bottom: 16px;
  margin-top: 10.5px;
  color: rgba(73, 73, 74, 1);
  border-bottom: none;
}
#account-registration .descWrapper p.termsPara {
  font: 500 16px/26px benton-sans;
  width: 100%;
  margin-bottom: 32px;
}
#account-registration .descWrapper p.termsParaLower {
  font: 500 16px/26px benton-sans;
  width: 100%;
  margin-bottom: 40px;
}
#account-registration .formWrapper h4 {
  margin-bottom: 26px;
  font: 700 18px/22px benton-sans;
}
#account-registration .formWrapper input[type=number] {
  text-align: center;
}
#account-registration .dashStyle {
  font-size: 30px;
  margin: auto 10px;
}
#account-registration .lastDigitsSpan {
  height: 2.875em;
  padding: 3px;
  font-size: 18px;
}
#account-registration .SSNContainer,
#account-registration .SSNContainer span {
  display: flex;
  align-items: center;
}
#account-registration .errorText {
  margin: 0;
  color: #e60000;
  font: 700 12px/22px benton-sans;
  line-height: 20px;
}

#account-registration .userInfo .verifyDeatilsRow .col{
  font: 500 14px/24px benton-sans;
  max-width: 50%;
  word-break: break-word;
}

#account-registration .verifyDetailsLabel {
  text-align: right;
  padding: 4px;
  color: #49494A;
}

#account-registration .eleCardWrapComponent{
  border: 1px solid #d2d2d2;
  padding: 25px;
  position: relative;
}

#account-registration .eleCardWrapComponent::after{
  content: '';
  width: 0px;
  height: 0px;
  border-bottom: 30px solid transparent;
  border-left: 30px solid #d2d2d2;
  position: absolute;
  top: 0;
  left: 0;
}

#account-registration .eleCardWrapComponent p.fieldTitle{
  font: 1.125em yorkten-slab-extra-bold;
  border-bottom: 1px solid #d2d2d2;
  padding-bottom: 0.5em;
  margin-bottom: 1.2em;
  color:#486d90;
}

#account-registration .eleCardWrapComponent .phoneTypeRowWrapper{
  border-top: 1px solid #d2d2d2;
  padding-top: 1em;
}

#account-registration .eleCardWrapComponent .phoneFormatTxt{
  font:0.75em benton-sans;
  margin: 0;
}

#account-registration .eleCardWrapComponent .inputLabel{
  font-size: 1em;
}

#account-registration .eleCardWrapComponent.termsCard {
  font: 0.875em benton-sans-medium;
  margin-bottom: 3em;
}

#account-registration .eleCardWrapComponent.termsCard::after {
  border-left: 30px solid #8bc105;
}

#account-registration .eleCardWrapComponent.termsCard a{
  text-decoration: underline;
}

#account-registration .RHSWrapper input[type=number],
#account-registration .RHSWrapper input[type=text],
#account-registration .RHSWrapper .vcmbtn.sameWidthButton, 
#account-registration .RHSWrapper input[type=password],
#account-registration .createPassword{
  max-width: 18em;
  width: 100%;
}
#account-registration .RHSWrapper span.inputLabel {
  display: inline-block;
}
#account-registration .RHSWrapper h4,
#account-registration .inputLabel ,
#account-registration .RHSWrapper h2 {
  font: 0.875em/1.6em benton-sans-medium;
}
#account-registration .RHSWrapper h4.otp,
#account-registration .RHSWrapper .inputLabel.otp {
  margin-bottom: 23px;
}
#account-registration .RHSWrapper h4.phone,
#account-registration .RHSWrapper .phone.inputLabel {
  margin-bottom: 31px;
}

#account-registration .RHSWrapper .phone.inputLabel  {
  display: block;
}
#account-registration .phoneRadioWrapper.rowWrapper {
  display: flex;
  flex-direction: column;
}
#account-registration .verifyDetailsContainer hr{
  border: none;
}
#account-registration .verifyDetailsContainer hr::after{
  content: '';
  height: 1px;
  width: 100%;
  background: linear-gradient(to left, rgba(139, 137, 137, 0),rgba(194, 194, 194, 1), rgba(128, 128, 128, 0));
  display: block;
}

#account-registration .verifyDetailsLabel {
  text-align: right;
  padding: 4px;
  color: #49494A;
}
#account-registration .rowWrapper {
  margin-bottom: 20.5px;
}
#account-registration .labelHelp {
 font: 500 18px/22px benton-sans;
 padding-left: 4px;
}
#account-registration .passCodeWrapper {
  display: flex;
}
#account-registration .passCodeWrapper .resendOtp {
  margin-left: 1.5px;
  padding: 0;
  color: rgb(0, 74, 152);
  -webkit-appearance: none;
  font: 600 16px/22px benton-sans;
  background: transparent;
  border: none;
}
#account-registration .inputWrapper {
  margin-bottom: 30px;
}
#account-registration .inputWrapper.passCode {
  margin-bottom: 33px;
}
#account-registration .passCodeSent {
  margin-left: 15px;
}
#account-registration .passCodeSent p:first-child{
  font: 500 16px/20px benton-sans;
  color: #49494A;
  margin-bottom: 7px;
}
#account-registration .passCodeSent .resendOtp{
  margin: 0;
}
#account-registration .btnWrapper {
  display: flex;
  justify-content: space-between;
}
#account-registration .flexColBackOpt {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#account-registration .rulesWrapper{
  color: #49494A;
}
#account-registration .rulesWrapper .rules {
  font: 500 15px/18px benton-sans;
}
#account-registration .rulesHeading {
  font: 700 18px/22px benton-sans;
  margin-bottom: 17px;
  display: block;
}
#account-registration .ruleListIcon {
  margin-bottom: 10px;
}
#account-registration .ruleListText {
  margin-left: 10px;
}
#account-registration .circle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid black;
  position: relative;
  display: inline-block;
}
#account-registration .filledCircle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #004A98;
  position: relative;
  display: inline-block;
}
#account-registration .tick {
  position: absolute;
  top: 5px;
  left: 4px;
  width: 10px;
  height: 5px;
  transform: rotate(-45deg);
  border-left: 2px solid white;
  border-bottom: 2px solid white;
}
#account-registration .displayFlex {
  display: flex;
}
#account-registration .termsSpanHeading {
  font: 700 18px/22px benton-sans;
  display: block;
  margin-bottom: 16px;
  margin-top: 10.5px;
}
#account-registration .phoneSupportOption {
  margin: 36px 0;
  font: 500 16px/22px benton-sans;
}
#account-registration .phoneSupportOption .telephone,
#account-registration .phoneSupportOption .telephone:hover{
  font:600 16px/22px benton-sans;
  margin: 0 3px;
  text-decoration: underline;
}
#account-registration .createPassword button{
  background: transparent;
  border: none;
  -webkit-appearance: none;
}
#account-registration .closeButtonDiv {
  position: relative;
  float: right;
  margin-left: 50px;
}
#account-registration .closeCross {
  width: 30px;
  height: 30px;
}
#account-registration .closeCross::before {
  position: absolute;
  content: "";
  width: 30px;
  border: 1.5px solid #8BC105;
  transform: rotate(45deg);
  top: 20px;
  right: 0px;
}
#account-registration .closeCross::after {
  position: absolute;
  content: "";
  width: 30px;
  border: 1.5px solid #8BC105;
  transform: rotate(-45deg);
  top: 20px;
  right: 0px;
}
#account-registration #telephoneTooltip {
  box-shadow: 0px 0px 16px #00000029;
  border: none;
  border-radius: 0;
  max-width: 600px;
  padding: 14px 33px 22px 63px;
}
#account-registration #telephoneTooltip .arrow {
  display: none;
}

#account-registration .helpNumberTooltip {
  display: inline-block;
  margin-left: -35px;
}
#account-registration .helpNumberTooltip a {
  padding-left: 50px;
}
#account-registration .vcmBoxText button.inlineLInkNew ,
#account-registration .vcmBoxText a.inlineLInkNew {
  font: 600 1.125em benton-sans-medium;
  margin: 0.4em 0 0.6em;
}
#account-registration .vcmBoxText svg {
  margin-left: 10px;
}
@media only screen and  (min-width:768px) {
  #account-registration .flexColBackOpt {
      min-height: 250px;
  }
  #account-registration .noPad + .passCodeWrapper  {
      padding: 45px 0 0;
  }
  #account-registration .termsAndConditionsContent {
      padding-bottom: 50px;
  }
  #account-registration .contentWrapper .container-fluid {
      padding: 0;
  }

}
@media only screen and  (max-width:767px) {
  #account-registration a.inlineLInkNew{
    display: inline;
  }
  #account-registration .descWrapper {
      margin-bottom: 20px;
  }
  #account-registration .descWrapper p {
      width: 100%;
  }
  #account-registration .descWrapper h3 {
      margin-bottom: 25px;
  }
  #account-registration .headingRow .descWrapper {
      margin-bottom: 0;
  }
  #account-registration .contentWrapper.pad {
      padding: 10px 0 60px;
  }
  #account-registration .RHSWrapper input[type=number],
  #account-registration .RHSWrapper input[type=text],
  #account-registration .RHSWrapper .vcmbtn.sameWidthButton,
  #account-registration .RHSWrapper input[type=password],
  #account-registration .createPassword{
      max-width: 100%;
  }

  #account-registration .createPassword {
      width: 100%;
  }
  #account-registration .passCodeWrapper {
      flex-direction: column;
  }
  #account-registration .passCodeWrapper .resendOtp {
      margin-left: 0;
      text-align: left;
  }
  #account-registration .passCodeWrapper #passcode {
      margin-bottom: 23px;
  }
  #account-registration .passCodeSent {
      margin-left: 0;
  }
  #account-registration .btnWrapper {
      flex-direction: column;
  }
  #account-registration .btnWrapper .backLink {
      order: 2;
      margin-top: 20px;
  }
  #account-registration .headingRow .pageHeading {
      border-bottom: none;
      margin: 0 0 25px;
      padding: 0;
      font: 800 30px/36px yorkten-slab-normal
  }
  #account-registration .vcmBoxContainer .vcmBoxText {
      margin-left: 10px;
  }
  #account-registration .vcmBoxText svg {
      margin-left: 3px;
  }
  #account-registration .helpNumberTooltip{
      margin-left: -20px;
  }

  #account-registration .helpNumberTooltip a {
      font: 600 26px/10px benton-sans, sans-serif;
  }
  #account-registration #telephoneTooltip .closeButtonDiv{
      z-index: 999;
  }
  .vcmBoxText button.inlineLInkNew{
    font-size: 1.105em;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  #account-registration .noPad + .passCodeWrapper {
      padding-left: 20px;
  }
  #account-registration .RHSWrapper .row div > h4 {
      width: 200%;
      max-width: 300px;
  }
}


#account-registration #telephoneTooltip {
  width: calc(100% - 30px);
  padding: 14px 25px 22px 40px;
}

#account-registration .listDescCol > div {
  margin-bottom: 1.2em;
}


@media (max-width: 1024px) and (min-width: 768px) {
  #account-registration  #telephoneTooltip {
      width: 100%;
  }
  #account-registration .helpNumberTooltip{
      margin-left: -20px;
  }

  #account-registration .helpNumberTooltip a {
      font: 600 26px/10px benton-sans, sans-serif;
  }
  #account-registration #telephoneTooltip .closeButtonDiv{
      z-index: 999;
  }
}

#account-registration .tc_disclaimer, .pdfImage{
  display: block;
  float: left;
}

#account-registration .col-lg-9{
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

#account-registration .contentWrapper .container-fluid{
  padding-left: 15px !important;
  padding-right: 15px !important;
}


@media (max-width: 1280px){
  #account-registration + .fab-modal-root{
      top: calc(100vh - (0.75 + 0.15) * 9.5rem);
      right: 3.55rem;
  }
}