.loadMore {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 16px;
    color: #004a98;
    font-weight: 600;
    margin-bottom: 50px;
    position: relative;
}
.removeBtnWrpr button,
.loadMore button {
    color: inherit;
    font: inherit;
    background: transparent;
    border: none;
    padding: 0px;
    display: flex;
}
.loadMore button div {
    margin-left: 5px;
}
.myModal {
    max-width: 1020px !important;
    min-height: 650px !important;
}

.filterIcon,
.compareIcon,
.printIcon,
.sortIcon {
    float: left;
    position: absolute;
    top: 2px;
}
.investSelContainer .tableAction {
    flex-grow: 0;
    margin-left: 50px;
}

.investSelContainer .tableAction button p {
    white-space: nowrap;
}
.noOfItemsSelected {
    font-size: 14px;
    color: #56565a;
    display: block;
    margin-bottom: 30px;
    font-weight: 600;
}
.noOfItemsSelected span {
    font-size: 16px;
    color: #486d90;
    font-weight: 700;
    margin-right: 5px;
}
.investSelContainer .invListTable {
    margin-bottom: 20px;
    color: #49494a !important;
}
.investSelContainer .invListTable tbody {
    border-bottom: 1px solid rgb(206, 206, 206);
}
.investSelContainer .invListTable th,
.investSelContainer .invListTable td {
    padding: 20px 20px 20px 20px;
    position: relative;
}
.investSelContainer .invListTable th:first-child,
.investSelContainer .invListTable td:first-child {
    padding: 20px 20px 20px 100px;
}
.investSelContainer .invListTable tbody tr:nth-child(even),
.investSelContainer .fundInvestTable tbody tr:nth-child(even) {
    background-color: rgba(250, 250, 250, 1);
}

.checkBoxHeader {
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 1px solid #4f799f;
    position: absolute;
    left: 30px;
    top: 24px;
}

.invListTable .checkBoxWrapper input[type='checkbox'] {
    position: absolute;
    left: 30px;
    top: 24px;
}

/* .investSelContainer .invListTable {
    color: #49494a !important;
} */

/*  .investSelContainer .invListTable td span.riskSpan::before {
    content: "";
    border-radius: 50% 50%;
    width: 8px;
    height: 8px;
    margin-right: 5px;
    display: inline-block;
}
.investSelContainer .invListTable td span.high::before {
    background-color: #FF0000;
    border: 1px solid #FF0000;
}
.investSelContainer .invListTable td span.medium::before {
    background-color: #F89873;
    border: 1px solid #F89873;
}
.investSelContainer .invListTable td span.low::before {
    background-color: #8BC105;
    border: 1px solid #8BC105;
} */

.investSelContainer .invListTable td.initialInvestmentval::before {
    content: '$';
}

.compareFundsButton p::after {
    content: ' [Up to 4]';
    color: #49494a;
    font-size: 12px;
    font-weight: 500;
    font-family: benton-sans, yorkten-slab-normal, sans-serif;
}

.accordionHeading div {
    position: absolute;
    left: 0;
}

#fundSrcOptions {
    margin-bottom: 65px;
}

#onlineBankAccDetails {
    margin-top: 40px;
    margin-bottom: 50px;
}

#fundUrInvAcc {
    margin-top: 40px;
    margin-bottom: 75px;
}

.accordionHeading {
    padding-left: 32px;
    position: relative;
    margin-bottom: 15px;
    font-size: 22px;
    color: #486d90;
    font-family: yorkten-slab-normal, benton-sans, sans-serif;
    font-weight: 600;
}

.investSelContainer .collapse {
    border-top: 1px solid #cecece;
    padding-top: 30px;
}

.bankAcclabl label,
.bankAcclabl .accTypeLabel {
    max-width: 285px;
}
#onlineBankAccDetails .bankAcclabl .col-sm-7 {
    max-width: 350px;
    flex-basis: 61%;
}

.specimenCard .card-img {
    order: 2;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    max-width: 93%;
}

#fundUrInvAcc select,
#fundUrInvAcc input {
    height: 50px;
    font-size: 14px;
    border-radius: 0;
}
.dollar {
    position: relative;
}
.dollar::before {
    content: '$';
    position: absolute;
    left: 0;
    top: 12px;
}

#monthlyInvestmentinputErrorText {
    margin-top: 20px;
}
#fundUrInvAcc tfoot .dollar::before {
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
}
.maxValplaceholder,
.datePlaceholder,
.datePickerRow input[name='startDate'] ~ small:not(.errorMsgInputStyle) {
    font-size: 12px;
    color: rgba(105, 138, 172, 0.4);
}
.maxValplaceholder::before {
    content: 'Minimum ';
}
.dollar::after {
    position: absolute;
    left: 0;
    bottom: -20px;
    font-size: 12px;
    color: rgba(105, 138, 172, 0.4);
}
.table_invVal {
    width: 155px;
    word-wrap: break-word;
}
.removeBtnWrpr div {
    position: absolute;
    left: 0;
}
#fundUrInvAcc .fundUrInvAccCol1 {
    max-width: 200px;
}
#fundUrInvAcc tbody td {
    height: 95px;
}
#fundUrInvAcc tfoot tr {
    position: relative;
}
/* #fundUrInvAcc tfoot td{
    height: 60px;
} */
#fundUrInvAcc .tablefooterWhitespace {
    content: '';
    width: 100%;
    height: 20px;
    display: block;
    background-color: #fff;
    max-width: 1110px;
}
.investSelContainer .form-control.is-invalid {
    border-radius: 0px;
    border: 1px solid red;
    background-image: none;
}
.investSelContainer .invalid-feedback {
    color: red;
}

#onlineBankAccDetails,
#offlineFundDetails {
    margin-top: 40px;
    padding: 30px 95px;
    border: 2px solid #8bc105;
    position: relative;
    background-color: #fafafa;
}
#onlineBankAccDetails::before,
#offlineFundDetails::before {
    content: '';
    border-bottom: 35px solid transparent;
    border-left: 35px solid #8bc105;
    position: absolute;
    height: 0;
    width: 0;
    top: 0;
    left: 0;
}
.bankAccDetailsHeading,
.offlineDetailsHeading {
    margin-bottom: 15px;
    font-size: 22px;
    color: #486d90;
    font-family: yorkten-slab-normal, benton-sans, sans-serif;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
}

.bankAccDetailsHeading span {
    text-align: right;
    font: 500 16px/20px benton-sans, yorkten-slab-normal, sans-serif;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
}

.BankAccDetailsSec,
.offlineDetailsSec {
    padding-top: 30px;
    border-top: 1px solid #cecece;
}
.offlineDetailsHint {
    margin-top: 40px;
    border: 1px solid #d2d2d2;
    background-color: #fff;
    padding: 25px 30px 25px 95px;
    font: 600 16px/22px benton-sans, yorkten-slab-normal, sans-serif;
    position: relative;
}
.offlineDetailsHint::before {
    content: '';
    border-bottom: 25px solid transparent;
    border-left: 25px solid #8bc105;
    position: absolute;
    height: 0;
    width: 0;
    top: 0;
    left: 0;
}
#onlineBankAccDetails .bankAcclabl {
    flex-grow: 1;
    max-width: unset;
}
#onlineBankAccDetails .specimenCardWrapper {
    max-width: 255px;
}
#onlineBankAccDetails .specimenCardWrapper .card-body {
    padding: 30px 30px 0;
}
.fundPaymentWrapper {
    margin-top: 30px;
}
p + .fundPaymentWrapper {
    margin-top: 0;
}
.valueSpanOffDet {
    display: flex;
    flex-direction: column;
    font: 500 16px/24px benton-sans, yorkten-slab-normal, sans-serif;
    color: #49494a;
}
.printAction.col {
    flex-grow: 0;
}
button.clearFilters {
    padding-left: 25px;
    background: url('../../../assets/resetIcon.png') no-repeat center left;
    width: auto;
    margin-right: 3px;
}

#fundUrInvAcc .table-responsive {
    overflow-x: visible;
}
.fundInvestTable .dropdownRow > .col-sm-12 input[name='fundingOption'] {
    max-width: 240px;
    width: 240px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .fundSourceWrapper {
        flex-direction: column;
    }
    .bankAcclabl label,
    .bankAcclabl .accTypeLabel {
        flex-basis: 43%;
    }
    #onlineBankAccDetails .bankAcclabl .col-sm-7 {
        flex-basis: 57%;
    }
    #fundUrInvAcc .tablefooterWhitespace {
        max-width: 690px;
    }
    #fundUrInvAcc [name='fundingOption'] {
        min-width: 112px;
    }
    #fundUrInvAcc .tableDate {
        max-width: 100px;
    }
    .investSelContainer .invListTable td span.riskSpan::before {
        margin-right: 3px;
    }
    #onlineBankAccDetails .specimenCardWrapper {
        max-width: 100%;
        flex-basis: 100%;
    }
    .table_invVal {
        max-width: 115px;
        width: 115px;
    }
    .table_fundOptions {
        max-width: 230px;
        width: 230px;
    }
    .fundInvestTable.table th,
    .fundInvestTable.table td {
        padding: 12px 10px;
    }
    .maxValplaceholder,
    .datePlaceholder {
        font-size: 9px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .fundPaymentWrapper .card-title {
        font-size: 18px !important;
    }
    #onlineBankAccDetails .bankAcclabl .col-sm-7 {
        flex-basis: 51%;
    }

    #fundUrInvAcc .tablefooterWhitespace {
        max-width: 930px;
    }
    .investSelContainer .invListTable td span.riskSpan::before {
        margin-right: 3px;
    }
}

.investSelContainer .fundInvestTable .dollar label + div {
    position: static;
}

.investSelContainer .fundInvestTable .dollar small {
    bottom: -15px;
}

.investSelContainer .fundInvestTable .dollar .maxValplaceholder {
    margin-top: 0.3125rem;
    margin-bottom: 1.5rem;
    display: block;
}
