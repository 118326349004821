.commonButtons { 
    margin-top: 100px;
}
.commonButtonsThreeFourth { 
    margin-top: 70px;
}
.commonButtonsHalf { 
    margin-top: 20px; 
}
.hide { 
    display: none; 
}
.infoStrong {
    font: 700 16px/24px benton-sans, sans-serif;
}
.radioText {
    font: 600 16px/22px benton-sans, sans-serif;
}
.subHeading { 
    font: 600 16px/22px benton-sans, sans-serif;
    color: '#49494A';
    margin-bottom: 30px;
}
.inputField {
    border-radius: 0;
    margin-left: 5px;
    width: 160px;
    height: 50px;
}
.optionalMessage { 
    font: 500 16px/20px benton-sans, sans-serif; 
    display: flex; 
    justify-content: flex-end; 
    margin-bottom: 20px
}

.errMsg {
    vertical-align: top;
    margin: 0;
    padding-top: 1px;
    color: #E60000;
    display: inline-block;
    font: 700 12px/22px 'benton-sans' , sans-serif;
}