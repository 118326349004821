.btn-outline-light.focus,
.btn-outline-light:focus {
    outline: none !important;
}

@media (max-width: 767px) {
    .riskImg {
        width: 66px;
        height: 20px;
    }
    .selling-options p {
        padding-left: 15px;
        margin-bottom: 1rem;
    }
}
