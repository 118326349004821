@media (max-width: 767px) {
    .iraContributionSelect .dropdownRow {
        margin-bottom: 0;
    }
    .reviewConfirmPage {
        margin-top: 30px;
    }
    .reviewConfirmPage #cancelButton,
    .eConsentContainer #cancelButton,
    #cancelButton {
        margin: 0 !important;
        padding-right: 0;
    }
    .reviewConfirmPage .backButton,
    .eConsentContainer .backButton,
    .backButton,
    .rwdBtnWrapper #nextButton,
    .rwdBtnWrapper #cancelButton {
        padding-right: 0;
        /* margin:0 auto!important; */
        float: none !important;
        display: block;
    }
    .reviewConfirmPage .leftCornerCard {
        border-bottom: 20px solid transparent;
        border-left: 20px solid #8bc105 !important;
    }
    #documents-review-section .eConsetReviewTextTwo {
        padding-left: 0px !important;
    }
    .exchangeReviewContainer {
        padding-top: 30px;
    }
    .exchangeReviewContainer div[data-test='review-page'] {
        margin-bottom: 12px;
    }
    .exchangeReviewContainer #transReviewEditButton {
        margin-bottom: 8px !important;
        padding-bottom: 0 !important;
    }
    .rwdBtnWrapper > div {
        height: auto !important;
        border-top: 1px solid rgb(210, 210, 210);
        padding: 20px 0;
    }
    .rwdBtnWrapper hr {
        display: none;
    }
}
@media (max-width: 767px) {
    .iraContributionSelect .dropdownRow {
        margin-bottom: 0;
    }
    .reviewConfirmPage {
        margin-top: 30px;
    }
    .reviewConfirmPage #cancelButton,
    .eConsentContainer #cancelButton,
    #cancelButton {
        margin: 0 !important;
        padding-right: 0;
    }
    .reviewConfirmPage .backButton,
    .eConsentContainer .backButton,
    .backButton,
    .rwdBtnWrapper #nextButton,
    .rwdBtnWrapper #cancelButton {
        padding-right: 0;
        /* margin:0 auto!important; */
        float: none !important;
        display: block;
    }
    .reviewConfirmPage .leftCornerCard {
        border-bottom: 20px solid transparent;
        border-left: 20px solid #8bc105 !important;
    }
    #documents-review-section .eConsetReviewTextTwo {
        padding-left: 0px !important;
    }
    .exchangeReviewContainer {
        padding-top: 30px;
    }
    .exchangeReviewContainer div[data-test='review-page'] {
        margin-bottom: 12px;
    }
    .exchangeReviewContainer #transReviewEditButton {
        margin-bottom: 8px !important;
        padding-bottom: 0 !important;
    }
    .rwdBtnWrapper > div {
        height: auto !important;
        border-top: 1px solid rgb(210, 210, 210);
        padding: 20px 0;
    }
    .rwdBtnWrapper hr {
        display: none;
    }
}
