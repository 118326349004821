@media only screen and (min-width: 768px) {
    .edit-plan-stepper {
        padding: 0 4rem;
    }

    .stepperText + hr {
        width: 240%;
        top: 9px;
        position: absolute;
    }

    
}

