@media (max-width: 767px) {
    .mobile-banner-view {
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start !important;
    }

    .banner-alert-msg {
        width: 85%;
    }

    .banner-alert-btn {
        width: 15%;
    }

    .align-items-center {
        align-items: flex-start;
    }
}
