#account-registration .listDescWrapper.row {
    padding-bottom: 4px;
    padding-top: 23px;
    border-bottom: solid 1px rgba(112, 112, 112, 0.34);
    cursor: pointer;
    flex-wrap: nowrap;
}
#account-registration .listDescWrapper.row:first-child{
    padding-top: 0;
}
#account-registration .listDescWrapper.row .listDescCol {
    max-width: calc(100% - 30px);
    margin-right: 10px;
}
#account-registration h3.listHeader .listDesc {
    color: #004a98;
}
#account-registration .listDesc {
    display: block;
    color: #54565A;
    font: 600 18px/28px benton-sans;
    margin: 0;
    margin-bottom: 12px;
}
#account-registration .listDesc + div > ul {
    margin: 12px 0;
}
#account-registration .listDescWrapper > div {
    font: 1em/1.6em benton-sans;
}
#account-registration .listPadRow > .benefitsTiles  > .container-fluid{
    margin-top: 16px;
}
#account-registration .listDescWrapper button {
    background: transparent;
    -webkit-appearance: none;
    border: none;
}
#account-registration .listDescCol .listDesc + div {
    cursor: default;
}
@media only screen and (max-width:767px) {
    #account-registration .listDesc {
        font: 600 16px/24px benton-sans;
    }
    #account-registration .listDescWrapper > div {
        font: 500 16px/24px benton-sans;
    }
}

#account-registration h3.listHeader {
    border-bottom: 0;
    padding-bottom: 0;
    width: 100%;
}