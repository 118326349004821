.wradio {
    display: flex;
    align-items: center;
}

.wradio input[type="radio"] {
    opacity: 0;
    height: 0px;
    width: 0px;
}

.wradio label::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    align-items: center;
    justify-content: center;
    display: flex;
    border: 1px solid rgba(73, 73, 74, 1);
    border-radius: 50%;
    height: 24px;
    width: 24px;
    margin-right: 20px
}

.wradio input[type="radio"]:disabled+label::before {
    border: 1px solid #49494A;
    background:#efefef;
    cursor: default;
}

.wradio label::after {
    position: absolute;
    left: 7px;
    top: 7px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #004A98;
    border: 1px solid rgb(73, 73, 74);
}


.wradio.selected label::after {
    content: "";
}

.wradio input[type="radio"]:focus+label::before {
    outline:  2px solid #000000;
}

.wradio input.not-visible {
    position: absolute;
}