.verify {
    min-height: 100vh;
    position: relative;
    /* padding: 33px 10px 0; */
}
.verify-pageheading {
    color: rgba(72, 109, 144, 1);
    font: 800 30px/36px yorkten-slab-normal, benton-sans, sans-serif;
    padding-bottom: 10px;
    border-bottom: 2px solid rgb(140, 191, 38);
    display: inline-block;
    margin-bottom: 40px;
    margin-top: 40px;
}
.stepper {
    width: 1107px;
    height: 60px;
    padding-right: 90px;
    padding-left: 150px;
}
.cardWrap {
    padding-bottom: 20px;
}
/* .buttonwrap {
    padding-bottom: 20px
} */
.backbutton {
    padding: 20;
}
