.infoIcon {
    margin-top: 2px;
    margin-left: 5px;
    cursor: pointer;
    -webkit-appearance: none;
    /* background-color: transparent; */
    border: none;
    background: transparent url('../../../assets/hint-small.png') no-repeat center;
    width: 20px;
    height: 20px;
    padding: 0;
}

.contentPerf {
    flex-wrap: wrap;
    display: flex;
    margin-left: 0;
    margin-right: 0;
    padding: 40px 0;
    border-bottom: 1px solid rgba(210, 210, 210, 1);
}
.cardContent > div:last-child .contentPerf {
    border: none;
    padding-bottom: 0;
}

.contentPerf .row {
    width: 100%;
}

.fieldkey {
    font: 700 18px/22px benton-sans, yorkten-slab-normal, sans-serif;
    color: rgba(73, 73, 74, 1);
    padding-bottom: 20px;
}

.hinttext {
    font: 600 16px/20px benton-sans, yorkten-slab-normal, sans-serif;
    letter-spacing: 0;
    color: rgba(73, 73, 74, 1);
    opacity: 1;
    margin-bottom: 30px;
}

.radioheading {
    font-weight: 700;
    font-family: benton-sans, yorkten-slab-normal, sans-serif;
    font-size: 16px/22px;
    color: rgba(73, 73, 74, 1);
    padding-bottom: 30px;
}
.optionsWrapper {
    margin-bottom: 40px;
}
.radioWrapper {
    font-family: benton-sans, yorkten-slab-normal, sans-serif;
    font-size: 18px/28px;
    color: rgb(73, 73, 74);
    margin-right: 50px;
}

.radioWrap {
    font-family: benton-sans, yorkten-slab-normal, sans-serif;
    font-size: 18px/28px;
    color: rgb(73, 73, 74);
    margin-right: 50px;
}

.cardWrapper {
    position: relative;
    padding: 40px 0px 0px 0px;
}

.cardrow {
    margin-right: 0;
}

.cardheading {
    text-align: left;
    font: 700 22px/45px yorkten-slab-normal, benton-sans, sans-serif;
    letter-spacing: 0;
    color: #4f799f;
    opacity: 1;
    margin-bottom: 0;
}
.perf-card .cardrow {
    border-bottom: 1px solid rgba(210, 210, 210, 1);
    margin-bottom: 30px;
}
.dividendHint {
    padding: 26px 25px 21px 31px;
    border: 1px solid rgba(210, 210, 210, 1);
    position: relative;
    font: 600 16px/22px benton-sans, yorkten-slab-normal, sans-serif;
    color: #49494a;
    opacity: 1;
}
.dividendHint::before {
    content: '';
    width: 0px;
    height: 0px;
    border-bottom: 25px solid transparent;
    border-left: 25px solid rgba(139, 193, 5, 1);
    position: absolute;
    top: 0;
    left: 0;
}
.ruler {
    margin-top: 0px;
}

.cardSubheading {
    font: 600 16px/19px benton-sans, yorkten-slab-normal, sans-serif;
    color: rgba(73, 73, 74, 1);
    margin-bottom: 10px;
}

.cardContent {
    padding: 0;
    background-color: #ffffff;
    text-align: left;
    font: 500 16px/19px benton-sans, yorkten-slab-normal, sans-serif;
    letter-spacing: 0;
    color: rgba(73, 73, 74, 1);
    opacity: 1;
}

.preferencesPage {
    min-height: 100vh;
    position: relative;
    /* padding: 33px 10px 0; */
}

.preferencesHeading {
    margin-bottom: 45px;
    padding-bottom: 10px;
    padding-top: 40px;
    border-bottom: 2px solid #8cbf26;
    text-align: left;
    font: 700 30px/45px yorkten-slab-normal, benton-sans, sans-serif;
    letter-spacing: 0;
    color: #4f799f;
    opacity: 1;
    display: inline-block;
}

.stepper-comp {
    width: 1107px;
    height: 60px;
    padding-right: 90px;
    padding-left: 150px;
}

/* .perf-card {
    padding-bottom: 20px
} */

/* .perf-buttonWrapper {
    padding-bottom: 20px
} */

.error-msg {
    color: red;
}
.preferencesPage .fieldlabel {
    font: 700 18px/22px benton-sans, yorkten-slab-normal, sans-serif;
    color: rgba(73, 73, 74, 1);
    margin-bottom: 0;
}
