@media only screen and (min-width: 768px) {
    .mobile-stepper {
        display: none;
        font-family: yorkten-slab-normal, sans-serif;
    }
}
@media only screen and (max-width: 767px) {
    .mobile-stepper {
        display: block;
        background-color: #3e5975;
        width: 100% !important; /* keep this as important when merging */
        position: relative;
        /* top: 9px; */ /* can be removed */
        /* height: 95px; */
        padding: 20px 15px !important; /* keep this as important when merging */
        color: #ffffff;
    }
    .stepperMobileContainer h1
    {
        display: inline-block !important;
    }
    .currentStep {
        color: #ffffff;
        font: 20px/30px yorkten-slab-normal;
        margin-bottom: 0;
    }
    .currentPage {
        color: #BDCFE2;;
        font: 18px/30px yorkten-slab-normal;
        display: block;
    }

    .mobile-cont .desktopStepper {
        display: none;
    }
    .desktopStepper {
        display: none;
    }
}
