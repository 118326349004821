.adp-wrapper {
    position: relative;
    padding: 0;
    background: #ffffff 0% 0% no-repeat padding-box;
}
.adp-wrapper > .container {
    margin-bottom: 50px;
}

.editDeliveryPreferencesWrapper {
    min-height: 100vh;
    position: relative;
    padding: 0;
    background: #ffffff 0% 0% no-repeat padding-box;
}
.adp-page-heading {
    margin-bottom: 40px;
    padding-bottom: 10px;
    border-bottom: 2px solid rgba(139, 193, 5, 1);
    text-align: left;
    font: 800 30px/36px yorkten-slab-normal, sans-serif;
    letter-spacing: 0;
    color: rgba(72, 109, 144, 1);
    opacity: 1;
    display: inline-block;
}
.adp-wrapper .esign-cards {
    padding-bottom: 0;
    padding-top: 0;
    font: 500 16px/19px benton-sans, sans-serif;
    letter-spacing: 0;
    color: #333333de;
}
.adp-wrapper .esign-cards .accordion h2 {
    padding-left: 30px;
    margin-bottom: 31px;
}
.adp-wrapper .checkwrapper {
    min-height: 80px;
    background: rgba(247, 247, 247, 1) 0% 0% no-repeat padding-box;
    opacity: 1;
    text-align: left;
    font: 500 16px/25px benton-sans, sans-serif;
    letter-spacing: 0;
    color: #3e607e;
}

.container .delivery-check-content {
    width: 100%;
    /*justify-content: space-between;*/
    padding: 20px 0px 23px 0px;
    flex-wrap: wrap;
    display: inline-flex;
    align-items: center;
}

/*.container .delivery-check-content .col-2:first-child {*/
/*    flex-basis: 10%;*/
/*}*/

.container .delivery-check-content .checkBoxWrapper {
    margin-left: 21px;
}

.container .delivery-check-content .checkBoxWrapper input[type='checkbox'] {
    margin-right: 15px;
}

.container .delivery-check-content .checkBoxWrapper input[type='checkbox']:checked:after {
    top: 1px;
    left: 8px;
    width: 8px;
    height: 16px;
}

.container .delivery-check-content label {
    text-align: left;
    font: 500 16px/25px benton-sans, sans-serif;
    letter-spacing: 0;
    color: #3e607e;
    opacity: 1;
}
.adp-wrapper .error-check {
    color: red;
    padding-left: 40px;
}
.adp-wrapper .revenue {
    margin-top: 20px;
    text-align: left;
    font: 400 14px/24px benton-sans, sans-serif;
    letter-spacing: 0;
    color: #616366;
    opacity: 1;
    padding-bottom: 70px;
    padding-top: 15px;
}
.adp-wrapper .esign-buttons {
    padding-bottom: 0;
}
.adp-wrapper .date-time {
    text-align: right;
    font: 600 14px/20px benton-sans, sans-serif;
    letter-spacing: 0;
    color: rgba(86, 86, 90, 1);
    opacity: 1;
    padding-right: 20px;
}
.esign-cards .card {
    border: none;
}
.adp-wrapper .accordion .card-header {
    border: none;
    padding: 0;
    background: #ffffff 0% 0% no-repeat padding-box;
}
.adp-wrapper .card-body .Slabel .container-fluid {
    flex-basis: 10%;
    margin-left: 0;
    padding-left: 0;
    margin-right: 1%;
}
.adp-wrapper .card-body .Slabel .container-fluid label {
    flex-basis: 0%;
    width: 0px;
    margin-bottom: 16px;
    font-weight: normal;
}

.adp-wrapper .card-body .Slabel {
    justify-content: space-between;
}

.adp-wrapper #delivery {
    padding-top: 20px;
    margin-bottom: 60px;
}

.adp-wrapper #delivery .card-body {
    padding: 0px 15px 0;
}
.adp-wrapper #delivery .card-body .row {
    text-align: left;
    font: 600 16px/22px benton-sans, sans-serif;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-top: 31px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(210, 210, 210, 1);
    align-items: center;
}
.adp-wrapper #delivery .card-body .rowHeader,
.adp-wrapper #delivery .card-body .rowHeader2 {
    margin-top: 0;
    padding-bottom: 0;
    margin-bottom: 40px;
    border-bottom: none;
    font: 600 18px/22px benton-sans, sans-serif;
    letter-spacing: 0.36px;
    color: rgba(51, 51, 51, 0.87);
}
.adp-wrapper #delivery .card-body .rowHeader2 {
    margin-top: 40px;
}

.adp-wrapper #seasonal .card-body .row {
    text-align: left;
    font: 600 16px/22px benton-sans, sans-serif;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
}
.serviceDatesRow {
    width: 100%;
    margin-top: 24px;
}

.adp-wrapper .handIcon {
    height: 20px;
    width: 16px;
}

.adp-wrapper .applyLink {
    text-align: center;
    font: 700 16px/20px Yorkten Slab Norm ExBold, sans-serif;
    letter-spacing: 0;
    color: #004a98;
    opacity: 1;
    border: none;
    background: none;
}
#seasonal label[for='set-address'] + div {
    margin-left: 4px;
}
.mandatoryFieldsHint {
    font: 500 16px/22px benton-sans, sans-serif;
    color: rgba(73, 73, 74, 1);
    float: right;
    margin-bottom: 10px;
}
#seasonal .card-body {
    padding: 10px 15px 0;
}
#seasonal .form-group,
#seasonal label[for='set-address'] {
    margin-bottom: 40px !important;
}
#seasonal .fromDate::before,
#seasonal .toDate::before {
    top: -24px;
    font: 500 14px/20px benton-sans, sans-serif;
}
#seasonal .card {
    overflow: visible;
}
#seasonal .serviseDates .datePickerRow.row {
    margin-bottom: 0;
}
.adp-wrapper .backButton {
    background: url(../../../assets/hand.png) no-repeat center left;
    background-size: 15px;
}
.adp-wrapper .esign-buttons .container .col-2:first-child {
    flex-basis: 10%;
}
#seasonal {
    display: none;
}

.buttonAccordion {
    display: flex;
    width: 100%;
    border: none;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-bottom: 1px solid rgba(210, 210, 210, 1);
}
.prefCancelBtn {
    color: rgb(0, 74, 152);
    font: 700 16px/22px benton-sans, sans-serif;
    background: transparent;
    border: none;
    padding: 0px 0px 0px 30px;
    position: relative;
    margin-top: 5px;
    margin-bottom: 70px;
}
/* 
.buttonAccordion:focus {
    border: 2px solid #004a98;
    outline: 0px;
} */
