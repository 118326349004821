.checkBoxWrapper input[type="checkbox"] {
    margin-right: 20px;
    border-color: rgba(86, 86, 90, 1);
    position: relative;
}

.checkBoxWrapper input[type="checkbox"]:checked {
    border-color: #4F799F;
}

.checkBoxWrapper input[type="checkbox"]:checked:after {
    content: '';
    display: block;
    width: 5px;
    height: 10px;
    border: solid #4F799F;
    border-width: 0 2px 2px 0;
    transform: rotate(36deg);
    top: 2px;
    left: 6px;
    position: absolute;
    background-color: #fff;
}
.backgroungBlur{
    background-color:rgb(210, 210, 210) !important;
}