.aip-wrapper {
    min-height: 100vh;
    position: relative;
    padding: 33px 10px 60px;
    background: #ffffff 0% 0% no-repeat padding-box;
}
.aip-page-heading {
    margin-bottom: 40px;
    padding-bottom: 10px;
    border-bottom: 2px solid #8cbf26;
    text-align: left;
    font: 800 30px/36px 'yorkten-slab-normal';
    letter-spacing: 0;
    color: rgba(72, 109, 144, 1);
    opacity: 1;
    display: inline-block;
}
.esign-cards {
    padding-bottom: 10px;
    padding-top: 10px;
    font: Regular 16px/19px 'benton-sans';
    letter-spacing: 0;
    color: #333333de;
}
.aip-wrapper .checkwrapper {
    background: #f0f4f7 0% 0% no-repeat padding-box;
    opacity: 1;
    text-align: left;
    font: 500 16px/25px benton-sans;
    letter-spacing: 0;
    color: #3e607e;
}
.aip-wrapper .check-content {
    justify-content: space-between;
    padding-top: 35px;
    padding-bottom: 35px;
    flex-wrap: wrap;
    display: inline-flex;
    padding-left: 20px;
    letter-spacing: 0;
    opacity: 1;
}
.aip-wrapper .check-content .checkBoxWrapper {
    margin-left: 30px;
    width: 700px;
}
.aip-wrapper .check-content label {
    text-align: left;
    font: Medium 16px/25px benton-sans;
    letter-spacing: 0;
    color: #3e607e;
    opacity: 1;
}
.aip-wrapper .error-check {
    color: red;
    padding-left: 40px;
}
.aip-wrapper .revenue {
    margin-top: 20px;
    text-align: left;
    font: 400 14px/24px 'benton-sans';
    letter-spacing: 0;
    color: #616366;
    opacity: 1;
    padding-bottom: 70px;
    padding-top: 15px;
}
.aip-wrapper .esign-buttons {
    padding-bottom: 20px;
}
.aip-wrapper .date-time {
    text-align: left;
    font: 600 16px/25px 'benton-sans';
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    width: 200px;
    padding-left: 20px;
}
.esign-cards .card {
    border: none;
}
.aip-wrapper .card-body .Slabel .container-fluid {
    flex-basis: 10%;
    margin-left: 0;
    padding-left: 0;
    margin-right: 1%;
}
.aip-wrapper .card-body .Slabel .container-fluid label {
    flex-basis: unset;
    width: unset;
    margin-bottom: 16px;
    font-weight: normal;
}

.aip-wrapper .card-body .Slabel {
    justify-content: space-between;
}

.aip-wrapper #delivery .card-body .row {
    text-align: left;
    font: 600 16px/22px 'benton-sans';
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    padding-top: 25px;
    padding-bottom: 25px;
}

.aip-wrapper #seasonal .card-body .row {
    text-align: left;
    font: 600 16px/22px 'benton-sans';
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    padding-top: 10px;
    padding-bottom: 10px;
}
