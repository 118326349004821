.marketpersonalIfoFormWrapper .form-control {
    height: 50px;
    /* max-width: 445px; */
    border-radius: 0px;
    border: 1px solid rgb(210, 210, 210);
}

.marketpersonalIfoFormWrapper .form-control.is-invalid {
    border-color: red;
    background-image: none;
}

.marketpersonalIfoFormWrapper .form-control.is-invalid + .invalid-feedback {
    color: red;
}

.marketpersonalIfoFormWrapper .card {
    border: none;
}
.uspsSuggession .card {
    border: 2px solid #ffffff;
}
.uspsSuggession .card .leftCornerCard {
    border-left: 35px solid #8cbf26;
}
.UploadPh > div,
.nonUsForm > div {
    flex-basis: 60%;
    max-width: 445px;
}
.UploadPh div label,
.nonUsForm div label {
    width: 160px;
    flex-basis: unset;
}
.UploadPh div .dzu-previewContainer + label,
.nonUsForm div .dzu-previewContainer + label {
    width: 0;
}
/* .nonUsForm > label{
    margin-bottom: 40px;
} */
.form8Address {
    display: flex;
    flex-direction: column;
    font-size: 16px;
}
.form8Address b {
    font-size: 18px;
    margin-bottom: 10px;
}
.w8FormExplain {
    position: absolute;
    top: 20px;
    font-size: 12px;
    color: #5d83ae !important;
    text-decoration: underline !important;
}
.marketmarketpersonalIfoFormWrapper,
.marketpersonalIfoFormWrapper .label {
    font-size: 16px;
    font-weight: 600;    
    width: 100% !important;
    flex-basis: 100% !important;
    /* max-width: 285px; */
}

.TrustedContactInfo label,
.TrustedContactInfo .label {
      
    width: 100% !important;
    flex-basis: 100% !important;
    /* max-width: 285px; */
}
.emailGroup .label{
    width: 24.3% !important;
    flex-basis: 24.3%  !important;
}
@media (max-width: 767px){
    .marketmarketpersonalIfoFormWrapper,
    .marketpersonalIfoFormWrapper .label {    
        width: 100%;
        flex-basis: 100%;
    }
    .emailGroup .label {
        width:100% !important;
        flex-basis: 100% !important;
    }
}
.marketpersonalIfoFormWrapper .datePickerRow > .col-sm-5 {
    max-width: 255px;
}
.marketpersonalIfoFormWrapper .card-body .row .form-group label + .col-sm-5 {
    max-width: 445px;
}

.marketpersonalIfoFormWrapper .form-group label + div::after,
.PhNumberWrapper .inputDiv::after {
    position: absolute;
    left: 0;
    bottom: -20px;
    font-size: 12px;
    color: #56565a;
}
.hintText {
    font-size: 12px;
    color: #56565a;
}
.marketmarketpersonalIfoFormWrapper.dobdate + div::after {
    content: 'MM/DD/YYYY';
}
.marketmarketpersonalIfoFormWrapper[labelfor='SSN'] + div::after {
    content: 'XXX-XX-XXXX';
}

/*.PhNumberWrapper .inputDiv::after {*/
/*    content: 'XXX-XXX-XXXX';*/
/*}*/
.marketmarketpersonalIfoFormWrapper.optional::after {
    content: '(Optional)';
    font-weight: normal;
    font-size: 14px;
    color: rgba(86, 86, 90, 0.65);
    margin-left: 10px;
}
.marketpersonalIfoFormWrapper > .row {
    margin: 0;
}

#accordionH2Style {
    position: relative;
    margin-bottom: 30px;
    font-size: 22px;
    line-height: 35px;
    color: #486d90;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    border-bottom: 1px solid #d2d2d2;
    padding-bottom: 5px;
    padding-left: 0px; /* fixed based on design library accordion header */
}

.marketpersonalIfoFormWrapper h2,
.accordionHeader {
    position: relative;
    margin-bottom: 30px;
    font-size: 22px;
    line-height: 35px;
    color: #486d90;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
    border-bottom: 1px solid rgba(210, 210, 210, 1);
    padding-bottom: 5px;
    padding-left: 35px;
}

.marketpersonalIfoFormWrapper h2 {
    padding-left: 0;
}

@media (max-width:767px) {
    .accordionHeader {
        padding-bottom: 0
    }
    .retirementHeadading {
        float: none !important;
        padding-top: 0 !important;
        font-size: 27px !important;
        margin-bottom: 0 !important;
    }
    .paraAln {
        float: left !important;
        margin-top: 15px !important;
        font-size: 14px !important;
        color: #56565a !important;
    }
    .marketpersonalIfoFormWrapper .form-control {
        font-size: 16px !important;
        color: #56565a !important;
    }
    .styledfontOptionalSpan {
        font-size: 12px !important;
    }
    .primaryPhoneAlign {
        font-size: 22px !important;
    }
   /*  #MilitaryInformation .datePickerRow:nth-child(1) {
        width: 48% !important;
        margin-right: 4% !important;
    } */
    .dateofServiceLabel {
       flex-basis: auto !important;
    }
    .dateofServiceLabel span {
        font-size: 16px  !important;
    }
    .inputAlign {
        margin-left: 0 !important;
        /* margin-top: 10px; */
    }
    
    .genderRenderStyle {
        display: block !important;
    }
    .alignRadioBtn {
        flex-basis: auto !important;
    }
    .marketpersonalIfoFormWrapper .Slabel {
       
    }
    .sameAddressDiv {
        display:block !important;
    }
    .phoneNumbertext {
        flex-basis: auto !important;
        padding-bottom: 10px !important;
        max-width: 80% !important;
    }
    .marketpersonalIfoFormWrapper .datePickerRow > .col-sm-5 {
        max-width: 100%;
    }
    .form-text {
        flex-basis: auto !important;
        padding-bottom: 10px;
    }
    .empStatusDropdown {
        margin-left: 0 !important;
        margin-top: 10px !important;
        font-size: 16px !important;
    }
    .distributionPercentageHint {
        padding:21px !important;
        height: auto !important;
    }
    .distributionPercentageHint p {
        font-size: 20px !important;
        margin-bottom: 0 !important;
    }
}

.marketpersonalIfoFormWrapper button .accordionHeader {
    position: relative;
    font-size: 22px;
    line-height: 45px;
    color: #486d90;
    font-family: yorkten-slab-normal, sans-serif;
    font-weight: 800;
}

.marketpersonalIfoFormWrapper .card-header h2 {
    padding-left: 32px;
    display: flex;
    justify-content: space-between;
    padding-right: 32px;
}

.card-header h2::before,
.accordionHeader::before {
    content: '';
    background-image: url('../../../assets/Icon_Accod_Close.svg');
    height: 20px;
    width: 20px;
    display: block;
    position: absolute;
    left: 0;
    top: 11px;
    background-repeat: no-repeat;
}
.card-header h2.expand::before,
.accordionHeader.expand::before {
    content: '';
    background-image: url('../../../assets/Icon_Accod_Open.svg');
}

/* small[id$='-error'] {
    margin-left: 7px;
} */

.marketpersonalIfoFormWrapper .personalizeHeader {
    padding-left: 0;
    padding-bottom: 0;
}

/* .card-header h4.expand{
    border-bottom: 1px solid #CECECE;
} */

.marketpersonalIfoFormWrapper .dzu-dropzone {
    min-height: unset;
}
.marketpersonalIfoFormWrapper .accordion > .card {
    border: none;
    overflow: visible;
}
.marketpersonalIfoFormWrapper .card-header {
    background-color: transparent;
    border: none;
    padding: 45px 0 0;
}
.marketpersonalIfoFormWrapper .card-body {
    padding: 0;
}
.marketpersonalIfoFormWrapper .card-body .row,
.marketpersonalIfoFormWrapper .beneficiaryForm .row {
    margin-left: 0;
    margin-right: 0;
    position: relative;
}

.card-body .Slabel .container-fluid {
    flex-basis: 10%;
    margin-left: 0;
    padding-left: 0;
    margin-right: 1%;
}
.card-body .Slabel .container-fluid label {
    flex-basis: unset;
    width: unset;
    margin-bottom: 16px;
    font-weight: normal;
}
.phoneNumberGroup .PhNumberWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 445px;
}
.PhNumberWrapper .inputDiv {
    flex-basis: 58%;
    position: relative;
}

.PhNumberWrapper .selectDiv {
    flex-basis: 36%;
    position: relative;
}

.phoneNumberGroup #emailType {
    max-width: 185px;
    position: absolute;
    top: 0;
    right: 170px;
}

.emailGroup .emailWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 800px;
}
.emailWrapper .inputDiv {
    flex-basis: 110%;
    position: relative;
}

.emailWrapper .inputDiv .form-control {
    max-width: 800px;
}

.emailWrapper .selectDiv {
    flex-basis: 36%;
    position: relative;
}

.emailGroup #emailType {
    max-width: 185px;
    position: absolute;
    top: 0;
    right: 170px;
}

.addAnotherFieldBtn {
    color: rgb(0, 74, 152);
    font: 700 16px/22px benton-sans, sans-serif;
    background: transparent;
    border: none;
    padding: 0px 0px 0px 42px;
    position: relative;
    margin-top: 5px;
    margin-bottom: 70px;
}

.deleteFieldBtn {
    color: rgb(0, 74, 152);
    font: 700 16px/22px benton-sans, sans-serif;
    background: transparent;
    border: none;
    padding: 0px 0px 0px 42px;
    position: relative;
    margin-top: 15px;
    margin-bottom: 60px;
}

.phoneWrapper:last-child .addAnotherFieldBtn,
#emailAccordion .addAnotherFieldBtn {
    margin-bottom: 25px;
}

.serviseDates {
    display: flex;
    justify-content: flex-start;
}

.serviseDates .datePickerRow.row {
    flex-grow: 0;
    max-width: 207px;
    margin-right: 30px;
    padding-right: 0;
    /* position: relative; */
}
.marketradioalign .container-fluid{
    width:auto ;
    float: left ;
    margin-right:20px !important;
}
.marketradioalign .container-fluid:last-child {
    width:45%;
}
.marketpersonalIfoFormWrapper .serviseDates div:last-child{
    margin-right:0px;
}

.marketpersonalIfoFormWrapper .serviseDates .datePickerRow.row {
    margin-bottom: 0;
}
.marketpersonalIfoFormWrapper .serviseDates.serviceDateError {
    margin-bottom: 30px;
}
.serviseDates input {
    width: 100%;
}
.fromDate::before,
.toDate::before {
    content: 'From';
    position: absolute;
    top: -30px;
    font: 600 16px/19px benton-sans, yorkten-slab-normal, sans-serif;
}
.toDate::before {
    content: 'To';
}
.marketpersonalIfoFormWrapper .fromDate::before,
.marketpersonalIfoFormWrapper .toDate::before {
    content: '';
}
/* .marketpersonalIfoFormWrapper .fromDate > label,
.marketpersonalIfoFormWrapper .toDate > label {
    margin-bottom: 10px;
} */
.marketpersonalIfoFormWrapper .row > label:not(.phone-type):not(.contact-me-during):not(#PhoneNumber) {
    padding-right: 10px;
}

.srPolFig {
    border-bottom: 1px dashed #0d68b8;
    margin-left: 5px;
}

.marketpersonalIfoFormWrapper .Slabel {
    margin-bottom: 25px;
}

.emailSection.row #emailType {
    max-width: 185px;
    position: absolute;
    top: 0;
}

.primaryBenSwitchRow > div,
.primarySwitchRow > div {
    margin-left: 4px;
}

.emailAddressRow .emailDotButton::after,
.phoneNumberGroup .emailDotButton::after {
    content: '\2807';
    color: #004a98;
    font-size: 27px;
    height: 30px;
    width: 10px;
    display: inline-block;
}
.emailAddressRow .emailDotButton,
.phoneNumberGroup .emailDotButton {
    position: absolute;
    right: 30px;
    top: 30px;
    background-color: transparent;
}

.emailAddressRow .emailDotMenu,
.phoneNumberGroup .emailDotMenu {
    list-style: none;
    position: absolute;
    top: 70px;
    right: 15px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
    padding: 0;
    z-index: 2;
}

.emailAddressRow .emailDotMenu li .primaryDlt.emailDelete,
.phoneNumberGroup .emailDotMenu li .primaryDlt.emailDelete {
    cursor: default;
    opacity: 0.5;
}
.fieldWrapperDiv {
    background-color: rgba(250, 250, 250, 1);
    border: 1px solid rgba(210, 210, 210, 1);
    margin-bottom: 30px;
}
.fieldWrapperDiv > .row {
    padding: 30px;
}
.fieldWrapperDiv .row.primarySwitchRow {
    border-top: 1px solid rgba(210, 210, 210, 1);
}
.fieldWrapperDiv .emailAddressRow {
    padding-bottom: 5px;
}
.fieldWrapperDiv .phoneNumberGroup {
    padding-bottom: 0;
}
.fieldWrapperDiv .contactTimeRow {
    padding-bottom: 5px;
}
.fieldWrapperDiv > .row label,
.fieldWrapperDiv > .row .label {
    flex-basis: 24.3%;
}
.fieldWrapperDiv > .row .form-group label + .col-sm-6 {
    max-width: 445px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .emailSection.row #emailType {
        right: 21px;
        top: 1.5px;
    }
    .emailSection.row #select-arr {
        right: 45px;
    }
    .phoneWrapper #emailType {
        right: 21px;
        top: 1.5px;
    }
    .phoneNumberGroup.row #select-arr {
        right: 45px;
    }
    .marketpersonalIfoFormWrapper .datePickerRow > .col-sm-5 {
    max-width: 100%;
    }
    .marketpersonalIfoFormWrapper .label,
    .marketmarketpersonalIfoFormWrapper {
        flex-basis: 34%;
    }
    .dividendTransferLabel
    {
        max-width: 32%;
        flex-basis: 33% !important;
    }
    .serviseDates .datePickerRow.row
    {
        max-width: 137px;
    }
    .radioBtnrow
    {
        display: flex !important;
    }
   #MilitaryInformation .card-body .Slabel .container-fluid
    {
        margin-right: 4%;
    }
    #PersonalInformation .card-body .Slabel .container-fluid:first-child
    {
        margin-right: 12%;
    }
    .card-body .Slabel .container-fluid {
        flex-basis: 13%;
        margin-left: 0;
        padding-left: 0;
        margin-right: 1%;
    }
    .card-body .Slabel .container-fluid label {
        flex-basis: unset;
        width: unset;
        margin-bottom: 16px;
        font-weight: normal;
    }
    #mailinguspsSuggestedCardId
    {
        max-width: 100% !important;
    }
    #retirementHeadading
    {
        width: 100%;
    }
    
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .emailSection.row #emailType {
        right: 11%;
    }
    .emailSection.row #select-arr {
        right: 120px;
    }
    .phoneWrapper #emailType {
        right: 11%;
    }
    .phoneNumberGroup.row #select-arr {
        right: 120px;
    }
    .marketpersonalIfoFormWrapper .datePickerRow > .col-sm-5 {
        max-width: 100%;
        }
        .marketpersonalIfoFormWrapper .label,
        .marketmarketpersonalIfoFormWrapper {
            flex-basis: 33%;
        }
        .dividendTransferLabel
        {
            max-width: 32%;
            flex-basis: 33% !important;
        }
        .serviseDates .datePickerRow.row
        {
            max-width: 192px;
        }
}
@media only screen and (min-width: 1200px) {
    .emailSection.row #emailType {
        right: 16%;
    }
    .emailSection.row .select-arr {
        right: 45px;
    }
    .phoneWrapper #emailType {
        right: 16%;
    }
    .phoneNumberGroup.row .select-arr {
        right: 45px;
    }
}
.primaryPercAlloc + .beneficiaryForm {
    margin-top: 25px;
}
.benBtnWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.benBtnWrapper .rmvBtn {
    margin-bottom: 30px;
    margin-top: 30px;
}

.benBtnWrapper .addBtn {
    margin: 0;
}

.emailSection {
    flex-direction: row;
    justify-content: space-between;
}
.emailDotMenu::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 10px solid rgba(255, 255, 255, 1);
    position: absolute;
    top: -10px;
    right: 10px;
}
.emailDotMenu li {
    padding: 8px 10px;
    background-color: #fff;
}

.emailDotMenu .emailDelete {
    padding: 0;
    padding-left: 20px;
    background: #fff url('../../../assets/delete.png') no-repeat center left;
}

.distributionPercentageHint {
    background-color: rgba(72, 109, 144, 1);
    height: 60px;
    padding: 17px 0px 0px 30px;
    margin: 23px 0 40px;
}
.distributionPercentageHint p {
    color: #ffffff;
    font: 800 22px/25px benton-sans, yorkten-slab-normal, sans-serif;
}
.benBtnWrapper .addAnotherFieldBtn {
    margin-bottom: 30px;
}

.marketpersonalIfoFormWrapper .addressFields .container-fluid .col-sm-5,
.marketpersonalIfoFormWrapper .addressFields .container-fluid .col-sm-7 {
    padding: 0;
}
.marketpersonalIfoFormWrapper .addressFields .container-fluid .col-sm-7,
.marketpersonalIfoFormWrapper .dropdownRow .col-sm-5 {
    max-width: 445px;
}
/* .marketpersonalIfoFormWrapper .addressFields .container-fluid .col-sm-7 {
    flex-basis: 61%;
} */
.marketpersonalIfoFormWrapper .addressFields .container-fluid label.col-sm-5 {
    flex-basis: 38.55%;
}

.marketpersonalIfoFormWrapper .form-group.row,
.marketpersonalIfoFormWrapper .dropdownRow.row,
.marketpersonalIfoFormWrapper .datePickerRow.row {
    margin-bottom: 30px;
}
.marketpersonalIfoFormWrapper .datePickerRow input[name='dateofBirth'] ~ small.errorMsgInputStyle {
    width: 150%;
}

.beneficiaryForm .datePickerRow input[name='beneficiaryDOB'] ~ small.errorMsgInputStyle {
    width: 150%;
}
/* #MilitaryInformation .datePickerRow {
    width: 150%;
} */
.marketpersonalIfoFormWrapper #upload {
    background-color: transparent;
    border: none;
    padding: 0;
}
.centarizeLabel {
    display: flex;
    align-items: center;
}
.sameAddressDiv {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    margin-top:10px;
}

.sameAddressSpan {
    width: 20%;
    display: flex;
    flex-direction: column;
}

/* .errorSameAddressDiv {
    width: 200%;
} */

.genderRenderStyle {
    display: flex;
    width: 200px;
    justify-content: space-between;
    transform: translate(0px, -10px);
}

.citizenshipRenderStyle {
    display: flex;
    width: 200px;
    justify-content: space-between;
    transform: translate(0px, 1px);
}

.pepQuestionLabel {
    padding-bottom: 30px;
    font-family: benton-sans, sans-serif;
    font-size: 16px;
    font-weight: 600;
}

/* #seniorPoliticalFigureName {
    width: 450px;
} */

.foreignPoliticalFigure {
    display: flex;
    flex-direction: column;
}

.pepQuestionRadio {
    display: flex;
    justify-content: space-around;
    width: 200px;
    flex-direction: row;
}

.planDescriptionLink {
    color: rgba(0, 74, 152, 1);
}

.planDescriptionLink:hover {
    background: rgba(0, 74, 152, 1);
    color: #ffffff;
}

.dzu-previewContainer:nth-of-type(2) {
    display: none;
}

@media screen and (max-width: 767px) {
    .pepQuestionRadio {
        flex-direction: column;
    }
    .accMngtContainer .retirementHeadading
    {
        padding-top: 15px ;
    }
    .indivitualAccTypePage .rwdBtnWrapper .mobileNext .container .mobileRow .CancelBtnMobile 
    {
        order: 3;
    }
    .indivitualAccTypePage .rwdBtnWrapper .mobileNext .container .mobileRow .backbtnMobile 
    {
        order: 4;
    }
    .indivitualAccTypePage .rwdBtnWrapper .mobileNext .container .mobileRow .savebtnMobile 
    {
        order: 2;
        margin-bottom: 15px;
    }
    .indivitualAccTypePage .rwdBtnWrapper .mobileNext .container .mobileRow .nextBtnMobile
    {
        order: 1;
        margin-bottom: 15px;
    }
    .indivitualAccTypePage .rwdBtnWrapper .mobileNext .container .mobileRow 
    {
        justify-content: flex-start !important;
    }
    .planBeneficiary .savebtnMobile, .confirmPage .savebtnMobile {
        order: 2;
        margin-bottom: 15px;
    }
    #MilitaryInformation .serviseDates
    {
        flex-direction: column;
    }
    .emailGroup
    {
        margin-bottom:0 !important;
    }
    .serviseDates .datePickerRow.row {
        max-width: 100%;
        margin-right: 0;
    }
    .mobileAddressValueCol > div >button:first-child
    {
        margin-left:0 !important;
    }
    #physicalPhysicalAddressLine20inputErrorText
    {
        position:relative !important;
    }
    #physicaluspsSuggestedCardId
    {
        padding:15px 15px 15px !important;
    }
    div[role="radiogroup"]
    {
        flex-direction: column;
    }
    div.topleftCornerBorder hr 
    {
        display: none !important;
    }
    
}
input.prefilled{
    border: 1px solid rgb(210, 210, 210);
}



/* personalInfoTooltip  */
.Tooltip{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 80%;
    top: -70px
}

.TooltipForMilitary{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 24%;
    top: -72px
}

.TooltipForJointMilitary{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 38%;
    top: -70px
}

.TooltipForEmployment{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 29%;
    top: -72px
}

.TooltipForJointEmployment{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 43%;
    top: -70px  
}


.TooltipForBenificiary{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 16%;
    top: -94px
}

@media screen and (max-width: 820px) {
   .TooltipForBenificiary{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 16%;
    top: -65px
}
}
@media screen and (max-width: 820px) {
   .TooltipForJointEmployment{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 80%;
    top: -75px
}
}
@media screen and (max-width: 1024px) {
   .TooltipForEmployment{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 85%;
    top: -75px
}
}
@media screen and (max-width: 960px) {
   .TooltipForJointMilitary{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 80%;
    top: -75px
}
}

@media screen and (max-width: 1024px) {
   .TooltipForMilitary{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 85%;
    top: -75px
}
}

@media only screen and (max-width: 540px) and (min-width: 375px){
   .Tooltip{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 80%;
    top: -110px
}
   .TooltipForJointMilitary{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 80%;
    top: -70px
}
 .TooltipForJointEmployment{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 85%;
    top: -70px
}
}

@media only screen and (max-width: 393px) and (min-width: 375px){
 .TooltipForJointMilitary{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 80%;
    top: -70px
}
}

@media only screen and (max-width: 360px){
    .Tooltip{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 80%;
    top: -110px
}
}
@media  screen and (max-width: 414px)and(min-width: 412px){
.TooltipForJointMilitary{
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    left: 80%;
    top: -20px
}
}