@media screen and (max-width: 767px) {

    .withDrawalForm > div
    {
        padding: 1rem;
        display: block;
    }

    .withDrawalForm > div :nth-child(2)
    {
        display: block;
    }

}
