.verifyAuthPref1 {
    /* font: 600 14px/20px 'benton-sans' , sans-serif; */
    margin-bottom: 0;
    margin-top: 11px;
    /* display: inline-block; */
}

.personalDetailsFormDiv input[name='otpIvrSelect'] + label > div {
    display: none;
}

.personalDetailsFormDiv .form-group {
    padding-bottom: 0;
    margin-bottom: 5px;
}

.personalDetailsFormDiv .form-group .wradio > span {
    margin-bottom: 20px;
}