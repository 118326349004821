@media screen and (max-width: 767px) {
    .dateRangeLabel {
        max-width: 100% !important;
        flex: auto;
    }
    .labelMargin {
        margin-top: 30px !important;
    }
    .filterButton {
        width: 100% !important;
        height: 50px !important;
    }
    .formLabel {
        flex-basis: auto !important;
    }
    .formLabel span {
        font-size: 16px !important;
    }
}