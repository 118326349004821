#KJEAllContent {
    background: #ffffff;
    color: #000000;
    font-family: benton-sans, yorkten-slab-normal, sans-serif;
    text-align: justify;
}

.KJEFontHeading {
    color: #000000;
}

.KJEError {
    color: #bb0000;
}

.KJETitle {
    background-image: none;
}

h3 {
    color: #486d90;
}

/* Please use the following styling if needed. These are site specific styling from KJESiteSpecific.css */

.KJECalculatorTable {
    /* styling added for eslint error - no empty styling */
    border: 0px solid #ffffff;
}

.KJEReportTitle {
    /* styling added for eslint error - no empty styling */
    border: 0px solid #ffffff;
}

.KJEReportTitleBlock {
    /* styling added for eslint error - no empty styling */
    border: 0px solid #ffffff;
}

.KJEWidthConstraint {
    background: #ffffff;
}

.KJEWrapper {
    background: #ffffff;
}

body {
    margin: 0;
}

/**CUSTOMCSS**/
.KJEFooter {
    display: none;
}

#KJEDefinitionsDL p.KJEDefinition,
#KJEAllContent p.KJEDefinition {
    text-align: justify;
}
