.compare-funds-wrapper .tableheader {
    height: 70px;
    vertical-align: middle;
    text-align: center;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #cecece;
    opacity: 1;
}

.compare-funds-wrapper .table .fund-heading {
    /* background-color:white; */
    /* text-align:center; */
    border-top: none;
    /* text-align: left; */
    font: Bold 16px/19px 'benton-sans', sans-serif;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    height: 70px;
    vertical-align: middle;
    text-align: center;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #cecece;
    /* opacity: 1; */
}

.compare-funds-wrapper .table .default-keys {
    text-align: left;
    vertical-align: middle;
    font: Bold 18px/22px 'yorkten-slab-normal', sans-serif;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    border-bottom: none;
    background-color: #ffffff;
}

.default-key-item {
    text-align: right;
    border: none;
}

.backIcon {
    display: inline;
}

.backIcon img {
    height: 32px;
    width: 32px;
    cursor: pointer;
}

.default-row {
    background-color: white;
    border-bottom: 1px solid #cecece;
}

.table .fund-key {
    text-align: right;
    border-top: none;
    border-bottom: none;
    color: #333333de;
    border-right: 1px solid #cecece;
    font: Bold 16px/19px 'benton-sans', sans-serif;
    background-color: #cecece;
}

.table .fund-key-item {
    vertical-align: middle;
    border-top: none;
    border-bottom: none;
    text-align: left;
    font: 600 16px/19px 'benton-sans', sans-serif;
    letter-spacing: 0;
    color: #333333de;
    opacity: 1;
    background-color: #ffffff;
    padding-left: 30px;
    border-right: 1px solid #cecece;
}

.fund-subtext {
    text-align: left;
    font: 600 14px/22px 'benton-sans', sans-serif;
    letter-spacing: 0;
    color: #004a98;
    opacity: 1;
}

.table .rating {
    text-align: center;
    border-top: none;
    vertical-align: middle;
}

.table .not-rating {
    font: 500 16px/22px 'benton-sans', sans-serif;
    letter-spacing: 0;
    color: #56565a;
    opacity: 1;
    text-align: center;
    vertical-align: middle;
    border-top: none;
}
.compare-funds-wrapper {
    background-color: white;
    min-height: 100vh;
    position: relative;
}
.fund-container {
    color: #5d83ae;
    padding-top: 30px;
    font: Bold 18px/35px 'Roboto Slab', sans-serif;
}
.fund-compare {
    font: Bold 22px/35px 'Roboto Slab', sans-serif;
    color: #56565a;
    margin-top: 18;
    font-size: 26;
}
.fund-line {
    margin-bottom: 54;
}
.backto {
    text-align: left;
    font: 600 16px/22px 'benton-sans', sans-serif;
    letter-spacing: 0;
    color: #004a98;
    opacity: 1;
    background: none;
    border: none;
}

.compare-funds-wrapper tbody td {
    height: 60px;
}

.compare-funds-wrapper thead th:first-child {
    border-left: none;
}

.compare-funds-wrapper .table thead th:last-child {
    border-right: none;
}

.odd {
    background-color: #ffffff;
}

.even {
    background-color: #f4f7f8;
}

.compare-funds-wrapper .table {
    border-bottom: 1px solid #cecece;
    margin-bottom: 50px;
}
