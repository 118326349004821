.saveDevice.checked label::before {
    content: '';
    display: block;
    border: solid #4F799F;
    border-width: 0 2px 2px 0;
    transform: rotate(36deg);
    position: absolute;
    background-color: #fff;
    width: 8px;
    height: 15px;
    top: 1px;
    left: -26px;
}

.saveDevice input[type="checkbox"] {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #004A98;
    height: 25px;
    width: 25px;
    margin-right: 10px;
}