#account-registration .memberMsgContainer {
    padding: 55px 0 30px;
    text-align: center;
}

#account-registration .accountLocked .pageHeading span {
    padding: 0 60px;
    background: url('../../../assets/icon-lock.png') no-repeat center left;
}
#account-registration .pageHeading {
    font: 800 42px/44px yorkten-slab-normal;
    letter-spacing: 0;
    color: #486D90;
    margin-bottom: 15px;
    border-bottom: 0;
}

#account-registration .explanationPara {
    font: 500 18px/26px benton-sans, sans-serif;
    letter-spacing: 0.36px;
    color: rgba(73, 73, 74, 1);
    margin-bottom: 26px;
    text-align: center;
}

#account-registration .helpText {
    font: 600 18px/23px benton-sans, sans-serif;
    letter-spacing: 0;
    color: #56565A;
    margin-bottom: 55px;
}
#account-registration .detailsWrapper.row  {
    max-width: 376px;
    margin: 0 auto; 
}

#account-registration .helpNumber.col-12{
    justify-content: center;
    display: flex;
    margin-bottom: 40px;
    padding: 0;
}
#account-registration .helpNumber a {
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    background: url('../../../assets/iconPhone.png') no-repeat top left;
    font: 600 36px/20px benton-sans, sans-serif;
    letter-spacing: 0;
    color: rgba(0, 74, 152, 1);
    margin: 0;
    text-align: left;
    height: 50px;
    justify-content: center;
}
#account-registration .helpNumber p span {
    font: 500 16px/26px benton-sans, sans-serif;
    color: #49494A;
}

#account-registration .helpNumber p span b {
    font-weight: 600;
    margin-right: 3px;
}

#account-registration .helpNumber span{
    font: italic 500 18px/26px benton-sans, sans-serif, sans-serif;
    color: #004A98;
}

#account-registration .detailsWrapper .chartCard {
    text-align: left;
    padding: 25px 55px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
}
#account-registration .chartTopSection span,
#account-registration .chartBottomSection span,
#account-registration .chartTopSection .chartSectiondHeading{
    display: block;
    color: #49494A;
    padding: 0;
    border:none;
}
#account-registration .chartTopSection span.todayOpenState {
    font: 500 18px/26px benton-sans, sans-serif;
    border-bottom: 2px solid rgba(139, 193, 5, 1);
    display: inline-block;
    margin-bottom: 16px;
}
#account-registration .chartTopSection .displayFlex {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid rgba(210, 210, 210, 1);
}
#account-registration .chartTopSection .labelSpan{
    font: 600 16px/26px benton-sans, sans-serif;
    margin-right: 10px;
    padding-bottom: 0;
    border-bottom: 0;
    margin-bottom: 0;
}

#account-registration .chartTopSection .textSpan {
    font: 500 16px/26px benton-sans, sans-serif;
}
#account-registration .chartTopSection div:not(.displayFlex):not(:last-child) {
    margin-bottom: 22px;
}
#account-registration .chartSectiondHeading {
    margin-bottom: 16px;
    font: 800 23px/30px benton-sans;
}

#account-registration .telephone, #account-registration .telephone:hover {
    color: rgba(0, 74, 152, 1);
    text-decoration: none;
}
#account-registration .identityCheckFail .pageHeading {
    color: rgba(230, 0, 20, 1);
    max-width: 88px;
    height: 88px;
    border: 1px solid rgba(230, 0, 20, 1);
    border-radius: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto 42px;
}
#account-registration .identityCheckFail > div > p {
    color: rgba(72, 109, 144, 1);
    font: 800 26px/44px yorkten-slab-normal;
}
#account-registration .identityCheckFail p.explanationPara {
    margin-bottom: 0;
}
#account-registration .closeChartIcon {
    background: url('../../../assets/btn-x-close.png') no-repeat center left;
    color: transparent;
    position: absolute;
    right: 16px;
    top: 16px;
    height: 50px;
    width: 50px;
    user-select: none;
}
@media only screen and (max-width:767px) {
    #account-registration .memberMsgContainer {
        padding: 30px 15px 130px;
    }
    #account-registration .pageHeading {
        font-size: 26px;
        line-height: 30px;
        margin: 0 25px 30px;
        padding-bottom: 5px;
        border-bottom: 2px solid #8BC105;
    }
    #account-registration .helpNumber p {
        font-size: 20px;
        max-width: 210px;
    }
    #account-registration .helpNumber p span {
        white-space: nowrap;
    }
    #account-registration .helpNumber span {
        font-size: 16px;
    }
    #account-registration .detailsWrapper .chartCard {
        padding: 25px 15px;
        margin: 0 -15px;
        flex-basis: calc(100% + 30px);
        max-width: unset;
    }
    #account-registration .chartTopSection .textSpan {
        font-size: 14px;
    }
    #account-registration .accountLocked .pageHeading span {
        padding: 0 0 0 40px;
    }
    #account-registration .accountLocked .pageHeading {
        border-bottom: none;
    }
}
