/* The checkboxcontainer */
#account-registration .checkboxcontainer {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  #account-registration .checkboxcontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  #account-registration .checkboxcontainer.disabled input{
    cursor: default;
  }
  #account-registration .checkboxcontainer.disabled .checkmark{
    cursor: default;
    background-color: lightgrey;
  }
  
  /* Create a custom checkbox */
  #account-registration .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 30px;
    width: 30px;
    border: solid 1px #004A98;
  }
  
  /* On mouse-over, add a grey background color */
  /*.checkboxcontainer:hover input ~ .checkmark {
    background-color: #ccc;
  }*/
  
  /* When the checkbox is checked, add a blue background */
  /*.checkboxcontainer input:checked ~ .checkmark {
    background-color: #2196F3;
  }*/
  
  /* Create the checkmark/indicator (hidden when not checked) */
  #account-registration .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  #account-registration .checkboxcontainer input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  #account-registration .checkboxcontainer .checkmark:after {
    left: 12px;
    top: 8px;
    width: 6px;
    height: 10px;
    border: solid #004A98;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }