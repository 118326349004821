.label-key {
    font: 600 16px/20px benton-sans, yorkten-slab-normal, sans-serif;
    color: rgba(73, 73, 74, 1);
    letter-spacing: 0;
    opacity: 1;
    margin-top: 3.5px;
}
.label-value {
    font: 500 16px/20px benton-sans, yorkten-slab-normal, sans-serif;
    letter-spacing: 0;
    opacity: 1;
}
.summary-heading {
    font: 700 18px/25px benton-sans, yorkten-slab-normal, sans-serif;
    letter-spacing: 0;
    color: rgba(73, 73, 74, 1);
    opacity: 1;
    margin-bottom: 2px;
}
.summary-list {
    font: 500 16px/22px benton-sans, yorkten-slab-normal, sans-serif;
    color: rgba(73, 73, 74, 1);
    letter-spacing: 0;
    opacity: 1;
}
.summary-radio {
    padding-bottom: 30px;
    /* padding-left:15px; */
    max-height: 98px;
}
.summary-radio-desc {
    margin-left: 55px;
}
.radio-dec-item {
    text-align: left;
    font: 500 14px/35px benton-sans, yorkten-slab-normal, sans-serif;
    letter-spacing: 0;
    color: rgba(73, 73, 74, 1);
    opacity: 1;
}
.radio-dec-item-esign {
    text-align: left;
    font: 500 14px/35px benton-sans, yorkten-slab-normal, sans-serif;
    letter-spacing: 0;
    color: rgba(73, 73, 74, 1);
    opacity: 1;
    margin-bottom: 10px;
}
.radio-dec-item span {
    padding-left: 15px;
}
.summary-para {
    margin-top: 10px;
    /* padding-left:30px */
}
.summary-para-wrap {
    padding-top: 5em;
    max-width: 95%;
}
.summary-link {
    text-align: center;
    font: 800 16px/20px yorkten-slab-normal, benton-sans, sans-serif;
    letter-spacing: 0;
    color: rgba(0, 74, 152, 1);
    opacity: 1;
    border: none;
    padding: 0;
    background: none;
}
.summary-val {
    margin-top: 2px;
    margin-left: 10px;
}
.link-sum-desc {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font: 400 16px/19px benton-sans, yorkten-slab-normal, sans-serif;
    letter-spacing: 0;
    color: rgba(73, 73, 74, 1);
    opacity: 1;
    padding-bottom: 15px;
    margin-right: 25px;
}
.summary-content {
    position: relative;
    padding: 40px 0 0;
}
.main-content-wrap {
    justify-content: flex-start;
    margin-right: 0;
    align-items: center;
}
.sum-content-wrap {
    justify-content: space-between;
    margin-right: 0;
}
.sum-content-wrap img {
    width: 19px;
    height: 19px;
    cursor: pointer;
}
.sum-content-wrap .sum-prelink img {
    margin-right: 5px;
    width: 15px;
    height: 15px;
    cursor: pointer;
}
.sum-content-wrap .col {
    padding: 0;
    margin-top: 0;
}
.sum-heading {
    text-align: left;
    font: 800 22px/45px yorkten-slab-normal, benton-sans, sans-serif;
    letter-spacing: 0;
    color: #4f799f;
    opacity: 1;
    padding-right: 10px;
}
.esign-prelink {
    text-align: right;
    font: 600 16px/20px benton-sans, yorkten-slab-normal, sans-serif;
    letter-spacing: 0;
    color: #49494a;
    /* padding-left: 30px; */
    padding: 0 0 0 30px;
}
.sum-prelink {
    padding-top: 15px;
    text-align: right;
    color: #5d83ae;
}
.sum-prelink .row {
    justify-content: flex-end;
}
.sum-ruler {
    margin-top: 0px;
}
.sum-sh {
    font: 500 16px/22px benton-sans, yorkten-slab-normal, sans-serif;
    color: rgba(73, 73, 74, 1);
    margin-bottom: 25px;
}
.sum-card-wrap {
    /* padding-top:15px;
    padding-bottom:20px; */
    background-color: #ffffff;
}
.verify .sum-card-wrap > * {
    padding-bottom: 10px;
}
.Slabel .container-fluid {
    flex-basis: 10%;
    margin-left: 0;
    padding-left: 0;
    margin-right: 1%;
}
.Slabel .container-fluid label {
    flex-basis: unset;
    width: unset;
    margin-bottom: 16px;
    font-weight: normal;
}

.radio-label {
    font: 700 16px/19px benton-sans, yorkten-slab-normal, sans-serif;
    color: rgba(73, 73, 74, 1);
    letter-spacing: 0;
    opacity: 1;
    height: 76px;
    width: 254px;
    margin-right: 70px;
    margin-left: 0;
}

.per-child {
    padding-bottom: 30px;
}

.per-child .label-key {
    width: 254px;
    margin-right: 70px;
    flex: none;
}

.per-child #seniorPoliticalFigure label {
    min-width: 254px;
    margin-right: 70px;
    flex: none;
}

.document-heading {
    justify-content: space-between;
}

.document-heading > * {
    flex: 1 6 10;
}

.document-heading h5 {
    text-align: left;
    font: 700 16px/19px benton-sans, yorkten-slab-normal, sans-serif;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
    margin-right: 10px;
    margin-left: 0px;
}

.main-docs {
    text-align: left;
    font: 800 18px/24px yorkten-slab-normal, benton-sans, sans-serif;
    letter-spacing: 0;
    color: #49494a;
    opacity: 1;
}

.sum-linktext {
    padding: 0;
    background: none;
    border: none;
    text-align: right;
    font: 800 15px/20px yorkten-slab-normal, benton-sans, sans-serif;
    letter-spacing: 0;
    color: #004a98;
}

.summary-content .pdfIcon img {
    width: 18px;
    height: 21px;
    padding-right: 1px;
}

.verify .contactHint {
    text-align: left;
    font: 400 14px/22px benton-sans, yorkten-slab-normal, sans-serif;
    letter-spacing: 0;
    color: rgba(73, 73, 74, 1);
    opacity: 0.65;
    margin: 0;
}

.verify .contactAddress {
    text-align: left;
    font: 600 16px/22px benton-sans, yorkten-slab-normal, sans-serif;
    letter-spacing: 0;
    color: rgba(73, 73, 74, 1);
    opacity: 1;
    margin-left: 0;
}

.verify .phy-heading {
    text-align: left;
    font: 700 16px/24px benton-sans, yorkten-slab-normal, sans-serif;
    letter-spacing: 0;
    color: rgba(73, 73, 74, 1);
    opacity: 1;
    margin-bottom: 12px;
}

.verify .mailAddress {
    margin-bottom: 42px;
}

.verify .usps {
    margin-left: 43px;
}

/* .verify .phyadd2 {
    margin-left:43px
} */

.verify .contactwrap {
    display: flex;
    flex-direction: column;
}
.verify .summary-content .accordionHeader {
    line-height: 0;
}
.verify .summary-content.accordion {
    padding-bottom: 10px;
}
.checkBoxWrapper input[type='checkbox']:checked:after {
    width: 8px;
    height: 16px;
    top: 4px;
    left: 10px;
}
