#account-registration .anchorSpan {
    display: inline-block;
}

#account-registration .anchorSpan .inlineLInk {
    display: block;
}

#account-registration #emailFld.verifyDetailsText{
    display: block;
}
#account-registration .emailFldBtn{
    display: block;
    float: right;
}
#account-registration .verifyDeatilsRow .verifyDetailsLabel {
    max-width: 30%;
}
#account-registration .verifyDeatilsRow .verifyDetailsText {
    max-width: 70%;
    width: 100%;
}
#account-registration .verifyDetailsText input {
    width: 100%;
    max-width: 100%;
}

#account-registration .verifyDetailsText .email input {
    width: 100%;
    max-width: 100%;
}

#account-registration .editablephonerow .verifyDetailsLabel {
    margin-top: auto;
    margin-bottom: auto;
}

#account-registration .editableemailrow .verifyDetailsLabel {
    margin-top: 15px;
}

#account-registration .verifyDetailsText .email button {
    float: right;
    width: 40%;
    margin-top: 10px;
}

@media only screen and (max-width: 767px) {
    #account-registration .verifyDeatilsRow .verifyDetailsLabel {
        max-width: 33%;
    }

    #account-registration .verifyDeatilsRow .verifyDetailsText {
        max-width: 67%;
    }

    #account-registration .verifyDetailsText .email button {
        width: 70%;
    }
}

#account-registration .fieldTitle {
    font: 0.875em benton-sans-medium;
    margin-top: 1em;
}

@media only screen and (min-width: 1023px) {
    #account-registration .btnContainer {
        margin-left: auto;
        width: 80%;
    }
}