
#account-registration .passcodePadding+.resendBtnPadding {
    padding-left: 15px;
    max-width: calc(100% - 163px);
}

@media (min-width:768px) {
    #account-registration #passcodeTitle {
        white-space: nowrap;
    }
}

@media (max-width:1024px) {
    #account-registration p.errorText {
        width: 100%;
    }
}

#account-registration #passcodeTitle {
    font: 700 18px/24px benton-sans;
    letter-spacing: 0;
}

#account-registration .OTPVerify .descWrapperHeading {
    font-size: 30px;
}

#account-registration .OTPVerify .descWrapper p {
    margin-bottom: 0px;
}

#account-registration .OTPVerify .descWrapper span.passcodeSubParag {
    font-size: 0.75em;
}

#account-registration .OTPVerify .RHSWrapper,
#account-registration .OTPVerify .RHSWrapper input[type=password] {
    max-width: 100%;
}

#account-registration .OTPVerify .RHSWrapper .passCodeWrapper {
    display: block;
}

#account-registration .passCodeSent {
    float: right;
}

#account-registration .OTPVerify .inputWrapper.passCode {
    margin-bottom: 5em;
}


@media only screen and (max-width:767px) {
    #account-registration .OTPVerify .backLink .vcmBackButton.btn {
    padding-left: 30px;
    }
}