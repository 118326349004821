#account-registration #vcm_name,
#account-registration #vcm_date{
 box-shadow: none;
 border: 0px;
 border-bottom: 1px solid;
 padding: 10px;
 color: rgba(0, 0, 0, 1);
}

#account-registration #vcm_name{
 width:50%;
}

#account-registration #vcm_date{
 width: 8.125em;
 border-bottom: none;
 background: none;
}

#account-registration .announceLabel{
 margin-top: 0.5em;
 width:65%;
 font: 1em/1.6em benton-sans;
}
#account-registration .signContanier  #errorMessage {
    width: 70%;
}
#account-registration .privacyPolicySpan{
    display: block;
    float: left;
    /* margin-top: 20px; */ /* this created issue VQ-5952 */
}

#account-registration #disclaimer_agreement {
    margin-bottom: 20px; /* solution for issue VQ-5952 */
}

#account-registration .privacyPolicyLink{
    font-weight: 600;
}
@media only screen and (max-width: 767px) {
 #account-registration .announceLabel{
  display: block;
  width: auto;
 }
 #account-registration #vcm_name{
    width: 60%;
 }
}

#account-registration .pdfImage{
    margin-left: 10px;
}

#account-registration .dateLblContainer{
    margin-left: 2.5em;
}

#account-registration .dateTxtContainer{
    margin-left: 2em;
}


@media only screen and (max-width: 1023px) {
    #account-registration .dateLblContainer,
    #account-registration .dateTxtContainer{
        margin-left: 0;
    }

    #account-registration .dateTxtContainer #vcm_date{
        padding-left: 0px;
    }
}

@media only screen and (max-width: 767px) {
    #account-registration .dateComp{
        margin-top: 20px;
    }
}
#account-registration .linkCls{
    color: #004a98;
    text-decoration: underline;
    font: 500 18px/26px benton-sans,sans-serif;
    word-break: break-word;
}

#account-registration .modal-backdrop.show.pdfModal{
    z-index: 1040;
}
