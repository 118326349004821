.detailedCradWrapper {
    width: 100%;
    background-color: rgba(250, 250, 250, 1);
    border: 2px solid rgba(250, 250, 250, 1);
    outline: 1px solid rgba(210, 210, 210, 1);
    margin-bottom: 40px;
    position: relative;
    text-align: left;
    padding: 0;
}
   
@media (max-width:767px ){
    /* .pointers-hidden {
        pointer-events: none;   
    } */
    .reduce-top-of-sep-ira {
        margin-top: -20px;
    }
    .detailedCradWrapper .mobileWicon
    {
        position: absolute;
        right: 14px;
        top: 10px;
    }
    .detailsList li::before {
        height: 8px !important;
        width: 20px !important;
        margin-top: 5px;
    }
    .detailedCradWrapper::before {       
        top: -2px !important;
        left: -2px !important;
    }
    .accMngtContainer .accListAlign:nth-child(2)>h2
    {
        display: none;
    }
}

@media (min-width:768px ){
    .detailedCradWrapper:hover {
        outline: 1px solid rgba(60, 90, 119, 1);
    } 
}

.detailedCradWrapper.selected {
    border: 2px solid rgba(0, 74, 152, 1);
    outline: 1px solid rgba(0, 74, 152, 1);
}

.detailedCradWrapper.selected svg {
    color: rgba(0, 74, 152, 1) !important;
}

.detailedCradWrapper::before {
    content: '';
    width: 0;
    height: 0;
    border-bottom: 35px solid transparent;
    border-left: 35px solid rgba(210, 210, 210, 1);
    position: absolute;
    top: 0px;
    left: 0px;
}

.detailedCradWrapper.selected::before {
    border-left: 35px solid rgba(140, 191, 38, 1);
}

.detailedCradWrapper .row {
    margin-left: 0;
    margin-right: 0;
}

.detailedCradWrapper .headerRow {
    padding: 23px 32px 21px 40px;
    justify-content: space-between;
    border-bottom: 1px solid rgba(210, 210, 210, 1);
}

.detailedCradWrapper .headerRow h3 {
    font: 800 18px/22px yorkten-slab-normal, sans-serif;
    color: rgba(72, 109, 144, 1);
    padding-left: 10px;
}

.detailedCradWrapper .headerRow .completionTxtSty {
    font: 600 14px/20px benton-sans, sans-serif;
    color: rgba(86, 86, 90, 1);
}

.detailedCradWrapper .headerRow span.cardheader {
    font: 800 18px/22px yorkten-slab-normal,sans-serif;
    color: #486d90;
}

.detailedCradWrapper .descriptionRow {
    font: 500 16px/20px benton-sans, sans-serif;
    color: rgba(73, 73, 74, 1);
    padding: 30px 0 30px 30px;
}

.detailedCradWrapper .descriptionRow .descriptionCol {
    min-width: 60%;
    padding-left: 20px;
    padding-right: 0;
}

.detailedCradWrapper .descriptionRow .descriptionCol:first-child {
    min-width: 40%;
    border-right: 1px solid rgba(210, 210, 210, 1);
    padding-left: 0;
    padding-right: 20px;
}

.detailedCradWrapper .descriptionCol span {
    font-weight: 600;
    margin-bottom: 26px;
    display: inline-block;
}

.detailsList {
    list-style: none;
    padding-left: 0;
    margin-bottom: 31px;
}

.detailsList li {
    margin-bottom: 24px;
}

.detailsList li::before {
    content: '';
    border-left: 3px solid rgba(139, 193, 5, 1);
    border-bottom: 3px solid rgba(139, 193, 5, 1);
    height: 8px;
    width: 13px;
    display: inline-block;
    transform: rotate(-45deg);
    margin-right: 11px;
    margin-bottom: 2px;
}

.detailedCradWrapper .headerRow span>div {
    margin-left: 30px;
    display: inline-block;
}

.detailedCradWrapper.selected .headerRow {
    padding-right: 20px;
}

.accountTypePage .alertWrapper {
    margin-top: 2.5rem;
}

@media screen and (min-width: 768px) {
    /* TODO: use just showOnMobile instead of lt.. */
    .lt-767 {
        display: none !important;
    }
    .showOnMobile {
        display: none !important;
    }
}

@media screen and (max-width: 767px) {
    .showOnMobile {
        display: inline-block !important;
    }
    .gt-767 {
        display: none !important;
    }
    .lt-767 {
        display: inline-block !important;
        white-space: nowrap;
        overflow-x: auto;
        margin-left: 0px !important;
        font-size: 14px;
        /* VD says 16. but then cannot accommodate the tickmark. */
        line-height: 24px;
    }
    .detailedCradWrapper::before {
        content: '';
        width: 0;
        height: 0;
        border-bottom: 20px solid transparent;
        border-left: 20px solid rgba(210, 210, 210, 1);
        position: absolute;
        top: 0px;
        left: 0px;
    }
    .detailedCradWrapper.selected::before {
        border-left: 20px solid rgba(140, 191, 38, 1);
    }
    .detailedCradWrapper .headerRow {
        padding: 0px 0px 20px 0px;
        margin: 21px;
        display: block;
    }
    .detailedCradWrapper .headerRow h3 {
        float: left;
        padding-left: 0;
        font-size: 20px;
    }
    .detailedCradWrapper .headerRow label {
        background: none !important;
        text-align: left;
    }
    .detailedCradWrapper .descriptionRow {
        display: block;
        padding: 0px 21px 0px 21px;
        font-size: 18px;
        line-height: 24px;
        font-family: benton-sans;
    }
    .detailedCradWrapper .descriptionRow .descriptionCol:first-child {
        border-right: none;
        font-size: 18px;
    }
    .detailedCradWrapper .descriptionRow .descriptionCol {
        padding-left: 0;
        padding-right: 0;
    }

   .descriptionList {
       padding-top: 30px;
       font-size: 18px;
   }
   .detailedCradWrapper .descriptionCol span {
       margin-bottom: 20px;
   }
   .detailsList li {
       display: flex;
   }
   .detailedCradWrapper .headerRow .completionTxtSty {
       font-size: 14px;
       color: #49494A;
   }
   .accListAlign {
       margin-bottom: 0px !important;
   }

   .accountTypePage .accMngtContainer
   {
       padding: 0px !important;
   }
   .accMngtContainer
   {
       padding: 0px !important;
   }

   .accountTypePage .accMngtContainer .accMgntHeading h1
   {
       padding-top: 0 !important;
       margin-bottom: 15px !important;
   }
   .accountTypePage .accMngtContainer #breadcrumb
   {
       padding-left: 15px;
   }
   .mobileRow 
   {
     margin: 0 !important;
   }
    .mobileRow input.form-control {
        margin: 0 !important;
        font-size: 16px;
    }
    .mobileCol {
        padding: 0 !important;
    }
    .mobileCol .verifyAuthPref1 {
        margin-top: 0;
        color: #49494a !important;
        font-size: 16px;
    }
    .mobileHeaderWidth,
    .mobileHeaderWidth h1 {
        width: 100% !important;
    }
    .mobileHeaderCont {
        display: block !important;
    }
    .mobileCol .form-group {
        display: block !important;
        width: 100% !important;
    }
    .mobileCol .form-group span,
    .personalDetailsFormDiv .form-group .wradio>span {
        margin-bottom: 0 !important;
        line-height: 18px !important;
        font-size: 16px !important;
    }
    .mobilePaddingTop {
        padding-top: 40px !important;
    }
    .responsive-fix>.mobileMarginTop {
        margin-top: 50px !important;
    }
    .custom-button-container>.mobileMarginTop {
        margin-top: 20px !important;
    }
    .custom-button-container .col-4,
    .custom-button-container .col-8 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .custom-button-container .col-8 button {
        float: none !important;
    }
    .mobilePrefix {
        padding-top: 20px !important;
    }
    .cardMobileClass {
        padding: 1.5em 1em !important;
    }
    .cardMobileClass .cardMobileMargin {
        margin: 1.5rem 0 !important;
    }
    .countryCodeMobile {
        padding-bottom: 20px !important;
    }
    .mobileView .responsiveFix-0 {
        padding-top: 30px;
    }
    .mobileView .responsiveFix-0 .mobileAlignment {
        text-align: left !important;
    }
    .responsiveFix-0.row {
        margin: 0 !important;
    }
    .mobileRowMargin {
        margin-bottom: 20px !important;
    }
    .mobileRowMargin .mobileAlignment {
        flex-basis: auto;
    }
    .mobileStyledHr:after {
        background: none !important;
        border-bottom: 1px solid #d2d2d2;
        opacity: 0.45;
    }
    .mobileStyledPara {
        padding-bottom: 30px;
    }
    .mobileStyleContact {
        font-weight: 500 !important;
        white-space: nowrap;
    }
    .signMobileStyle .inputLabel {
        font-size: 16px !important;
        line-height: 20px !important;
        padding-bottom: 10px !important;
    }
    #account-registration .signMobileStyle #vcm_name {
        width: 100% !important;
        border: 1px solid rgba(210, 210, 210, 1) !important;
    }
    .mobileFullName {
        font-size: 12px !important;
        margin-bottom: 20px !important;
    }
    .signMobileStyle .announceLabel span {
        line-height: 20px !important;
    }
    .messageBarDiv .mobileSuccessText {
        padding-left: 0 !important;
    }
    .captchaCont {
        text-align: center !important;
    }
    .mobile-cont h1:not(.currentStep) {
        display: block !important;
    }
    .checkInboxCont .mobileNext .backButton {
        margin-bottom: 0 !important;
    }
    .accountTypePage .rwdBtnWrapper .mobileNext .container .mobileRow {
        justify-content: flex-start !important;
    }
    .accountTypePage .rwdBtnWrapper .mobileNext .container .mobileRow .CancelBtnMobile {
        order: 3;
    } 
    .accountTypePage .rwdBtnWrapper .mobileNext .container .mobileRow .backbtnMobile {
        order: 4;
    }
    .accountTypePage .rwdBtnWrapper .mobileNext .container .mobileRow .savebtnMobile {
        order: 2;
        margin-bottom: 15px;
    }
    .accountTypePage .rwdBtnWrapper .mobileNext .container .mobileRow .nextBtnMobile {
        order: 1;
        margin-bottom: 15px;
    }
}
.noteText{
    color: #000;
    font-style: italic;
    font-size: 14px;
    font-weight: 600;
}
.BlueTextInAccountType{
    color:rgba(0, 74, 152, 1);
    cursor: pointer;
}
.BlueTextInAccountType:hover{
    text-decoration:underline;
}