.manageBen .beneficiaryHeader {
    border-bottom: none;
    padding: 0;
}
.manageBen .beneficiaryHeader .beneficiaryHeaderCol .headerval {
    color: rgba(72, 109, 144, 1);
    font: 700 14px/25px benton-sans, sans-serif;
}
.manageBen .beneficiaryHeader .beneficiaryHeaderCol .headerkey {
    font: 600 14px/20px benton-sans, sans-serif;
    color: rgba(86, 86, 90, 1);
}
.manageBen .beneficiaryHeader .beneficiaryHeaderCol {
    border: none;
    margin-left: 30px;
    padding: 18px 15px 17px 16px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    min-width: 160px;
}
.manageBen .beneficiaryHeader .beneficiaryHeaderCol::after {
    content: '';
    width: 0px;
    height: 0px;
    border-bottom: 25px solid transparent;
    border-left: 25px solid rgba(139, 193, 5, 1);
    position: absolute;
    top: 0;
    left: 0;
}

.manageBen .beneficiaryHeader .beneficiaryHeaderCol:first-child {
    margin-left: 0;
    max-width: 540px;
    flex-grow: 1;
    flex-direction: row;
}
.manageBen .beneficiaryHeader .beneficiaryHeaderCol:last-child {
    margin-right: 0;
}
