.bankAccAlign {
    flex-wrap: nowrap;
}
.equalHeightCard > [class*='col-'] {
    flex-direction: column;
    margin: 15px 0;
}
.equalHeightCard > div > label {
    display: contents;
}
.equalHeightCard > div > label > div {
    display: flex;
    flex: 1 0 auto;
    width:100%;
}
.fundInvestment label {
    padding: 0;
    margin: 0;
}