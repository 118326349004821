
#account-registration .lblCls{
 width: 50%;
 display: inline-block;
}


@media (max-width:1024px) {
  #account-registration .lblCls{
  width: 60%;
 }

 #account-registration .btnCls{
  float: right;
 }
}


@media (max-width:767px) {
  #account-registration .lblCls{
  width: 65%;
 }

 #account-registration .btnCls{
  float: right;
 }
}

#account-registration .emailOTPContainer{
 margin-top: 2.5em;
 margin-bottom: 30px;
}


#account-registration .emailOTPContainer .passcodePadding{
 max-width: 100%;
}

#account-registration .securityCodeInfo{
 margin-bottom: 1.875em;
 border: 1px solid #D2D2D2;
 background-color:#FFFFFF;
 color: #4b4b4b;
 display: block;
  padding: 20px;
}

#account-registration .emailOTPContainer .passCodeWrapper label{
 width: 100%;
 margin-bottom:0 ;
}

#account-registration .emailOTPContainer .inputWrapper{
 margin-bottom:0 ;
}

#account-registration .emailOTPContainer .passCodeWrapper{
  padding-right: 0px;
}

#account-registration .emailOTPContainer .resendOtp{
  float: right;
  margin-top: 10px;
}

@media only screen and (max-width: 767px) {
  #account-registration .emailOTPContainer div.container {
  padding-right: 15px;
 }
 #account-registration .emailOTPContainer .passCodeWrapper{
    display: block;
    float: right;
  }
}

#account-registration .emailOTPContainer .errorText{
  width: 100%;
  padding-bottom: 20px;
}
#account-registration .emailContainer input{
  width: 100%;
}

#account-registration .emailContainer{
  padding-left: 0;
  padding-right: 0;
}
#account-registration .buttonContainer {
  text-align: right;
}

#account-registration .buttonContainer .vcmbtn {
  width: 8em;
  margin-top: 10px;
  height: 3em;
}

#account-registration .emailOTPContainer .inputLabel,
#account-registration .emailOTPContainer .emailPassCodeField{
  width: 100%;
}

#account-registration .emailOTPContainer .inputLabel{
  margin-bottom: 0;
}

#account-registration .verifyEmailRow .email{
  display: flex;
    flex-flow: column;
}

#account-registration .securityCodeInfo::before{
  content: '';
    width: 0px;
    height: 0px;
    border-bottom: 19px solid transparent;
    border-left: 19px solid rgba(139, 193, 5, 1);
    position: absolute;
    top: 146px;
    left: 15px;
}