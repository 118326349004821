.se-wrap {
    padding-top: 10%;
    padding-bottom: 5%;
}

.se-container {
    /* position:relative; */
    display: flex;
    margin-left: 140px;
}

.se-sec1 {
    position: absolute;
    top: 30%;
    left: 15%;
}

.se-message {
    font: 500 138px/35px 'Roboto Slab', yorkten-slab-normal, sans-serif;
    color: #544a54;
}

.se-sec2 {
    position: relative;
    margin-top: 10%;
    font-family: benton-sans, yorkten-slab-normal, sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #544a54;
}

.se-desc {
    margin-top: 1%;
    margin-right: 1%;
    margin-bottom: 1%;
    margin-left: 0;
}

.se-desc2 {
    position: relative;
    margin-top: 10%;
    font-family: benton-sans, yorkten-slab-normal, sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #544a54;
}

.se-sec3 {
    display: inline-block;
}

.se-wait {
    margin-left: 30px;
}
.se-wait2 {
    margin-left: 40px;
}
.se-img {
    height: 550px;
}
.se-img-msg {
    position: absolute;
    top: 55%;
    left: 30%;
    display: flex;
    margin-left: 140px;
    flex-direction: column;
}

.linksAlign {
    display: flex;
    flex-direction: row;
}
.se-home {
    background: none;
    border: none;
    text-decoration: underline;
    display: block;
    margin-left: 0;
}
.se-mutual {
    background: none;
    border: none;
    text-decoration: underline;
    display: block;
}
.se-search {
    background: none;
    border: none;
    text-decoration: underline;
    display: block;
}
.se-background {
    padding-top: 10%;
    padding-left: 10%;
}

.errorDescription {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    margin-top: 60px;
}

.errorBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
}
