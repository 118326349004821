#account-registration .verifyPhoneRow input.phonetxt {
 width: 100%;
}


#account-registration .phoneError {
 margin: 0px 0px 0px 34px;
}

#account-registration input[type=text].countryCode{
 width: 100%;
 padding: 10px 0px;
 text-align: center;
}
@media only screen and (max-width:1023px) {
 #account-registration input[type=text].countryCode{
  padding: 0 10px;
 }
}

#account-registration .phoneTypeRowWrapper{
 margin-top: 20px;
}

#account-registration .phoneTypeRowWrapper .inputLabel{
 margin-right: 20px;
}

#account-registration .phoneTypeSwitchWrapper {
 display: flex;
 margin-top: 35px;
justify-content: flex-end;
}


@media only screen and (max-width: 400px) {
    #account-registration .phoneTypeSwitchWrapper {
        margin-top: 50px;
    }
}
