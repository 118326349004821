
#account-registration .userMangmtSuccess .contentWrapper.pad {
    margin: 0 auto;
    padding-right: 0;
    padding-left: 0;
}

@media (min-width: 576px) {
    #account-registration .userMangmtSuccess .contentWrapper.pad {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    #account-registration .userMangmtSuccess .contentWrapper.pad {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    #account-registration .userMangmtSuccess .contentWrapper.pad {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    #account-registration .userMangmtSuccess .contentWrapper.pad {
        max-width: 1140px;
    }
}

#account-registration .userMangmtSuccess .explanationPara,
#account-registration .userMangmtSuccess .explanationPara + .pageHeading{
display: none;
}
 
.successs-signin {    
text-align: center;
margin-top: 20px;
font-weight: 600;
display: none;
}
 
.userMangmtSuccess .successs-signin {
display: block;
}
 
.userMangmtSuccess .pageHeading {
text-align: center;
}

.btnVcmId {
    position: absolute;
    margin-left: 10;
    background: transparent;
    border: none;
}