#account-registration #noPhoneNumberHelp {
    border-radius: 0;
    margin-left: 14px;
    padding: 20px 14px;
    max-width: 250px;
    font-size: 12px;
}
#account-registration .noPhoneNumberBtn {
    color: #004A98;
    font-weight: 600;
    background: none;
    border: none;
    margin: 27px 0 49px;
    padding: 0;
    line-height: 22px;
}