.blurClassforBody {
    filter: blur(5px);
}

@media only screen and (max-width: 768px) {
    .modalDialog {
        left: 3% !important;
        width: 94% !important;
        top: 35% !important;
        padding: 40px 10px !important;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .modalDialog {
        left: 10% !important;
        width: 80% !important;
        padding: 40px 20px;
    }
}
