.rowStyle{
    width:100%
}
.mt-28 {
    margin-top: 28px;
}
/* 
@media (max-width: 767.98px) {
    .rowStyle{
        height: 165px;
        width: auto
    }
} */