.downloadcontainer {
    display: flex;   
}    
.info-list li {
    list-style: none;
    margin-top: 10px;  
}
 
.info-list li > div {
    display: inline-flex;
    border: 2px solid #56565A;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: space-around;
    margin-right: 10px;
}
.info-list li > p {
    display: inline-flex;
}
     
.rmd-stepper .stepperComp.stepperComp-4 .stepperCol:nth-last-child(2) .stepperDiv .stepperText + hr {
    width: calc(200% - 50px) !important;
}
    
@media only screen and (min-width: 768px) and (max-width: 992px) { 
    .rmd-stepper {
        padding: 0 3rem;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .rmd-stepper {
        padding: 0 4.5rem;
    }
}
@media only screen and (min-width: 1200px) {
    .rmd-stepper {
        padding: 0 6rem;
    }
}