
.loading-leave {
  opacity: 1;
}

.loading-leave.loading-leave-active {
  opacity: 0;
  transition: opacity 600ms ease-out;
}

.vcmSpinner > div {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%,-50%);
}