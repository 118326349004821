.cardLabel {
    font: 600 16px/20px benton-sans, yorkten-slab-normal, sans-serif;
    color: #49494A;
    padding: 25px 0;
}
#phoneAccordion h4 {
    padding-left: 15px;
    font-family: yorkten-slab-normal, benton-sans, sans-serif;
    color: #486D90;
}
#phoneAccordion h4::before {
    background-size: 100%;
    top: 15px;
}
#phoneAccordion .card {
    border: none;
}
#phoneAccordion .card-header {
    background-color: transparent;
}
#phoneAccordion .card-body {
    padding-left: 0;
    font: 400 16px/24px benton-sans, yorkten-slab-normal, sans-serif;
    color: #49494A;
}
#phoneAccordion .form-label {
    font: 700 16px/24px benton-sans, yorkten-slab-normal, sans-serif !important;
}
#phoneAccordion .lastUsed {
    margin-left: 39px;
    font: 400 16px/20px benton-sans, yorkten-slab-normal, sans-serif;
}
#phoneAccordion .checkBoxWrapper input[type="checkbox"]:checked:after {
    width: 7px;
    height: 14px;
    top: 3px;
    left: 8px;
}