
.loading-leave {
  opacity: 1;
}

.loading-leave.loading-leave-active {
  opacity: 0;
  transition: opacity 600ms ease-out;
}

.imgStyle {
    display: block;
    margin: auto;
    margin-top: 4px;
    /* width: 74px;
    height: 83px; */
}

.connecting{
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #486D90;
  padding-top: 160px;
}

.connectingText {
  font: 500 15px/20px benton-sans;
  margin-top: 40px;
  text-align: center;
  color: #FFFFFF;
}

.processing{
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.91) 0% 0% no-repeat padding-box;
  /* opacity: 0.91; */
  padding-top: 160px;
}

.processingText {
  font: 800 26px/44px yorkten-slab-normal;
  margin-bottom: 50px;
  margin-top: 131px;
  text-align: center;
  color: #486D90;
  height: 31px
}

.thanks{
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.91) 0% 0% no-repeat padding-box;
  /* opacity: 0.91; */
  padding-top: 160px;
}

.thanksText {
  font: 800 26px/44px yorkten-slab-normal;
  margin-top: 44px;
  text-align: center;
  color: #486D90;
}

.accountReg.background {
  position: fixed;
  margin: auto;
  z-index: 999;
  top: 0;
}

.thankYouTick {
  width: 88px;
  height: 88px;
  border-radius: 50%;
  background-color: #8BC105;
  position: relative;
  margin: 109px auto 0;
}

.thankYouTick::after{
  content: '';
  width: 47px;
  height: 21px;
  border-bottom: 6px solid #FFFFFF;
  border-left: 6px solid #FFFFFF;
  transform: rotate(-45deg);
  position: absolute;
  top: 27px;
  left: 22px;
}
  /* @-webkit-keyframes bg-animation {
    0% {
      background-color: #0F283F;
    }
    20% {
      background-color: #333333DD;
    }
    40% {
      background-color: #486D90;
    }
  }
  @keyframes bg-animation {
    0% {
        background-color: #0F283F;
      }
      20% {
        background-color: #333333DD;
      }
      40% {
        background-color: #486D90;
      }
  } */

@media (max-width: 765px) {
  .processingText {
    font: 800 18px/22px yorkten-slab-normal;
    margin: 0 auto 0;
    height: 74px;
    max-width: 194px;
  }

  .thankYouTick {
    margin: 0 auto 0;
  }
}