@media (max-width:767px) {
    .instructionText {
        font-size: 18px;
    }
   
}
.documentAnchorLink, .documentQuickLink{
    display:inline-block;
}
.date-picker-style{
    margin-left: 20px;
}
fieldset{
    
    display: block !important;
    margin-inline-start: 2px !important;
    margin-inline-end: 2px !important;
    padding-block-start: 0.35em !important;
    padding-inline-start: 0.75em !important;
    padding-inline-end: 0.75em !important;
    min-inline-size: min-content !important;
    border-width: 1px !important;
    border-style: groove !important;
    border-color: #dedede !important;
    border-image: initial !important;
    margin-bottom: 20px !important;
    padding-bottom: 30px !important;
}
legend{
    width:auto !important;
    display: block;
    padding-inline-start: 2px;
    padding-inline-end: 2px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    font-size:18px !important;
    color: #486D90 !important;
    margin-bottom: 0px !important;
}
